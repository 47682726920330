import React, {useState} from 'react';
import PromptTabDetail from '../Common/PromptTabDetail';


export default function SystemPromptsPowerPoint() {
  const [displayAddScopeSection, setDisplayAddScopeSection] = useState(false);

  const updateAddScopeSectionVisibility = (value) => {
    setDisplayAddScopeSection(value);
  }

  return (
    <>
      {
        !displayAddScopeSection ?
          <PromptTabDetail tab={"PPSystemPrompts"} updateAddScopeSectionVisibility={updateAddScopeSectionVisibility} />
          :
          null
      }
    </>
  )
}
