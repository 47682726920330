import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import { MotifChip } from "@ey-xd/motif-react";
import editPencil from "../../../../assets/image/edit.svg";
import { DashboardContext } from "../../../../context/dashboardContext";
import { ProjectScopeStatus } from "../../../../config/groupConfig";
import PropTypes from "prop-types";

const PromptAccordion = (props) => {
  const { scopeAll } = useContext(DashboardContext);
  const { selectedScopeId } = useSelector((state) => state.promptManagement);
  const [open, setOpen] = useState(false);
  const [isEditIconVisible, setIsEditIconVisible] = useState(props.tab === "SystemPrompts" && props.type === "testPrompt");
  const [showIIEditPrompt, setShowIIEditPrompt] = useState((props.tab === "InstantInsightPrompts" && props.type === "testPrompt") ? true : false);

  const [currentSelectedScope, setCurrentSelectedScope] = useState(null);
  const [speydStatusCheck, setSpeydStatusCheck] = useState(null);
  const [spiiStatusCheck, setSpiiStatusCheck] = useState(null);
  const [isInstantInsightTestTab] = useState(props.tab === "InstantInsightPrompts" && props.type === "testPrompt");
  const { scopeList } = useSelector(state => state.scope);

  useEffect(() => {
    const currentSelectedScope = scopeList.filter((scope) => {
      return scope.scopeId === selectedScopeId;
    })
    setCurrentSelectedScope(currentSelectedScope[0]);
    setSpeydStatusCheck(currentSelectedScope[0]?.spEydStatus)
    setSpiiStatusCheck(currentSelectedScope[0]?.spiiStatus)
  }, [scopeAll, selectedScopeId, props.node])
  /**
   * This method is a click operation of the accordion click
   * @param {*} event
   */
  const onClickAccordion = (event) => {
    if (event.target.className === "motif-checkbox-custom" && (props?.prompt?.isEditFlag || props.tab === "InstantInsightPrompts")) {
      props.onCheckPrompt(props?.prompt?.platformPromptId, true);
    } else if (
      event.target.parentNode.className === "motif-checkbox-checked-icon" && (props?.prompt?.isEditFlag || props.tab === "InstantInsightPrompts")
    ) {
      props.onCheckPrompt(props?.prompt?.platformPromptId, false);
    } else if (event.target.nodeName === "svg" || event.target.nodeName === "path") {
      setOpen(!open);
    } else if (
      event.target.nodeName === "svg" ||
      (event.target.className?.trim() !== "ii-prompt-detail-text" &&
        event.target.className?.trim() !== "motif-accordion-content")
    ) {
      setOpen(!open);
    }
  };

  return (
    <MotifAccordion
      open={open}
      alignIconRight={true}
      useChevronIcon={true}
      onClose={() => { }}
      onOpen={() => { }}
      onClick={($event) => onClickAccordion($event)}
      className={
        props?.prompt?.isChecked
          ? "ii-prompt-selected-accordion"
          : "ii-prompt-accordion"
      }
      data-testid="accordionPromptTitle"
    >
      <MotifAccordionTrigger>
        <div className="accordion-title">
          <div className="ii-response-accordion-trigger">
            {(() => {
              if (
                props.tab === "SystemPrompts" &&
                props.type === "activePrompt"
              ) {
                return "";
              } else if (
                props.tab === "SystemPrompts" && props.type === "testPrompt" &&
                ((props.node?.api === "Explore your data" && speydStatusCheck === ProjectScopeStatus.publish)
                  || (props.node?.api === "Instant Insight" && spiiStatusCheck === ProjectScopeStatus.publish))
              ) {
                return (
                  <MotifCheckbox
                    name="chkPrompt"
                    checked={props?.prompt?.isChecked}
                    value={props?.prompt?.isChecked}
                    disabled={!props?.prompt?.isEditFlag}
                  ></MotifCheckbox>
                );
              }
              else if(props.tab === "InstantInsightPrompts"){
                return (
                  <MotifCheckbox
                    name="chkPrompt"
                    checked={props?.prompt?.isChecked}
                    value={props?.prompt?.isChecked}
                  ></MotifCheckbox>
                );
              }
              else {
                return (
                  <MotifCheckbox
                    name="chkPrompt"
                    checked={props?.prompt?.isChecked}
                    value={props?.prompt?.isChecked}
                    disabled
                  // className={`${isInstantInsightTestTab && props?.prompt?.newEditFlag === "" ? "disable-checkbox-prompt-accordion": ""}`}
                  ></MotifCheckbox>
                );
              }
            })()}
            {
              props?.prompt?.platformPromptTitle
            }
            {
              (()=>{
                if(props.tab === "SystemPrompts") {
                  if(props?.prompt?.isEditFlag){
                    return(
                      <MotifChip
                        onClick={null}
                        title="Edited"
                        className="edited-chip"
                        style={{ "display": (isEditIconVisible || showIIEditPrompt) ? "block" : "none" }}
                      >
                        Edited
                      </MotifChip>
                    )
                  } else if(props?.prompt?.newEditFlag && !props?.prompt?.isEditFlag) {
                    return(
                      <MotifChip
                        onClick={null}
                        title="Edited"
                        className="edited-chip new-chip"
                        style={{ "display": showIIEditPrompt ? "block" : "none" }}
                      >
                        New
                      </MotifChip>
                    )
                  }
                }
                if(props.tab === "InstantInsightPrompts" && props.type === "testPrompt"){
                  if(props?.prompt?.newEditFlag==="New"){
                    return (
                      <MotifChip
                        onClick={null}
                        className="prompt-status-chip-new"
                      >
                        New
                      </MotifChip>
                    )
                  } else if(props?.prompt?.newEditFlag==="Edit"){
                    return (
                      <MotifChip
                        onClick={null}
                        className="prompt-status-chip-edited"
                      >
                        Edited
                      </MotifChip>
                    )
                  }
                }
              })()
            }
          </div>
          <div>
            <MotifIconButton onClick={!showIIEditPrompt ? props.onEditIconClick : props.editTextPrompts} style={{ "display": (isEditIconVisible || showIIEditPrompt) ? "block" : "none" }}>
              <img alt='icon' src={editPencil} />
            </MotifIconButton>
          </div>
        </div>
      </MotifAccordionTrigger>
      <MotifAccordionContent>
        {
            (()=>{
              if(props?.prompt?.platformPromptText != null){
                if(props.tab === "InstantInsightPrompts"){
                return (<div className="ii-prompt-detail-text" dangerouslySetInnerHTML={{
                  __html: props?.prompt?.platformPromptText,
                }}></div>)
              } else {
                return (<div className="ii-prompt-detail-text">{props?.prompt?.platformPromptText}</div>)
              }
            } else {
              return (<div className="ii-prompt-detail-text">No prompt found</div>)
            }
          })()
        }
      </MotifAccordionContent>
    </MotifAccordion>
  );
};

PromptAccordion.propTypes = {
  prompt: PropTypes.object,
  tab: PropTypes.string,
  node: PropTypes.object,
  type: PropTypes.string,
  onCheckPrompt: PropTypes.func,
  onEditIconClick: PropTypes.func,
  editTextPrompts: PropTypes.func
}

export default PromptAccordion;
