import axios from "../services/axiosWithMsal";
import CommonService from "./commonService.js";

class ExploreDataService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = this.commonService.getAPIUrl();
  }
  async GetPromptDetails(projectId, promptType, typeofData) {
    const PromptRequest = {
      Project_UUID: projectId,
      PromptType: promptType,
      TypeofData: typeofData,
    };

    const response = await axios
      .get(this.apiUrl + "Prompt/GetPromptDetailsByProject", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        params: PromptRequest,
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Get prompt details by project"
        );
      });

    return response.data;
  }

  async DeletePrompt(PromptId) {
    await axios
      .delete(this.apiUrl + "Prompt/DeletePrompt?PromptId=" + PromptId + "", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      })
      .then((Response) => {})
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Delete Prompt"
        );
      });
  }

  async DeleteConversation(conv_UUID) {
    await axios
      .delete(this.apiUrl + "Prompt/DeleteConversation?conv_UUID=" + conv_UUID + "", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      })
      .then((Response) => {})
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Delete Conversation"
        );
      });
  }

  TransferPrompt = async(promptId,transferType) => {
    await axios
      .post(this.apiUrl + "Prompt/SendToPPT", {
        promptId:promptId,
        type:transferType
      })
      .then((response) => {})
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Transfer Prompt To PPT"
        );
      });
  };

  SendEmailPrompt = async (prompt) => {
    await axios
      .post(this.apiUrl + "Prompt/SendPromptEmail", prompt)
      .then((Response) => {})
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Send Prompt Email"
        );
      });
  };
// Get Instant Insight Favorite Prompt Details Depending On ProjectId and Email
  async GetInstantInsightFavoritePromptsByProject(projectId) {
    const PromptRequest = {
      Project_UUID: projectId
    };

    const response = await axios
      .get(this.apiUrl + "InstantInsights/GetInstantInsightFavoritePromptsByProject", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        params: PromptRequest,
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Get Instant Insight Favorite Prompt Details By Project"
        );
      });

    return response.data;
  }

}
export default ExploreDataService;
