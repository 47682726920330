import React, { useEffect, useState } from "react";

import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import { MotifButton, MotifFormField, MotifLabel, MotifTextArea,MotifErrorMessage } from "@ey-xd/motif-react";
import PlatformService from "../../../../services/platformService";
import infoIcon from "../../../../assets/image/info-member.svg";
import "./PromptTabDetail.css";


export default function EditTestPrompts(props) {

    const MAXLENGTH = 12000;
    const INITIALPROMPTTEXT = props.prompt?.platformPromptText ? props.prompt?.platformPromptText : "";
    const platformService = new PlatformService();
    const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true);
    const [isTextAreaDirty, setIsTextAreaDirty] = useState(false);
    const [promptText, setPromptText] = useState(props.prompt?.platformPromptText ? props.prompt?.platformPromptText : "");
    const [charsLeft, setCharsLeft] = useState(MAXLENGTH);
    const [errorMessage, setErrorMessage] = useState('');
    const [maxLengthReached, setMaxLengthReached] = useState(false);

    
    useEffect(() => {
        setCharsLeft(MAXLENGTH - promptText.length);
        if (promptText.length != 0) {
            if (promptText != INITIALPROMPTTEXT) {
                setIsTextAreaDirty(true);
            }
            else {
                setIsTextAreaDirty(false);
            }
        }
        else {
            setIsTextAreaDirty(false);
        }
        setMaxLengthReached(promptText.length === MAXLENGTH);
    }, [promptText])

    useEffect(() => {
        if (isTextAreaDirty && !errorMessage && promptText.trim() !== '') {
            setIsConfirmBtnDisabled(false);
        }
        else {
            setIsConfirmBtnDisabled(true);
        }
    }, [isTextAreaDirty, errorMessage, promptText])

    const onCancelBtnClick = () => {
        props.onEditModalCloseClick();
    }

    const onConfirmBtnClick = async() => {
        const status = await platformService.editSystemTestPromptText({
            "platformPromptId": props.prompt? props.prompt?.platformPromptId : null,
            "platformPromptText": promptText
        });
        props.updateEditStatus(status);
        props.onEditModalCloseClick();
    }

    const onPromptTxtChange = (event) => {
        if (promptText.length < MAXLENGTH) {
            setPromptText(event.target.value);
        }
        if (event.nativeEvent.inputType === 'deleteContentBackward' || event.nativeEvent.inputType === 'deleteContentForward'){
            setPromptText(event.target.value);
        }

        let value = event.target.value;

        if (value.length > MAXLENGTH) {
            value = value.slice(0, MAXLENGTH);  
        }
        
        const allowedCharactersRegex = /^[a-zA-Z0-9 <>\#\*\=\:\?\n]*$/;  // For allowed characters (including spaces, #, *, etc.)
        const restrictedCharactersRegex = /&/; // For restricted characters

       
        if (restrictedCharactersRegex.test(value)) {
            setErrorMessage("Special characters not allowed");
        } else {
            setErrorMessage(''); 
        }

        
        setPromptText(value);
    }

    return (
        <div>
            <MotifModal
                id="editPromptModalnew"
                show={true}
                size="xlg"
                focusTrapOptions={{
                    tabbableOptions: {
                      displayCheck: "none",
                    },
                  }}
                  onClose={onCancelBtnClick}
                  className="dialog-modal"
            >
                <MotifModalHeader>
                    <div className="header-title">
                        Edit prompt
                    </div>
                </MotifModalHeader>
                <MotifModalBody>
                    <div>
                        <MotifFormField className="title-label">
                            <MotifLabel>
                                Title
                            </MotifLabel>
                        </MotifFormField>
                    </div>
                    <div className="info">
                        {props.prompt?.platformPromptTitle}
                    </div>
                    <div>
                        <MotifFormField className="title-prompt">
                            <MotifLabel>
                                Prompt
                            </MotifLabel>
                        </MotifFormField>
                    </div>
                    <div>
                        <MotifTextArea
                            label="inputPrompt"
                            onChange={onPromptTxtChange}
                            data-testid="inputPrompt"
                            className={`prompt-textarea ${errorMessage ? 'error-border' : ''}`} 
                            value={promptText}
                            placeholder="Enter prompt"
                        />
                    </div>
                    <div className="error_container">
                        {maxLengthReached && !errorMessage && (
                            <div className="max_limit">
                                Maximum limit reached - {MAXLENGTH.toLocaleString()} characters
                            </div>
                        )}
                        {errorMessage && <div className='errorText'>
                            {errorMessage}

                        </div>}
                        <div className="chars-left">
                            {promptText.length}/{MAXLENGTH.toLocaleString()}
                        </div>
                    </div>
                </MotifModalBody>
                <MotifModalFooter>
                    <div className="footer-btns-div">
                        <div>
                            <MotifButton
                                variant="secondary"
                                onClick={onCancelBtnClick}
                                className="cancel-btn"
                            >
                                Cancel
                            </MotifButton>
                        </div>
                        <div>
                            <MotifButton
                                className="confirm-btn"
                                onClick={onConfirmBtnClick}
                                disabled={isConfirmBtnDisabled}
                            >
                                Confirm
                            </MotifButton>
                        </div>
                    </div>
                </MotifModalFooter>
            </MotifModal>
        </div>
    )
}