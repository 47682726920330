import React, { useState } from "react";
import PromptProjectScope from "./PromptProjectScope";
import PromptDetail from "./PromptDetail";
import "./PromptTabDetail.css";

const PromptTabDetail = (props) => {
  const [selectedNode, setSelectedNode] = useState({});
  const [treeData, setTreeData] = useState(null);
  const [selectedScopeId, setSelectedScopeId] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);

  /**
   * This method is to select the subsection item
   * @param {*} prompt
   * @param {*} tree
   */
  const selectedItem = (prompt, tree) => {
    setSelectedNode(prompt);
    setTreeData(tree);
  };

  return (
    <div className="motif-row ii-prompt-management-tab-container">
      <div className="motif-col-xs-4 motif-col-sm-1">
        <PromptProjectScope updateAddScopeSectionVisibility={props.updateAddScopeSectionVisibility} setSelectedNode={selectedItem} tab={props.tab} setSelectedScopeId={setSelectedScopeId} setSelectedScope={setSelectedScope}/>
      </div>
      <div className="motif-col-sm-3">
        <PromptDetail
          selectedNode={selectedNode}
          scopeTreeData={treeData}
          tab={props.tab}
          selectedScopeId= {selectedScopeId}
          selectedScope= {selectedScope}
        />
      </div>
    </div>
  );
};

export default PromptTabDetail;
