import React, { useEffect, useState } from "react";
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import { MotifButton, MotifErrorMessage, MotifFooter, MotifFormField, MotifInput, MotifModal, MotifModalBody, MotifModalHeader, MotifToast } from "@ey-xd/motif-react";
import _ from "lodash";
import PlatformService from "../../../../services/platformService.js";
import { setRefreshScopes } from "../../../../reducers/scopeSlice.js";
import { useSelector, useDispatch } from "react-redux";
import editPencil from "../../../../assets/image/edit-pencil.svg";
import iconError from "../../../../assets/image/error.svg";
import arrowLeft from "../../../../assets/image/arrow-left-black.svg"
import publish from "../../../../assets/image/publish.svg";
import check_circle from "../../../../assets/image/check-circle.svg";
import plus from "../../../../assets/image/plus_scope.svg";

import './ManageProjectScope.css';
import PublishPrompt from "./PublishPrompt.js";



const ManageProjectScope = (props) => {
    const dispatch = useDispatch();
    const platformService = new PlatformService();
    const { scopeList, refreshScopes } = useSelector(state => state.scope);
    const { updateAddScopeSectionVisibility } = { ...props };
    const [showAddScopeModal, setShowAddScopeModal] = useState(false);
    const [projectScopeName, setProjectScopeName] = useState('');
    const [showSpecialCharacterValidation, setShowSpecialCharacterValidation] = useState(false);
    const [showMaximumCharsValidation, setShowMaximumCharsValidation] = useState(false);
    const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [publishPromptModal, setPublishPromptModal] = useState(false);
    const [scopeName, setScopeName] = useState("");
    const [promptCategory, setPromptCategory] = useState("");
    const [scopeId, setScopeId] = useState("");
    const [editscopePopupOpen, setEditscopePopupOpen] = useState(false);
    const [selectedScope, setSelectedScope] = useState();

    useEffect(() => {
        dispatch(
            setRefreshScopes({
              refreshScopes: !refreshScopes,
            })
          );
    }, [])

    const onAddScopeBtnClick = () => {
        setShowAddScopeModal(true);
    }

    const handleClose = () => {
        setShowAddScopeModal(false);
    }

    const onProjectScopeNameInputTxtChange = (value) => {
        const regex = /^[a-zA-Z0-9 ]*$/;
        if (value.length > 50) {
            value = value.slice(0, 50);
            setShowMaximumCharsValidation(true);
        }
        else {
            setShowMaximumCharsValidation(false);
        }
        if (regex.test(value)) {
            setShowSpecialCharacterValidation(false);
        } else {
            setShowSpecialCharacterValidation(true);
        }
        setProjectScopeName(value)
    }

    useEffect(() => {
        if (!editscopePopupOpen) {
            if (projectScopeName == "") {
                setIsConfirmBtnDisabled(true);
            }
            else if (showSpecialCharacterValidation) {
                setIsConfirmBtnDisabled(true);
            }
            else {
                setIsConfirmBtnDisabled(false);
            }
        }
        else {
            if (projectScopeName === selectedScope) {
                setIsConfirmBtnDisabled(true);
            }
            else {
                setIsConfirmBtnDisabled(false);
            }
        }
    }, [projectScopeName, showSpecialCharacterValidation])

    const onConfirmBtnClick = async (event) => {
        if (editscopePopupOpen) {
            let response = await platformService.saveScope(projectScopeName, scopeId);
            if (response == 200) {
                dispatch(
                      setRefreshScopes({
                        refreshScopes: !refreshScopes,
                      })
                    );
                setShowToast(true);
                setToastType("success");
                setToastMessage("Project scope edited successfully")
            }
            setShowAddScopeModal(false);
        }
        else {
            let response = await platformService.saveScope(projectScopeName);
            if (response == 200) {
                dispatch(
                    setRefreshScopes({
                      refreshScopes: !refreshScopes,
                    })
                  );
                setShowToast(true);
                setToastType("success");
                setToastMessage("Project scope added successfully")
            }
            setShowAddScopeModal(false);
        }
        setProjectScopeName('')
        setEditscopePopupOpen(false)
    }

    const onCancelBtnClick = () => {
        setShowAddScopeModal(false);
        setShowMaximumCharsValidation(false);
        setProjectScopeName('');
        if (editscopePopupOpen === true) {
            setEditscopePopupOpen(false)
            setProjectScopeName('')
        }
    }

    const getRelevantCssClasses = () => {
        if (showSpecialCharacterValidation) {
            return "field motif-input-invalid";
        }
        else if (showMaximumCharsValidation) {
            return "field motif-input-blue";
        }
        else {
            return "field";
        }
    }

    const closePublishPromptModal = () => {
        setPublishPromptModal(false)
    }
    const getPublishEydData = (scopeName, scopeId) => {
        setPublishPromptModal(true);
        setScopeName(scopeName);
        setScopeId(scopeId)
        setPromptCategory("Explore your data");
    }
    const getPublishIIData = (scopeName, scopeId) => {
        setPublishPromptModal(true);
        setScopeName(scopeName);
        setScopeId(scopeId)
        setPromptCategory("Instant Insight");
    }
    const PromptConfirmClick = async () => {
        let response = await platformService.publishSystemPrompts(scopeId, promptCategory);
        if (response == 200 || response == 204) {
            dispatch(
                setRefreshScopes({
                  refreshScopes: !refreshScopes,
                })
              );
            setShowToast(true);
            setToastType("success");
            setToastMessage("Project scope published successfully")
        }
        setPublishPromptModal(false)
    }

    const editScopeBtnClick = (scopeId, scopeName) => {
        setSelectedScope(scopeName)
        setScopeId(scopeId)
        setProjectScopeName(scopeName)
        setEditscopePopupOpen(true)
        setShowAddScopeModal(true);
    }

    const getScopes = () => {
        let markUp = 
            _.sortBy(scopeList, [function(o) { return o.scopeName.toLowerCase(); }])
            .map((scope) => {
                return (
                    <div className="scope-div" key={scope.scopeId}>
                        <div className="scopeOfProject">{scope.scopeName}</div>
                        <div className="rename">
                            <img
                                alt='icon'
                                onClick={() => editScopeBtnClick(scope.scopeId, scope.scopeName)}
                                src={editPencil}
                            />
                        </div>
                        <div
                            className='EYD'
                            style={scope.spEydStatus === 4 ? { color: '#2E2E38', fontWeight: '400' } : null}
                        >
                            {(() => {
                                if (scope.spEydStatus === 4) {
                                    return (
                                        <>Published</>
                                    )
                                } else if (scope.spEydStatus === 3) {
                                    return (
                                        <MotifButton
                                            style={{
                                                display: 'flex',
                                                alignItems: "center",
                                            }}
                                            size="small"
                                            onClick={() => getPublishEydData(scope.scopeName, scope.scopeId)}
                                        >
                                            <img
                                                src={publish}
                                                alt="publish"
                                                style={{
                                                    marginRight: '10px'
                                                }} />
                                            <span>Publish</span>
                                        </MotifButton>
                                    )
                                } else {
                                    return (
                                        <MotifButton
                                            style={{
                                                display: 'flex',
                                                alignItems: "center",
                                            }}
                                            size="small"
                                            disabled
                                        >
                                            <img
                                                src={publish}
                                                alt="publish"
                                                style={{
                                                    marginRight: '10px'
                                                }} />
                                            <span>Publish</span>
                                        </MotifButton>
                                    )
                                }
                            })()}
                        </div>
                        <div
                            className="instantInsights"
                            style={scope.spiiStatus === 4 ? { color: '#2E2E38', fontWeight: '400' } : null}>
                            {(() => {
                                if (scope.spiiStatus === 4) {
                                    return (
                                        <>Published</>
                                    )
                                } else if (scope.spiiStatus === 3) {
                                    return (
                                        <MotifButton
                                            style={{
                                                display: 'flex',
                                                alignItems: "center",
                                            }}
                                            size="small"
                                            onClick={() => getPublishIIData(scope.scopeName, scope.scopeId)}
                                        >
                                            <img
                                                src={publish}
                                                alt="publish"
                                                style={{
                                                    marginRight: '10px'
                                                }} />
                                            <span>Publish</span>
                                        </MotifButton>
                                    )
                                } else {
                                    return (
                                        <MotifButton
                                            style={{
                                                display: 'flex',
                                                alignItems: "center",
                                            }}
                                            size="small"
                                            disabled
                                        >
                                            <img
                                                src={publish}
                                                alt="publish"
                                                style={{
                                                    marginRight: '10px'
                                                }} />
                                            <span>Publish</span>
                                        </MotifButton>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                )
            })
        return markUp;
    }

    const onBackBtnClick = () => {
        updateAddScopeSectionVisibility(false);
    }

    return (
        <div className="manageProjectScopeComponent">
            <div className="back-section project-scope-field-txt">
                <MotifIconButton className="icon-btn" onClick={onBackBtnClick}>
                    <div className="back-btn">
                        <img src={arrowLeft} alt="Back" /><span>Back</span>
                    </div>
                </MotifIconButton>
            </div>
            <div className="parent-top-heading">
                <div className="top-heading">
                    Manage Project Scope
                </div>
                <div>
                    <MotifButton variant="secondary" className="btn-add-scope" onClick={onAddScopeBtnClick}>
                        <img src={plus} alt="plus" />Add New Project Scope
                    </MotifButton>
                </div>
            </div>
            {scopeList ? (
                <div className="scope-section">
                    <div className="section-heading project-scope-field-txt">
                        <div className="scopeOfProject">Name</div>
                        <div className="rename">Rename</div>
                        <div className="EYD">Explore your data</div>
                        <div className="instantInsights">Instant Insights</div>
                    </div>
                    <div className="scopes-parent-div">
                        {
                            getScopes()
                        }
                    </div>
                </div>)
                :
                (
                    <div className="no_scopes">No scopes available !</div>
                )
            }
            {
                showToast && (
                    <MotifToast
                        icons={{ toast: <img src={check_circle} alt=""></img> }}
                        variant={toastType}
                        actionName=""
                        position="bottom"
                        className=""
                        onClose={() => {
                            setShowToast(false);
                        }}
                    >
                        <div>{toastMessage}</div>
                    </MotifToast>
                )
            }
            <MotifModal
                id="addScopeModal"
                show={showAddScopeModal}
                size="lg"
                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: "none",
                    },
                }}
                onClose={() => onCancelBtnClick()}
            >
                <MotifModalHeader
                    closeButtonProps={{
                        "aria-label": "Close",
                        title: "Close",
                    }}
                >
                    {editscopePopupOpen ? 'Rename Project Scope' : 'Add New Project Scope'}
                </MotifModalHeader>

                <MotifModalBody>
                    <div className="project-scope-field-txt">Project Scope</div>
                    <MotifFormField className={getRelevantCssClasses()}>
                        <MotifInput
                            className="inputProjectScope"
                            onChange={(e) => onProjectScopeNameInputTxtChange(e.target.value)}
                            type='text'
                            placeholder={!editscopePopupOpen ? "Enter Project Scope Name" : null}
                            value={projectScopeName}
                            hideClearButton={false}
                        />
                        {
                            showSpecialCharacterValidation &&
                            <img src={iconError} alt='Error' className='errorIcon' />
                        }
                    </MotifFormField>
                    {
                        showSpecialCharacterValidation &&
                        <MotifErrorMessage className='errorText'>
                            Special characters not allowed
                        </MotifErrorMessage>
                    }
                    {
                        showMaximumCharsValidation && !showSpecialCharacterValidation &&
                        <span>Maximum limit reached - 50 characters</span>
                    }
                </MotifModalBody>

                <MotifFooter className="footer">
                    <div className="footer-btns-div">
                        <div>
                            <MotifButton
                                variant="secondary"
                                onClick={() => {
                                    onCancelBtnClick();
                                }}
                            >
                                Cancel
                            </MotifButton>
                        </div>
                        <div>
                            <MotifButton
                                className="confirm-btn"
                                onClick={onConfirmBtnClick}
                                disabled={isConfirmBtnDisabled}
                            >
                                Confirm
                            </MotifButton>
                        </div>
                    </div>
                </MotifFooter>
            </MotifModal>
            <PublishPrompt
                show={publishPromptModal}
                onHide={closePublishPromptModal}
                scopeName={scopeName}
                category={promptCategory}
                PromptConfirmClick={PromptConfirmClick}
            />
        </div>
    )
}

export default ManageProjectScope;