import axios from "./axiosWithMsal";
import CommonService from "./commonService.js";

// Service to handle all API calls related prompt library
class ConfigurationService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
  }

  /* Invoke API to fetch all personal prompts for user
       Returns - Document settings object
    */
  async getDocumentSettings() {
    const apiEndPoint = `Project/GetDocumentSetting`;

    const response = await axios
      .get(this.apiUrl + apiEndPoint, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Document Settings",
          "Get Document Settings"
        );
      });

    return response.data;
  }

  async getKeyVaultValue(key) {
    return axios
      .get(this.apiUrl + "Project/GetKeyVaultValue?keyVaultName=" + key, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((result) => {
        return result?.data;
      });
  }
}

export default ConfigurationService;
