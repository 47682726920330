import React from "react";
import { MotifButton, MotifModal, MotifModalBody, MotifModalFooter, MotifModalHeader } from "@ey-xd/motif-react";

import redWarningInfoIcon from "../../../../assets/image/Light circle.svg";
import "./DiscardTreeHierarchyModal.css";


export default function DiscardTreeHierarchyModal(props) {


    const {handleModalClose, selectedNodes, handleDiscardConfirmClick}= props;

    const btnCloseClick = () => {
        handleModalClose();
    }

    const btnConfirmClick = () => {
        handleDiscardConfirmClick();
        handleModalClose();
    }

    return (
        <div className="discard-modal">
            <MotifModal
                id="discard-modal"
                show={true}
                size="lg"
                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: "none",
                    },
                }}
                onClose={btnCloseClick}
            >
                <MotifModalHeader className="discard-modal-header">
                    <div className="icon-title-container">
                        <div className="discard-icon"><img alt="discard icon" src={redWarningInfoIcon} /></div>
                        <div className="modal-title">
                            Discard
                        </div>
                    </div>
                    <span>Selected items will be removed from the hierarchy in the structure. Click 'Confirm' to discard.</span>
                </MotifModalHeader>
                <MotifModalBody>
                    <ul className="discard-list">
                        {
                            (() => {
                                return (
                                    selectedNodes.map((node) => {
                                        return (
                                            <li>
                                                {node.value}
                                            </li>
                                        )
                                    })
                                )
                            })()
                        }
                    </ul>
                </MotifModalBody>
                <MotifModalFooter>
                    <div className="share-alert-btns-div">
                        <div>
                            <MotifButton
                                variant="secondary"
                                onClick={() => {
                                    btnCloseClick();
                                }}
                                className="btn-cancel"
                            >
                                Cancel
                            </MotifButton>
                        </div>
                        <div>
                            <MotifButton
                                className="confirm-button"
                                onClick={btnConfirmClick}
                            >
                                Confirm
                            </MotifButton>
                        </div>
                    </div>
                </MotifModalFooter>
            </MotifModal>
        </div>
    )
}