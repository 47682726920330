import { createSlice } from "@reduxjs/toolkit";

const scopeSlice = createSlice({
  name: "scope",
  initialState: {
    scopeList: [],
    refreshScopes: false,
  },
  reducers: {
    setScopeList: (state, action) => {
      state.scopeList = action.payload.scopeList;
    },
    setRefreshScopes: (state, action) => {
      state.refreshScopes = action.payload.refreshScopes;
    },
  },
});

export const { setScopeList, setRefreshScopes } = scopeSlice.actions;

export default scopeSlice.reducer;
