import React, { useState, useCallback } from "react";
import {
  MotifInput,
  MotifOption,
  MotifSelect,
  MotifTimeInput,
  MotifButton,
  MotifDatePicker,
  MotifErrorMessage,
  MotifFormField,
} from "@ey-xd/motif-react";
import moment from "moment";

import RichTextEditor from "../AdminDashboard/RichTextEditor";
import PlatformService from "../../services/platformService";

import "./CreateNotification.css";

const CreateNotification = ({ onCancel, setActiveTab }) => {
  const platformService = new PlatformService();
  const [type, setType] = useState("Planned maintenance");
  const [title, setTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [startDate, setStartDate] = useState(moment.utc().toDate());
  const [endDate, setEndDate] = useState(moment.utc().toDate());
  const [startTime, setStartTime] = useState(moment.utc().toDate());
  const [endTime, setEndTime] = useState(moment.utc().toDate());
  const [errors, setErrors] = useState({});

  const maxTitleChars = 100;
  const maxDescriptionChars = 1000;
  const restrictedCharsRegex = /[`!@#$%^*+=\[\]{};':"\\|,<>?~]/;

  const notificationTypes = [
    { value: "Planned maintenance", label: "Planned maintenance" },
    { value: "Known issues", label: "Known issues" },
    { value: "General", label: "General" },
    { value: "News", label: "News" },
  ];

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const formatTime = (time) => moment(time).format("HH:mm") + ":00";

  const hasErrors = Object.values(errors).some((error) => error);

  const handleTitleChange = useCallback(
    (value) => {
      let errorMessage = "";

      if (restrictedCharsRegex.test(value)) {
        errorMessage = "Special characters not allowed";
      } else if (value.length > maxTitleChars) {
        errorMessage = "Maximum allowed characters exceeded";
      }

      setTitle(value);
      setErrors((prev) => ({ ...prev, title: errorMessage }));
    },
    [restrictedCharsRegex, maxTitleChars]
  );

  const validateFields = (field, value) => {
    const now = moment.utc();
    const startMoment = moment.utc(startDate).startOf("day");
    const endMoment = moment.utc(endDate).startOf("day");
    const startTimeMoment = moment.utc(startTime);
    //const endTimeMoment = moment.utc(endTime);

    let errorMessage = "";

    switch (field) {
      case "endDate":
        if (moment.utc(value).isBefore(startMoment)) {
          errorMessage = "End Date cannot be earlier than Start Date";
        }
        break;

      case "startTime":
        if (startMoment.isSame(now, "day") && moment.utc(value).isBefore(now)) {
          errorMessage = "Start time cannot be earlier than current time";
        }
        break;

      case "endTime":
        if (
          startMoment.isSame(endMoment, "day") &&
          moment.utc(value).isBefore(startTimeMoment)
        ) {
          errorMessage = "End Time cannot be earlier than Start Time";
        }
        break;

      default:
        break;
    }

    setErrors((prev) => ({ ...prev, [field]: errorMessage }));
  };

  const handleChange = (field, value) => {
    const parsedValue =
      field === "startTime" || field === "endTime"
        ? moment(value, "HH:mm").toDate()
        : moment(value).toDate();

    switch (field) {
      case "startDate":
        setStartDate(parsedValue);
        break;
      case "endDate":
        setEndDate(parsedValue);
        break;
      case "startTime":
        setStartTime(parsedValue);
        break;
      case "endTime":
        setEndTime(parsedValue);
        break;
      default:
        break;
    }
    validateFields(field, value);
  };

  const handleEditorContentChange = useCallback(
    (content) => {
      const plainText = stripHtmlTags(content);
      let errorMessage = "";

      if (restrictedCharsRegex.test(plainText)) {
        errorMessage = "Special characters not allowed";
      } else if (plainText.length > maxDescriptionChars) {
        errorMessage = "Maximum allowed characters exceeded.";
      }

      setEditorContent(content);
      setErrors((prev) => ({ ...prev, editorContent: errorMessage }));
    },
    [restrictedCharsRegex, maxDescriptionChars, stripHtmlTags]
  );

  const handlePublishOrDraft = async (mode) => {
    if (hasErrors) {
      return;
    }
    const payload = {
      type,
      title,
      description: editorContent,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      startTime: formatTime(startTime),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      endTime: formatTime(endTime),
      saveMode: mode, // for publish P and for draft D
    };

    try {
      let responseStatus = await platformService.publishNotification(payload);
      if (responseStatus === 201) {
        onCancel();
      }
    } catch {
      console.log("error");
    }
  };

  return (
    <div className="message-settings-container">
      <div className="button-container">
        <p className="Label-big">Message Settings</p>
        <div className="button-group">
          <MotifButton
            size="medium"
            variant="text"
            className="button"
            onClick={onCancel}
          >
            Cancel
          </MotifButton>
          <MotifButton
            variant="secondary"
            size="medium"
            className="button"
            onClick={() => handlePublishOrDraft("D")}
          >
            Save Draft
          </MotifButton>
          <MotifButton
            className="button"
            size="medium"
            variant="primary"
            onClick={() => handlePublishOrDraft("P")}
            disabled={hasErrors}
          >
            Publish
          </MotifButton>
        </div>
      </div>

      <div className="divider"></div>

      <div className="form-container">
        <div className="form-group select">
          <label className="Label-input">Select Type</label>
          <MotifSelect
            id="type"
            value={type}
            onChange={(value) => setType(value)}
          >
            {notificationTypes.map((type) => (
              <MotifOption key={type.value} value={type.value}>
                {type.label}
              </MotifOption>
            ))}
          </MotifSelect>
          ;
        </div>

        <div className="form-group input">
          <div className="InputWithLabel">
            <div className="Label-input">Message title (optional)</div>
            <MotifFormField className="error-box">
              <MotifInput
                aria-label="Input"
                id="input"
                label="Input"
                onChange={(e) => handleTitleChange(e.target.value)}
                value={title}
              />
              {errors.title && (
                <MotifErrorMessage className="errMsg">
                  {errors.title}
                </MotifErrorMessage>
              )}
            </MotifFormField>
          </div>
        </div>

        <div className="text-editor ">
          <RichTextEditor
            editorContentError={errors?.editorContent}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            handleEditorDataChange={handleEditorContentChange}
          />
          {errors.editorContent && (
            <MotifErrorMessage className="errMsg">
              {errors.editorContent}
            </MotifErrorMessage>
          )}
        </div>

        <div className="timegroup">
          <div className="form-group">
            <div className="InputWithLabel">
              <div className="Label-input">Start Date</div>
              <MotifDatePicker
                clearIcon={null}
                className="date-picker"
                dayPlaceholder="DD"
                format="yyyy/MM/dd"
                onChange={(value) => handleChange("startDate", value)}
                value={startDate}
              />
              {errors.startDate && (
                <MotifErrorMessage>{errors.startDate}</MotifErrorMessage>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="InputWithLabel">
              <div className="Label-input">Start time (UTC)</div>
              <MotifFormField className="error-box">
                <MotifTimeInput
                  format="HH:mm"
                  value={startTime}
                  onChange={(value) => handleChange("startTime", value)}
                />
                {errors.startTime && (
                  <MotifErrorMessage>{errors.startTime}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
        </div>

        <div className="timegroup">
          <div className="form-group">
            <div className="InputWithLabel">
              <div className="Label-input">End Date</div>
              <MotifFormField className="error-box">
                <MotifDatePicker
                  className="date-picker"
                  clearIcon={null}
                  dayPlaceholder="DD"
                  format="yyyy/MM/dd"
                  onChange={(value) => handleChange("endDate", value)}
                  value={endDate}
                />
                {errors.endDate && (
                  <MotifErrorMessage>{errors.endDate}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
          <div className="form-group">
            <div className="InputWithLabel">
              <div className="Label-input">End time (UTC)</div>
              <MotifFormField className="error-box">
                <MotifTimeInput
                  format="HH:mm"
                  value={endTime}
                  onChange={(value) => handleChange("endTime", value)}
                />
                {errors.endTime && (
                  <MotifErrorMessage>{errors.endTime}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNotification;
