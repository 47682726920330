import React, { useContext, useEffect, useState } from "react";
import { MotifCheckbox, MotifFormField, MotifSearch } from "@ey-xd/motif-react";
import _ from "lodash";
import {
  documentLibraryTabStates,
  uploadDocumentConfirmMsg,
  uploadDocumentCountExceedMsg,
  uploadDocumentDuplicateMsg,
  uploadDocumentPasswordProtectedMsg,
  uploadDocumentSizeExceedMsg,
  uploadDocumentSpecialCharsMsg,
  uploadDocumentUnSupportedMsg,
} from "../../../data/constant";
import Utils from "../../../utils/Utils";
import search from "../../../assets/image/search.svg";
import PropTypes from "prop-types";

export function DocumentUploaderHeader(props) {
  const utils = new Utils();
  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);
  const [totalDocumentsSize, setTotalDocumentsSize] = useState(0);
  const [fileSearchValue, setFileSearchValue] = useState("");
  const [isPdfFilter, setIsPdfFilter] = useState(false);
  const [isDocFilter, setIsDocFilter] = useState(false);
  const [isPptFilter, setIsPptFilter] = useState(false);
  const [isXlsFilter, setIsXlsFilter] = useState(false);
  const [isTxtFilter, setIsTxtFilter] = useState(false);
  const [selectedExtensions, setSelectedExtensions] = useState([]);
  const [isDocumentCountExceeds, setIsDocumentCountExceeds] = useState(false);
  const [isDocumentSizeExceeds, setIsDocumentSizeExceeds] = useState(false);

  useEffect(() => {
    let totalFilesCount = props?.documentStates?.documentsCount;
    let totalFilesSize = props?.documentStates?.documentsSize;
    let totalCount = props.documents.length;
    let totalSize = _.sumBy(props.documents, (doc) => Number(doc?.file?.size)) ?? 0;
    setTotalDocumentsCount(totalFilesCount + totalCount);
    setTotalDocumentsSize(totalFilesSize + totalSize);

    if (
      totalFilesCount + totalCount >
      props?.documentStates?.maxDocumentCount
    ) {
      setIsDocumentCountExceeds(true);
    } else if (
      utils.getBytesInMB(totalFilesSize + totalSize) >
      props?.documentStates?.maxDocumentSize * 1024
    ) {
      setIsDocumentSizeExceeds(true);
      setIsDocumentCountExceeds(false);
    } else {
      setIsDocumentSizeExceeds(false);
      setIsDocumentCountExceeds(false);
    }

    if (fileSearchValue || selectedExtensions.length) {
      let documentList = [];

      if (fileSearchValue) {
        let docs = _.filter(props.documents, function (item) {
          return item.file.name
            .toLowerCase()
            .includes(fileSearchValue.toLowerCase());
        });
        documentList = docs;
      } else {
        documentList = props.documents;
      }
      if (selectedExtensions.length) {
        let data = _.filter(documentList, function (item) {
          return (
            selectedExtensions.indexOf(utils.getFileExtension(item.file.name)) >
            -1
          );
        });
        documentList = data;
      }
      props.onFilterDocuments(documentList);
    } else {
      props.onFilterDocuments(props.documents);
    }
  }, [props.documents, fileSearchValue, selectedExtensions]);

  useEffect(() => {
    props.onShowDocumentDetails({
      documentCount: isDocumentCountExceeds,
      documentSize: isDocumentSizeExceeds,
    });
  }, [isDocumentCountExceeds, isDocumentSizeExceeds]);

  /**
   * Description
   * -----------
   * This method filters the documents by its extension.
   * Parameters:
   * extension: selected filter extension value.
   * checked: extension checkbox filter selection value.
   */
  const filterDocuments = (extension, checked) => {
    if (extension.length > 0) {
      _.forEach(extension, function (ext) {
        switch (ext) {
          case "pdf":
            setIsPdfFilter(checked);
            break;
          case "ppt":
          case "pptx":
            setIsPptFilter(checked);
            break;
          case "doc":
          case "docx":
            setIsDocFilter(checked);
            break;
          case "xls":
          case "xlsx":
            setIsXlsFilter(checked);
            break;
          case "txt":
            setIsTxtFilter(checked);
            break;
          default:
            break;
        }
      });
    }

    if (checked) {
      let arrayExtension = [...selectedExtensions, ...extension];
      setSelectedExtensions(_.union(arrayExtension));

      console.log(arrayExtension);
    } else {
      let removeExtensionArray = _.filter(selectedExtensions, function (e) {
        return extension.indexOf(e) <= -1;
      });
      setSelectedExtensions(removeExtensionArray);
    }
  };

  return (
    <>
      {[
        documentLibraryTabStates.ShowDocuments,
        documentLibraryTabStates.ModifyDocuments,
        documentLibraryTabStates.PasswordProtectedDocuments,
        documentLibraryTabStates.SpecialCharsDocuments,
        documentLibraryTabStates.DuplicateDocuments,
        documentLibraryTabStates.UnsupportedDocuments,
      ].includes(props.activeTabStates) && (
        <>
          {(props.activeTabStates === documentLibraryTabStates.ShowDocuments ||
            props.activeTabStates ===
              documentLibraryTabStates.ModifyDocuments) && (
            <>
              <div className="document-header">
                <div className="header-wrapper">
                  <span className="document-uploader-title">Document list</span>
                  {!isDocumentCountExceeds && !isDocumentSizeExceeds && (
                    <span className="document-uploader-description">
                      {uploadDocumentConfirmMsg}
                    </span>
                  )}
                  {isDocumentCountExceeds && (
                    <span className="document-invalid-files-description">
                      {uploadDocumentCountExceedMsg}
                    </span>
                  )}
                  {isDocumentSizeExceeds && (
                    <span className="document-invalid-files-description">
                      {uploadDocumentSizeExceedMsg}
                    </span>
                  )}
                </div>

                <MotifFormField>
                  <img className="searchIconDocument" src={search} alt="" />
                  <MotifSearch
                    className="searchTxtBoxDocument"
                    aria-label="Search"
                    hideClearButton
                    id="docs-search"
                    placeholder="Search"
                    value={fileSearchValue}
                    onChange={(e) => {
                      setFileSearchValue(e.target.value);
                    }}
                  />
                </MotifFormField>
              </div>
              <div className="document-checkbox-filter-wrapper">
                <div className="document-checkbox-filers">
                  <div className="checkbox-filters-box">
                    <div>
                      <MotifCheckbox
                        id="pdfDocFilter"
                        name="pdfDocFilter"
                        className="checkbox-filter"
                        onChange={(e) => {
                          filterDocuments(["pdf"], e.target.checked);
                        }}
                        checked={isPdfFilter}
                      >
                        PDF
                      </MotifCheckbox>
                    </div>
                    <div>
                      <MotifCheckbox
                        id="pptDocFilter"
                        name="pptDocFilter"
                        className="checkbox-filter"
                        onChange={(e) => {
                          filterDocuments(["ppt", "pptx"], e.target.checked);
                        }}
                        checked={isPptFilter}
                      >
                        PPTX
                      </MotifCheckbox>
                    </div>
                    <div>
                      <MotifCheckbox
                        id="docDocFilter"
                        name="docDocFilter"
                        className="checkbox-filter"
                        onChange={(e) => {
                          filterDocuments(["doc", "docx"], e.target.checked);
                        }}
                        checked={isDocFilter}
                      >
                        DOC
                      </MotifCheckbox>
                    </div>
                    <div>
                      <MotifCheckbox
                        id="xlsDocFilter"
                        name="xlsDocFilter"
                        className="checkbox-filter"
                        onChange={(e) => {
                          filterDocuments(["xls", "xlsx"], e.target.checked);
                        }}
                        checked={isXlsFilter}
                      >
                        XLS
                      </MotifCheckbox>
                    </div>
                    <div>
                      <MotifCheckbox
                        id="txtDocFilter"
                        name="txtDocFilter"
                        className="checkbox-filter"
                        onChange={(e) => {
                          filterDocuments(["txt"], e.target.checked);
                        }}
                        checked={isTxtFilter}
                      >
                        TXT
                      </MotifCheckbox>
                    </div>
                  </div>
                  <span className="checkbox-filter-text">
                    Select to filter by file type
                  </span>
                </div>
                <div className="document-states-wrapper">
                  <span
                    className={`document-states-value ${
                      isDocumentCountExceeds
                        ? "document-states-value-error"
                        : ""
                    }`}
                  >
                    {totalDocumentsCount}/
                    {props?.documentStates?.maxDocumentCount}
                  </span>
                  <span
                    className={`document-states-value ${
                      isDocumentSizeExceeds ? "document-states-value-error" : ""
                    }`}
                  >
                    {utils.getBytesInMB(totalDocumentsSize)}MB/
                    {props?.documentStates?.maxDocumentSize}GB
                  </span>
                </div>
              </div>
            </>
          )}
          {props.activeTabStates ===
            documentLibraryTabStates.PasswordProtectedDocuments && (
            <div className="document-header">
              <div className="header-wrapper">
                <span className="document-uploader-title">
                  {uploadDocumentPasswordProtectedMsg}
                </span>
                <span className="document-uploader-description">
                  Files without a password will be discarded
                </span>
              </div>
            </div>
          )}
          {props.activeTabStates ===
            documentLibraryTabStates.SpecialCharsDocuments && (
            <div className="document-header">
              <div className="header-wrapper">
                <span className="document-uploader-title">Document list</span>
                <span className="document-invalid-files-description">
                  {uploadDocumentSpecialCharsMsg}
                </span>
              </div>
            </div>
          )}
          {props.activeTabStates ===
            documentLibraryTabStates.DuplicateDocuments && (
            <div className="document-header">
              <div className="header-wrapper">
                <span className="document-uploader-title">Document list</span>
                <span className="document-invalid-files-description">
                  {uploadDocumentDuplicateMsg}
                </span>
              </div>
            </div>
          )}
          {props.activeTabStates ===
            documentLibraryTabStates.UnsupportedDocuments && (
            <div className="document-header">
              <div className="header-wrapper">
                <span className="document-uploader-title">Document list</span>
                <span className="document-invalid-files-description">
                  {uploadDocumentUnSupportedMsg}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default DocumentUploaderHeader;

DocumentUploaderHeader.propTypes = {
  documents: PropTypes.array,
  activeTabStates: PropTypes.string,
  documentStates: PropTypes.object,
  onFilterDocuments: PropTypes.func,
  onShowDocumentDetails: PropTypes.func,
};
