import axios from "./axiosWithMsal";
import CommonService from "./commonService.js";

class PlatformService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = process.env.REACT_APP_PLATFORM_API_URL;
  }

  /**
   * Description
   * -----------
   * This method fetches all predefined scopes from scope master that can be used
   * in create project
   */
  getScopeDetails = async () => {
    try {
      return axios
        .get(this.apiUrl + "Project/GetScopeList", {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        })
        .then((data) => {
          return data?.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "Get Scope Master"
      );
    }
  };

  /**
   * This method returns structured data for insights tree
   * @returns Object
   */
  getScopeTreeData = async (scopeId) => {
    try {
      return await axios
        .get(this.apiUrl + "Platform/GetScopeTreeDetails?ScopeId=" + scopeId, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        })
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "GetScopeTreeData"
      );
    }
  };

  /**
   * This method returns instant insight active prompts list
   * @returns Object
   */
  getInstantInsightActivePrompts = async (subSectionId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetPlatformPromptDetails?SubSectionId=" +
            subSectionId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getInstantInsightPrompts"
      );
    }
  };

  /**
   * This method returns instant insight test prompts list
   * @returns Object
   */
  getInstantInsightTestPrompts = async (subSectionId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetPlatformTestPromptDetails?SubSectionId=" +
            subSectionId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getInstantInsightPrompts"
      );
    }
  };
  /**
   * This method delete instant insight prompts list
   * @returns sucess
   */
  DeletePlatformPrompts = async (platformPromptRequest) => {
    await axios
      .post(
        this.apiUrl + "Platform/DeletePlatformPrompts",
        platformPromptRequest,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .catch((error) => {
        this.commonService.logException(
          error,
          "Platform Component",
          "promptDelete"
        );
      });
  };

  /**
   * This method delete sat prompts list
   * @returns sucess
   */
  DeleteSatPlatformPrompts = async (platformPromptRequest) => {
    await axios
      .post(
        this.apiUrl + "Platform/DeleteSystemPromptSaT",
        platformPromptRequest,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .catch((error) => {
        this.commonService.logException(
          error,
          "Platform Component",
          "promptDelete"
        );
      });
  };

  /**
   * This method discards the category/section/subsection hierarchy
   */
  DiscardHierarchy = async (params) => {
    await axios
      .post(
        this.apiUrl + "Platform/DiscardInstantInsightScopeStructure",
        params,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .catch((error) => {
        this.commonService.logException(
          error,
          "Platform Component",
          "Discard hierarchy"
        );
      });
  };

  /**
   * This method moves platform prompts to another subsection
   * @returns sucess
   */
  moveInstantInsightPrompts = async (platformPromptRequest) => {
    await axios
      .post(
        this.apiUrl + "Platform/MovePlatformPrompts",
        platformPromptRequest,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then(function (response) {
        return true;
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Platform Component",
          "moveInstantInsightPrompts"
        );
        return false;
      });
    return true;
  };

  /**
   * This method publish confirm
   * @returns sucess
   */
  ConfirmPublish = async (payload) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/PublishTestPrompts",
        payload,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "promptPublish"
      );
    }
  };

  /**
   * This method saves a new scope tree hierarchy.
   * @returns Response status code.
   */
  saveScopeTreeHierarchy = async (payload) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/SaveScopeHierarchy",
        payload,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform service",
        "Save scope tree hierarchy"
      );
      throw error;
    }
  };

  /**
   * This method edits the prompt text.
   * @returns {Boolean} Status of edit operation.
   */
  editSystemTestPromptText = async (prompt) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/EditSystemPrompts",
        prompt,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status == 200 ? true : false;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "editSystemTestPromptText"
      );
      return false;
    }
  };

  /**
   * This method is to get the api details of the system prompts
   * @returns sucess
   */
  getSystemPromptAPIList = async (projectScopeId, type) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetProjectScopeAPIList?ProjectScopeId=" +
            projectScopeId +
            "&Type=" +
            type,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getSystemPromptAPIList"
      );
    }
  };

  /**
   * This method returns system prompts
   * @returns Object
   */
  getSystemPrompts = async (category, type, scopeId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetSystemPrompts?Category=" +
            category +
            "&Type=" +
            type +
            "&ScopeId=" +
            scopeId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getSystemPrompts"
      );
    }
  };

  /**
   This method is to save renames scope structure to database
   * @returns sucess
   */
  renameScopeStructure = async (structureRequest) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/RenameScopeStructure",
        structureRequest,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status === 200 ? true : false;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "renameScopeStructure"
      );
      return false;
    }
  };
  /**
   * This method returns test prompt data for instant insights
   * @returns Object
   */
  AddEditInstantInsightTestPrompt = async (testPayload) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/AddEditInstantInsightTestPrompt",
        testPayload,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status === 200 ? true : false;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "renameScopeStructure"
      );
      return false;
    }
  };

  /**
   * This method returns test prompt data for Sat Prompt
   * @returns Object
   */
  addEditSaTPrompt = async (testPayload) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/AddEditSATPrompt",
        testPayload,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      return response.status === 200 ? true : false;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "addEditSATPrompt"
      );

      return false;
    }
  };

  /**
   * This method publish the instant insight test prompts
   * @returns Object
   */
  publishInstantInsightTestPrompt = async (request) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/PublishInstantInsightsPrompt",
        request,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status === 200 ? true : false;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "publishInstantInsightTestPrompt"
      );
      return false;
    }
  };

  /**
   * This method publish the Sat test prompts
   * @returns Object
   */
  publishSatTestPrompt = async (request) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/PublishSaTPrompt",
        request,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status === 200 ? true : false;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "publishSatTestPrompt"
      );
      return false;
    }
  };
  /**
   * Api call to a publish scope.
   * @param {*} scopeId
   * @param {*} promptAPI
   * @returns null
   */
  async publishSystemPrompts(scopeId = String, promptAPI = String) {
    return axios
      .post(
        this.apiUrl + "Platform/PublishSystemPrompts",
        {
          scopeId: scopeId,
          promptAPI: promptAPI,
        },
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Manage Project Scope Component",
          "UpdateScope"
        );
        return false;
      });
  }

  /**
   * Api call to a new scope.
   * @param {*} scopeName
   * @param {*} scopeId
   * @returns null
   */
  async saveScope(scopeName, scopeId) {
    let request = {};
    if (scopeId) {
      request = {
        ScopeId: scopeId,
        ScopeName: scopeName,
        Type: "E",
      };
    } else {
      request = {
        scopeName: scopeName,
      };
    }
    return axios
      .post(this.apiUrl + "Platform/AddEditProjectScope/", request, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      })
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Manage Project Scope Component",
          "SaveScope"
        );
        return false;
      });
  }

  /**
   * Api call to a SaT scope.
   * @param {*} scopeId
   * @returns null
   */

  getSaTPromptData = async (projectScopeId, type) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetSATPrompts?Type=" +
            type +
            "&ScopeId=" +
            projectScopeId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getSystemPromptAPIList"
      );
    }
  };
  
  /**
   * This method is to get the api details of the system prompts in powerpoint method
   * @returns sucess
   */
  getPowerPointSystemPromptAPIList = async (projectScopeId, type, application) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetProjectScopeAPIList?ProjectScopeId=" +
            projectScopeId +
            "&Type=" +
            type +
            '&application=' + application,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getSystemPromptAPIList"
      );
    }
  };

  publishNotification = async (payload) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/CreateNotification",
        payload,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status;
    } catch (error) {
      this.commonService.logException(
        error,
        "Createnotification Component",
        "publishNotification"
      );
    }
  };
}
export default PlatformService;
