import React, { useEffect, useRef, useState } from 'react';
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import { MotifButton, MotifFormField, MotifLabel, MotifRichTextEditor, MotifInput } from "@ey-xd/motif-react";
import Utils from '../../../../utils/Utils';


export default function AddEditPromptModal(props) {
    const utils = new Utils();
    const { onCancelBtnClick, handleConfirmClick, onPromptTxtChange, onPromptTitlechange, promptTitle, promptText, maxLengthReached, editIITestPrompt, handleBlur, errorMessageForTitle, isConfirmBtnDisabled, errorMessageForPrompt } = props;
    const promptRef = useRef(null);

    const motifReactEditorModules = {
        toolbar: [[{
            list: 'ordered'
        }, {
            list: 'bullet'
        },
        ]],
        clipboard: {
            matchVisual: false
        }
    };

    useEffect(() => {
        const checkModalLoaded = () => {
            const promptEditor = promptRef.current;
            if (promptEditor) {
                const numberedBtn = promptEditor.querySelector(
                    `button[value="ordered"]`
                );
                const bulletBtn = promptEditor.querySelector(`button[value="bullet"]`);
                if (numberedBtn) {
                    utils.addCustomTooltip(numberedBtn, 'Numbered list', 'modalTooltipNumberedList', 'bottom');
                }
                if (bulletBtn) {
                    utils.addCustomTooltip(bulletBtn, 'Bulleted list', 'modalTooltipBulletedList', 'bottom');
                }
            } else {
                // Retry after a short delay if the modal is not yet loaded
                setTimeout(checkModalLoaded, 10);
            }
        };
        checkModalLoaded();

        return () => {
            let tooltipNumberedList = document.getElementById('modalTooltipNumberedList');
            if (tooltipNumberedList) {
                document.body.removeChild(tooltipNumberedList);
            }
            let tooltipBulletedList = document.getElementById('modalTooltipBulletedList');
            if (tooltipBulletedList) {
                document.body.removeChild(tooltipBulletedList);
            }
        };
    }, []);
    const confirmDisableCheck = () => {
        let promptTextVal = promptText;
        promptTextVal = promptTextVal?.replace(/<[^>]+>/g, '').trim();

        return promptTextVal === '' || !promptTitle;
    };

    return (
        <MotifModal
            id="addTestPrompt"
            show={true}
            size="lg"
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
            onClose={onCancelBtnClick}
            className="dialog-modal"
        >
            <MotifModalHeader>
                <div className="header-title">
                    {!editIITestPrompt ? 'Add New Prompt' : 'Edit Prompt'}
                </div>
            </MotifModalHeader>
            <MotifModalBody>
                <div>
                    <MotifFormField className="title-label">
                        <MotifLabel>
                            Title
                        </MotifLabel>
                    </MotifFormField>
                </div>
                <div className="info">
                    <MotifFormField className="password-input-wrapper">
                        <MotifInput
                            label="promptTitle"
                            onChange={onPromptTitlechange}
                            type="text"
                            value={promptTitle}
                            placeholder={"Enter a title"}
                            className={`prompt-textarea ${errorMessageForTitle ? 'error-border' : ''}`}
                            onBlur={handleBlur}
                        />
                    </MotifFormField>
                </div>
                <div className="error_container">
                    {maxLengthReached && !errorMessageForTitle && (
                        <div className="max_limit">
                            Maximum limit reached - 50 characters
                        </div>
                    )}
                    {errorMessageForTitle && <div className='errorText'>
                        {errorMessageForTitle}
                    </div>}
                </div>
                <div>
                    <MotifFormField className="title-prompt">
                        <MotifLabel>
                            Prompt
                        </MotifLabel>
                    </MotifFormField>
                </div>
                <div ref={promptRef}>
                    <MotifRichTextEditor
                        id="rtePromptText"
                        label="promptText"
                        onChange={onPromptTxtChange}
                        value={promptText}
                        placeholder="Enter a prompt"
                        modules={motifReactEditorModules}
                        className={errorMessageForPrompt ? "ql-error" : null}
                    />
                </div>
                <div className="error_container">                    
                    {errorMessageForPrompt && <div className='errorText'>
                        {errorMessageForPrompt}
                    </div>}
                </div>
            </MotifModalBody>
            <MotifModalFooter>
                <div className="footer-btns-div">
                    <div>
                        <MotifButton
                            variant="secondary"
                            onClick={onCancelBtnClick}
                            className="cancel-btn"
                        >
                            Cancel
                        </MotifButton>
                    </div>
                    <div>
                        <MotifButton
                            className="confirm-btn"
                            onClick={handleConfirmClick}
                            disabled={isConfirmBtnDisabled || confirmDisableCheck()}
                        >
                            Confirm
                        </MotifButton>
                    </div>
                </div>
            </MotifModalFooter>
        </MotifModal>
    )
}
