import React, { useEffect, useState } from "react";
import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import _ from "lodash";
import "./ConfirmPublishPromptModal.css";

const ConfirmPublishPromptModal = (props) => {
  const [newPromptList, setNewPromptList] = useState([]);
  const [editedPromptList, setEditedPromptList] = useState([]);

  useEffect(() => {
    if (props?.publishPromptList?.length) {
      setNewPromptList(
        _.filter(props?.publishPromptList, { newEditFlag: "New" })
      );
      setEditedPromptList(
        _.filter(props?.publishPromptList, { newEditFlag: "Edit" })
      );
    }
  }, [props.publishPromptList]);

  const confirmPublishInstantInsightPrompt = () => {
    props.onPromptPublishComplete();
  };

  /**
   * This method will be closed the modal
   */
  const btnModalCloseClick = () => {
    props.closeConfirmMovePrompt(false);
  };
  return (
    <div>
      <MotifModal
        id="motifModalConfirmPublishPrompt"
        className="genericModal"
        size="lg"
        show={true}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={btnModalCloseClick}
      >
        <div>
          <MotifModalHeader>
            <div className="confirm-move-prompt-modal-header">
              Publish to Active Prompts
            </div>
          </MotifModalHeader>
        </div>
        <MotifModalBody>
          <div className="confirm-publish-ii-prompt-body-container">
            <div className="confirm-publish-prompt-type-container">
              {editedPromptList?.length > 0 && (
                <>
                  <div className="confirm-publish-ii-prompt-header">
                    Edited Prompts
                    <div className="confirm-publish-ii-prompt-description">
                      Prompt(s) with the same name already exist in Active
                      Prompts
                    </div>
                  </div>
                  <div className="confirm-publish-ii-prompt-list">
                    <ul>
                      {editedPromptList?.map((prompt) => {
                         if (props.tab === "InstantInsightPrompts" ) {
                        return <li>{prompt?.platformPromptTitle}</li>
                         }
                        if (props.tab === "SaTPromptLibrary" ) {
                          return <li>{prompt?.title}</li>;
                        }
                        return null;
                      })}
                    </ul>
                  </div>
                </>
              )}

              {newPromptList?.length > 0 && (
                <>
                  <div className="confirm-publish-ii-prompt-header">
                    New Prompts
                    <div className="confirm-publish-ii-prompt-description">
                      Prompt(s) to be added to Active Prompts
                    </div>
                  </div>
                  <div className="confirm-publish-ii-prompt-list">
                    <ul>
                      {newPromptList?.map((prompt) => {
                        if (props.tab === "InstantInsightPrompts" ) {
                        return <li>{prompt?.platformPromptTitle}</li>;
                        }
                        if (props.tab === "SaTPromptLibrary" ) {
                          return <li>{prompt?.title}</li>;
                        }
                        return null;
                      })}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="share-alert-btns-div">
            <div>
              <MotifButton
                variant="primary-alt"
                onClick={() => {
                  btnModalCloseClick();
                }}
                className="share-alert-cancel-button"
              >
                Cancel
              </MotifButton>
            </div>
            <div>
              <MotifButton
                className="share-alert-go-to-tw-button"
                onClick={confirmPublishInstantInsightPrompt}
              >
                Confirm
              </MotifButton>
            </div>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
};
export default ConfirmPublishPromptModal;
