import { React, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MotifButton, MotifTooltip } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import _ from "lodash";
import MotifToast from "@ey-xd/motif-react/Toast";
import eyeImage from "../../../../../assets/image/eye.svg";
import "./PromptAction.css"
import check_circle from "../../../../../assets/image/check-circle.svg";
import PlatformService from "../../../../../services/platformService";
import { DashboardContext } from "../../../../../context/dashboardContext";

const PromptAction = (props) => {
  const { scopeAll } = useContext(DashboardContext);
  const { selectedScopeId } = useSelector((state) => state.promptManagement);
  const { scopeList } = useSelector(state => state.scope);
  const [showModal, setShowModal] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [currentSelectedScope, setCurrentSelectedScope] = useState(null);
  const [showConfirmPublishTestPrompt, setShowConfirmPublishTestPrompt] = useState(false);
  const [hasAtLeastOneEditOrNew, setHasAtLeastOneEditOrNew] = useState(false);

  const platformService = new PlatformService();

  useEffect(() => {
    if (props.checkedPrompts) {
      const hasAtLeastOneEditOrNew = props.checkedPrompts.some((prompt) => {
        return (prompt.newEditFlag === "Edit" || prompt.newEditFlag === "New")
      })
      setHasAtLeastOneEditOrNew(hasAtLeastOneEditOrNew);
    }
  }, [props.checkedPrompts])

  useEffect(() => {
    const currentSelectedScope = scopeList.filter((scope) => {
      return scope.scopeId === selectedScopeId;
    })
    setCurrentSelectedScope(currentSelectedScope[0]);
  }, [scopeAll, selectedScopeId, props.node])

  const handlePublishClick = () => {
    setShowModal(true);
  };

  const handleConfirmPublish = async () => {

    const SPLId = props.checkedPrompts.map((item) => item.platformPromptId).join(",");


    const scopeIds = [...new Set(props.checkedPrompts.map((item) => item.scopeId))][0];

    const requestPayload = {
      ScopeId: scopeIds,
      SPLId: SPLId,
    };

    let response = await platformService.ConfirmPublish(requestPayload)

    if (response == 200) {
      props.getSystemPrompts();
      setShowToast(true);

      setToastType("success");
      setToastMessage("Prompt(s) published successfully");

    }
    setShowModal(false);
  };

  const handleCancelPublish = () => {
    setShowModal(false);
  };

  const onPublishPromptSuccess = async () => {
    let request = {
      platformPromptTestIds: _.map(props?.checkedPrompts, t => t.platformPromptId)
    };
    let isSuccess = await platformService.publishInstantInsightTestPrompt(request);
    if (isSuccess) {
      setShowConfirmPublishTestPrompt(false);
      props?.onSuccessPublishPrompt();
    }
  }

  return (
    <>
      <div className="ii-prompt-action-section">
        <MotifButton
          label="btnPublishIIPrompt"
          className="li-move-prompt"
          disabled
          onClick={handlePublishClick}
        >
          <img src={eyeImage} alt="Publish Prompt"></img>
          Publish
        </MotifButton>
      </div>

      {showToast && (
        <MotifToast
          icons={{ toast: <img src={check_circle} alt=""></img> }}

          variant="success"
          className={"publish-motif-toast"}
          position="bottom"
          onClose={() => setShowToast(false)}
        >
          <div className="toast-body">{toastMessage}</div>
        </MotifToast>
      )}

    </>
  );
};

export default PromptAction;
