import React, { useState } from "react";
import PromptTabDetail from "../Common/PromptTabDetail";

const SaTPromptLibrary = () => {

    const [displayAddScopeSection, setDisplayAddScopeSection] = useState(false);

    const updateAddScopeSectionVisibility = (value) => {
        setDisplayAddScopeSection(value);
    }

    return (
        <>
            {
                !displayAddScopeSection ?
                    <PromptTabDetail tab={"SaTPromptLibrary"} updateAddScopeSectionVisibility={updateAddScopeSectionVisibility} />
                    :
                    null
            }
        </>
    )
};
export default SaTPromptLibrary;
