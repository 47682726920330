import { createContext, useEffect, useState } from "react";
import { paansConfig } from "../config/paansConfig";
import axios from "../services/axiosWithMsal";
import commonService from "../services/commonService";

export const DashboardContext = createContext();

const DashboardContextProvider = (props) => {
  let apiUrl = process.env.REACT_APP_API_URL;
  let apiUrlUS = process.env.REACT_APP_API_URL_US;
  let apiUrlEU = process.env.REACT_APP_API_URL_EU;
  let apiUrlAU = process.env.REACT_APP_API_URL_AU;
  let hostingEnv = process.env.REACT_APP_HOSTING_ENV;

  let paansRedirectUrl = paansConfig.PAANSacceptUrl;

  sessionStorage.setItem("Email", props.userEmail);
  const userEmail = props.userEmail;

  const [paans, setPaans] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectCountry, setProjectCountry] = useState("");

  const [allProjects, setAllProjects] = useState([]);
  const [getAlldocuments, setDocuments] = useState([]);
  const [getDocumentSettings, setGetDocumentSettings] = useState([]);
  const [viewPrompt, setViewPrompt] = useState([]);
  const [isNewPromptCoversation, setIsNewPromptCoversation] = useState(false);
  const [showViewSourceDocIconOnEditButtonClick, setShowViewSourceDocIconOnEditButtonClick] = useState({});
  const [
    isDocumentUploadProcessInitiated,
    setIsDocumentUploadProcessInitiated,
  ] = useState(false);
  const [showDocumentUploadMessage, setShowDocumentUploadMessage] =
    useState(false);
  const [isDocumentDeleteProcessInitiated, setIsDocumentDeleteProcessInitiated] =
    useState(false);
  const [isLocalUploadInitiated, setIsLocalUploadInitiated] = useState(false);
  const [isSharepointUploadInitiated, setIsSharepointUploadInitiated] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [documentUploadSubmitted, setDocumentUploadSubmitted] = useState(false);
  const [isRefreshInitiated, setIsRefreshInitiated] =
    useState(false);
  const [scopeAll, setScopeAll] = useState([]);
  const [hidePromptSearchInput, setHidePromptSearchInput] = useState(false);
  const projectMasterData = require("../data/projectMasterData");
  const [isPromptSubmitted, setIsPromptSubmitted] = useState(false);
  const [flipValueToTriggerRerender, setFlipValueToTriggerRerender] = useState(false);
  const [latestPromptId, setLatestPromptId] = useState(null);
  const [viewInstantPrompt, setViewInstantPrompt] = useState({});
  const [isRefreshFavorites, setIsRefreshFavorites] = useState(false);
  const [selectedPWFavoritePrompt, setSelectedPWFavoritePrompt] = useState(null);
  const [refreshUploadMessage, setRefreshUploadMessage] = useState(null);

  useEffect(() => {
    function fetchPaans() {
      // Fetch PAANS APPSecret from KeyVault
      axios
        .get(
          apiUrl + "Project/GetKeyVaultValue?keyVaultName=PAANS-SECRET", {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          let PAANSAppIdSecret = data?.data;
          fetch(paansConfig.PAANSCheckEndPoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: PAANSAppIdSecret,
            },
            body: JSON.stringify({
              region: "Global",
              language: "EN",
              appId: paansConfig.PAANSApiID,
              policyType: 1,
              userId: props.userEmail,
              includeContent: false,
              acceptUrl: paansRedirectUrl,
              rejectUrl: paansConfig.PAANSrejectUrl,
              getContent: false,
            }),
          })
            .then((res) => res.json())
            .then((result) => {
              getProjects(props.userEmail);

              return setPaans(result);
            });
        });
    }
    fetchPaans();
    getAllprojects();
  }, []);

  const getAPIUrl = (dataHostLocationId) => {
    let hostingEnv = process.env.REACT_APP_HOSTING_ENV;
    if (!dataHostLocationId) {
      dataHostLocationId = localStorage.getItem("currentDataHostingLocation");
    }

    if (hostingEnv === "UAT" || hostingEnv === "PROD") {
      if (dataHostLocationId === '1') {
        return process.env.REACT_APP_API_URL_US;
      } else if (dataHostLocationId === '2') {
        return process.env.REACT_APP_API_URL_EU;
      } else {
        return process.env.REACT_APP_API_URL_AU;
      }
    } else if (hostingEnv === "QA") {
      if (dataHostLocationId === '1') {
        return process.env.REACT_APP_API_URL_US;
      } else if (dataHostLocationId === '2') {
        return process.env.REACT_APP_API_URL_EU;
      } else {
        return process.env.REACT_APP_API_URL_EU;
      }
    } else {
      return process.env.REACT_APP_API_URL;
    }
  }

  const getProjects = (userEmail) => {

    // Get projects from all three regions

    const userEmailRequest = {
      email: userEmail,
      country: [],
    };

    if (hostingEnv === "DEV") {
      // call only eu/neds url
      axios
        .post(apiUrl + "Project/GetProjectMasterData", userEmailRequest)
        .then((data) => {
          return setProjects(data?.data);
        });
    }
    else if (hostingEnv === "QA") {
      // call eu and us urls
      axios
        .post(apiUrlEU + "Project/GetProjectMasterData", userEmailRequest)
        .then((data1) => {
          axios
            .post(apiUrlUS + "Project/GetProjectMasterData", userEmailRequest)
            .then((data2) => {
              return setProjects([...data2.data, ...data1.data]);
            });
        });
    }
    else if (hostingEnv === "UAT" || hostingEnv === "PROD") {
      // same eu
      axios
        .post(apiUrl + "Project/GetProjectMasterData", userEmailRequest)
        .then((data) => {
          return setProjects(data?.data);
        });
      axios
      .post(apiUrlEU + "Project/GetProjectMasterData", userEmailRequest)
      .then((data1) => {
        axios
        .post(apiUrlUS + "Project/GetProjectMasterData", userEmailRequest)
        .then((data2) => {
          axios
          .post(apiUrlAU + "Project/GetProjectMasterData", userEmailRequest)
          .then((data3) => {
            //return setProjects(data?.data);
            return setProjects([...data3?.data, ...data2?.data, ...data1?.data]);
          });
        });
      });
    }


  };

  // useEffect(() => {
  //   function getProjectAll() {
  //     axios
  //       .get(apiUrl + "Project/GetProjectDetails?Project_UUID=all", {
  //         headers: {
  //           "Content-type": "application/json; charset=UTF-8",
  //         },
  //       })
  //       .then((data) => {
  //         return setProjectsAll(data?.data);
  //       });
  //   }
  //   getProjectAll();
  // }, []);

  const GetProjectDetailById = async (ProjectiId = String, dataHostingLocation = String) => {
    try {

      let url = getAPIUrl(dataHostingLocation);
      return await axios
        .get(
          url + "Project/GetProjectDetails?Project_UUID=" + ProjectiId + "",
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (e) {
      return e;
    }
  };

  const GetDocuments = async (projectId) => {
    let url = getAPIUrl(localStorage.getItem('currentDataHostingLocation'));
    const userDocumentRequest = {
      Project_UUID: projectId,
    };
    axios
      .get(url + "Document/GetDocumentDetails", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        params: userDocumentRequest,
      })
      .then((data) => {
        return setDocuments(data?.data);
      });
  };

  const GetDocumentSettings = async () => {
    axios
      .get(apiUrl + "Project/GetDocumentSetting", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((data) => {
        return setGetDocumentSettings(data?.data);
      });
  };

  const SaveDocumentSetting = async (docNoChange, docSizeChange) => {
    let docSetRequest = {
      noOfDocuments: docNoChange,
      documentSize: docSizeChange,
    };

    const response = await fetch(apiUrlEU + "Project/CreateDocumentSetting", {
      method: "POST",
      body: JSON.stringify(docSetRequest),
      headers: { "Content-Type": "application/json" },
    });

    if (hostingEnv === 'PROD' || hostingEnv === 'UAT' || hostingEnv === 'QA') {
      const response2 = await fetch(apiUrlUS + "Project/CreateDocumentSetting", {
        method: "POST",
        body: JSON.stringify(docSetRequest),
        headers: { "Content-Type": "application/json" },
      });
    }

    if (hostingEnv === 'PROD' || hostingEnv === 'UAT') {
      const response3 = await fetch(apiUrlAU + "Project/CreateDocumentSetting", {
        method: "POST",
        body: JSON.stringify(docSetRequest),
        headers: { "Content-Type": "application/json" },
      });
    }


    return response;
  };
  const getAllprojects = async () => {
    if (hostingEnv === "DEV") {
      axios
        .get(apiUrl + "Project/GetProjectDetails?Project_UUID=all", {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          return setAllProjects(data?.data);
        });
    }
    else if (hostingEnv === "QA") {
      axios
        .get(apiUrlEU + "Project/GetProjectDetails?Project_UUID=all", {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data1) => {
          axios
            .get(apiUrlUS + "Project/GetProjectDetails?Project_UUID=all", {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            })
            .then((data2) => {
              return setAllProjects([...data2.data, ...data1.data]);
            });
        });
    }
    else if (hostingEnv === "UAT" || hostingEnv === "PROD") {
      axios
        .get(apiUrlEU + "Project/GetProjectDetails?Project_UUID=all", {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          return setAllProjects(data?.data);
        });

      axios
      .get(apiUrlEU + "Project/GetProjectDetails?Project_UUID=all", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((data1) => {
        axios
          .get(apiUrlUS + "Project/GetProjectDetails?Project_UUID=all", {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          })
          .then((data2) => {

            axios
              .get(apiUrlAU + "Project/GetProjectDetails?Project_UUID=all", {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              })
              .then((data3) => {
               return setAllProjects([...data3.data, ...data2.data, ...data1.data]);
              });
          });
      });
    }
  };
  /**
        * Description
        * -----------
        * This method fetches all predefined scopes from scope master that can be used 
        * in create project
        */
  function getScopeMaster() {
    try {
      axios
        .get(
          apiUrl + "Project/GetScopeList",
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          return setScopeAll(data?.data);
        });
    } catch (error) {
      commonService.logException(
        error,
        "Project Detail Component",
        "Get Scope Master"
      );
    }
  }

  /**
   * Load the scopes initially on dashboard
   */
  useEffect(() => {
    getScopeMaster();
  }, []);




  return (
    <DashboardContext.Provider
      value={{
        paans,
        projects,
        getProjects,
        getAllprojects,
        allProjects,
        SaveDocumentSetting,
        //projectsAll,
        GetProjectDetailById,
        getAlldocuments,
        GetDocuments,
        getDocumentSettings,
        GetDocumentSettings,
        userEmail,
        isDocumentUploadProcessInitiated,
        setIsDocumentUploadProcessInitiated,
        showDocumentUploadMessage,
        setShowDocumentUploadMessage,
        isDocumentDeleteProcessInitiated,
        setIsDocumentDeleteProcessInitiated,
        isRefreshInitiated,
        setIsRefreshInitiated,
        viewPrompt,
        setViewPrompt,
        isNewPromptCoversation,
        setIsNewPromptCoversation,
        scopeAll,
        hidePromptSearchInput,
        setHidePromptSearchInput,
        isPromptSubmitted,
        setIsPromptSubmitted,
        latestPromptId,
        setLatestPromptId,
        projectCountry,
        setProjectCountry,
        viewInstantPrompt,
        setViewInstantPrompt,
        showViewSourceDocIconOnEditButtonClick,
        setShowViewSourceDocIconOnEditButtonClick,
        flipValueToTriggerRerender,
        setFlipValueToTriggerRerender,
        isRefreshFavorites,
        setIsRefreshFavorites,
        selectedPWFavoritePrompt,
        setSelectedPWFavoritePrompt,
        isLocalUploadInitiated, 
        setIsLocalUploadInitiated,
        isSharepointUploadInitiated,
        setIsSharepointUploadInitiated,
        documentList,
        setDocumentList,
        documentUploadSubmitted, 
        setDocumentUploadSubmitted,
        refreshUploadMessage,
        setRefreshUploadMessage,
        getScopeMaster
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
export default DashboardContextProvider;
