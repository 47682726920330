import { createSlice } from "@reduxjs/toolkit";
 
const activeUserSlice = createSlice({
  name: "activeUser",
  initialState: {
    userRole: null
  },
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload.userRole;
    }
  },
});
 
export const { setUserRole } =
activeUserSlice.actions;
 
export default activeUserSlice.reducer;