import React, { useState } from "react";
import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import editPencil from "../../../../assets/image/edit.svg";
import PropTypes from "prop-types";
import { MotifChip } from "@ey-xd/motif-react";

const SaTPromptAccordion = (props) => {
  const [open, setOpen] = useState(false);
  const satEditPrompt = (props.tab === "SaTPromptLibrary" && props.type === "testPrompt") ? true : false;

  /**
   * This method is a click operation of the accordion click
   * @param {*} event
   */
  const onClickAccordion = (event) => {
    if (event.target.className === "motif-checkbox-custom") {
      props.onCheckPrompt(props?.prompt?.saTPromptId, true);
    } else if (
      event.target.parentNode.className === "motif-checkbox-checked-icon") {
      props.onCheckPrompt(props?.prompt?.saTPromptId, false);
    } else if (event.target.nodeName === "svg" || event.target.nodeName === "path") {
      setOpen(!open);
    } else if (
      event.target.nodeName === "svg" ||
      (event.target.className?.trim() !== "ii-prompt-detail-text" &&
        event.target.className?.trim() !== "motif-accordion-content")
    ) {
      setOpen(!open);
    }
  };
  return (
    <MotifAccordion
      open={open}
      alignIconRight={true}
      useChevronIcon={true}
      onClose={() => { }}
      onOpen={() => { }}
      onClick={($event) => onClickAccordion($event)}
      className={
        props?.prompt?.isChecked
          ? "ii-prompt-selected-accordion"
          : "ii-prompt-accordion"
      }
      data-testid="accordionPromptTitle"
    >
      <MotifAccordionTrigger>
        <div className="accordion-title">
          <div className="ii-response-accordion-trigger">
            {
              props.type === "testPrompt" &&
              <MotifCheckbox
                name="chkPrompt"
                checked={props?.prompt?.isChecked}
                value={props?.prompt?.isChecked}
              ></MotifCheckbox>
            }

            {props?.prompt?.title}
            {(() => {
              if (props?.prompt?.newEditFlag === "New") {
                return (
                  <MotifChip
                    onClick={null}
                    className="prompt-status-chip-new"
                  >
                    New
                  </MotifChip>
                );
              } else if (props?.prompt?.newEditFlag === "Edit") {
                return (
                  <MotifChip
                    onClick={null}
                    className="prompt-status-chip-edited"
                  >
                    Edited
                  </MotifChip>
                );
              }
            })()}
          </div>
          <div>
            <MotifIconButton onClick={
              satEditPrompt ? props.editSatTextPrompts : props.onEditIconClick
            } style={{ "display": satEditPrompt ? "block" : "none" }}>
              <img alt='icon' src={editPencil} />
            </MotifIconButton>
          </div>
        </div>
      </MotifAccordionTrigger>
      <MotifAccordionContent>
        {
          (() => {
            if (props?.prompt?.promptText != null) {
              return (
                <div className="ii-prompt-detail-text" dangerouslySetInnerHTML={{
                  __html: props?.prompt?.promptText,
                }}></div>
              )
            }
            else {
              return (<div className="ii-prompt-detail-text">No prompt found</div>)
            }
          })()
        }
      </MotifAccordionContent>
    </MotifAccordion>
  );
};

SaTPromptAccordion.propTypes = {
  prompt: PropTypes.object,
  tab: PropTypes.string,
  node: PropTypes.object,
  type: PropTypes.string,
  onCheckPrompt: PropTypes.func,
  onEditIconClick: PropTypes.func,
  editSatTextPrompts: PropTypes.func
}

export default SaTPromptAccordion;
