import React, { useState, useEffect } from "react";
import { MotifCheckbox, MotifTree, MotifTreeNode } from "@ey-xd/motif-react";

import arrowDown from "../../../../assets/image/Arrow_down.svg";
import arrowUp from "../../../../assets/image/Arrow_up.svg";

import "./TreeView.css";

export default function TreeView(props) {

    const { treeData, nodeExpansionGraph, updateAreNodesSelected, bubbleBackData } = props;
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [scopeHasStructure, setScopeHasStructure] = useState(true);
    const [selectedScope, setSelectedScope] = useState(null);
    const [message, setMessage] = useState("");

    useEffect(() => {
        setSelectedNodes([]);
    }, [props.refresh])

    useEffect(()=>{
        // Add required references and properties to tree data
        addReferences(treeData, null);
        
    },[treeData])

    useEffect(()=>{
        if(selectedNodes.length > 0){
            updateAreNodesSelected(true);
        }
        else {
            updateAreNodesSelected(false);
        }
        bubbleBackData(selectedNodes);
    }, [selectedNodes])

    useEffect(() => {
        setScopeHasStructure(false);
        if (scopeHasStructure) {
            setMessage("No Structure created Please create Category, Section and Subsections")
        }
        else if (!selectedScope) {
            setMessage("No Selection made Select a Project Scope to view the tree hierarchy")
        }

    }, [scopeHasStructure, selectedScope])

    const addReferences = (node, parent) => {
        if (treeData) {
            // Check if parent reference is present in data else add parent reference
            if (!treeData.parent) {
                node.parent = parent;
            }

            // Check if isSelected property is present in data else add isSelected property
            if (!treeData.isSelected) {
                node.isSelected = false;
            }

            if (node.children && node.children.length != 0) {
                node.children.forEach((child) => addReferences(child, node));
            }
        }
    }

    const getCheckboxIfApplicable = (node) => {
        if (props.showCheckbox) {
            return (
                <MotifCheckbox
                    key={node.value}
                    checked={node.isSelected}
                    disabled={node.isPublished}
                    onChange={(e) => {
                        
                    }}
                />
            );
        }
        return null;
    };

    const getNodeMarkup = (node) => {
        return (
            <div className={props.showCheckbox ? "div-checkbox" : "icon-only"} onClick={() => { treeNodeClick(node) }}
            >
                {getCheckboxIfApplicable(node)}
                <button className="plain-button" title={node.value && (node.value.length > 45 ? node.value : '')}
                disabled={node.disabled}
                >
                    {node.value}
                </button>
            </div>
        );
    };

    const checkIfNodeShouldBeExpanded = (node) => {
        if (nodeExpansionGraph.find((expandedNode) => {
            return expandedNode.value === node.value;
        })) {
            return true;
        }
        else {
            return false;
        }
    }

    const renderTree = (node) => {
        if (!node) return null;

        return (
            <MotifTreeNode 
                value={getNodeMarkup(node)}
                open={checkIfNodeShouldBeExpanded(node)}
                key={node.value}
                icons={{
                    close: <img src={arrowUp}></img>,
                    open: <img src={arrowDown}></img>,
                }}
                >
                {node.children && node.children.map((child) => renderTree(child))}
            </MotifTreeNode>
        );
    };
      
    const createMotifTree = (treeData) => {
        return <MotifTree showLines={true}>{treeData.map((rootNode) => renderTree(rootNode))}</MotifTree>;
    };

    const updateSelection = (node) => {
        if (node?.children && node.children.length > 0) {
            // If any child is unselected, unselect the parent
            if (node.children.some((child) => !child.isSelected)) {
                node.isSelected = false;
            }
            else {
                // If all children are selected, select the parent
                node.isSelected = true;
            }

            // Recursively update parent nodes
            if (node.parent) {
                updateSelection(node.parent);
            }
        }
    }

    const addRemoveNode = (node, action) => {
        if (action === "add" && !node.disabled) {
            setSelectedNodes(selectedNodes => [...selectedNodes, node]);
            node.isSelected = true;
        }
        else {
            setSelectedNodes(selectedNodes => {
                return selectedNodes.filter((selectedNode) => {
                    if (selectedNode.value != node.value) {
                        return true;
                    }
                });
            });
            node.isSelected = false;
        }
    }

    const traverseAndUpdateTree = (node, action) => {
        if (!node.children || node.children.length === 0) {
            // just add/remove this node
            addRemoveNode(node, action);
        }
        else {
            // has children so add/remove node and then traverse tree again
            addRemoveNode(node, action);

            if (node.children) {
                node.children.forEach(child => {
                    traverseAndUpdateTree(child, action);
                });
            }
        }
    }

    const treeNodeClick = (node) => {
        if (node.isSelected) {
            traverseAndUpdateTree(node, "remove");
        }
        else {
            traverseAndUpdateTree(node, "add");
        }

        //bubbleBackData(treeData);
        //updateSelection(node.parent);
    };

    return (
        <div className="tree-div">
            {treeData && createMotifTree(treeData)}
        </div>
    )
}