export const dashboardDataConstants = {
    privacyNotice: 
    {
        name: 'Privacy notice',
        url: 'https://paans-ui-uat.ey.com/viewnotice?a=94910419-6A5F-47F9-8E91-67607678B1C0&r=Global&l=EN&pt=1',
    },
    enagagementManagement: 
    {
        name: 'Engagement Management Guidance',
        url: 'https://discovercontent.ey.net/pod24022wgsjejjr'
    },
    eyAiPrinciples: 
    {
        name: 'EY’s commitment to ethical and responsible AI principles | EY – Global',
        url: 'https://www.ey.com/en_gl/insights/ai/principles-for-ethical-and-responsible-ai'
    },
    msAcceptableUsePolicy:
    {
        name:'Microsoft Acceptable Use Policy',
        url:'https://www.microsoft.com/licensing/terms/product/ForOnlineServices/all'
    },
    msCodeOfConductOpenAIServices:
    {
        name:'Microsoft Code of Conduct for OpenAI Services',
        url:'https://learn.microsoft.com/en-us/legal/cognitive-services/openai/code-of-conduct'
    },
    userGuide: 
    {
        name:'User Guide',
        url:'https://eygb.sharepoint.com/:p:/r/sites/DiligenceWorkbenchSP/Shared%20Documents/User%20guides/Digital%20Diligence%20Assistant/DE_Digital%20Diligence%20Assistant_Detailed%20user%20guide.pptx?d=w380e5306b95944ccaf50376d795cd106&csf=1&web=1&e=FhnqQ2'
    }
}