import { MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifTooltip } from "@ey-xd/motif-react";
import bookmarkfill from "../../../assets/image/bookmarkemptyblack.svg"
import editPencil from "../../../assets/image/edit-pencil.svg"
import iconRight from "../../../assets/image/arrow-right-grey.svg"
import collapse from "../../../assets/image/collapse-black.svg"
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import Utils from "../../../utils/Utils";

export default function PromptModal(props) {
  const utils = new Utils();

  return (
    <MotifModal
      id="viewPromptModal"
      show={props.isOpenPromptModal}
      className="PromptModal"
      size="xl"
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={() => props.setIsOpenPromptModal()}
    >
      <MotifModalHeader
        className="PromptModalHeader"
        closeButtonProps={{
          "aria-label": "Custom Close Button aria-label",
          title: "Custom Close Button title",
        }}
        closeModalButton={
          <MotifTooltip
            placement="bottom"
            hideCloseButton={true}
            allowHover={true}
            allowClick={false}
            contentClassName="tooltipWithoutClose"
            trigger={
              <MotifIconButton
                className="btn-collapse"
                onClick={() => props.setIsOpenPromptModal()}
              >
                <img src={collapse} alt="collapse" />
              </MotifIconButton>
            }
          >
            Collapse
          </MotifTooltip>
        }
      >
        <div className="PromptModalHeader-title">
          {!props?.isInstantInsightDisable
            ? props?.specificPromptData?.title
            : props?.specificPromptData?.promptTitle}
        </div>
      </MotifModalHeader>

      <MotifModalBody className="PromptModalBody">
        <div className="motif-body">
          <div className="PromptModalBody-Text" id="promptText" dangerouslySetInnerHTML={{
            __html: props?.specificPromptData?.promptText,
          }}></div>
        </div>
      </MotifModalBody>

      <MotifModalFooter className="PromptModalFooter motif-modal-footer">
        {props?.isInstantInsightDisable && (
          <MotifTooltip
            trigger={
              <img
                className="BookmarkFill"
                src={bookmarkfill}
                alt="bookmark-fill"
                onClick={() => {
                  props.setShowConfirmDeletePrompt(true);
                }}
              ></img>
            }
            contentClassName="tooltipWithoutClose"
            allowHover={true}
            allowClick={false}
            hideCloseButton={true}
            placement="bottom"
          >
            Remove
          </MotifTooltip>
        )}
        {props?.isInstantInsightDisable && (
          <MotifTooltip
            trigger={
              <img
                className="EditPencil"
                src={editPencil}
                alt="edit-pencil" onClick={() => {
                  props.setShowConfirmEditPrompt(true);
                }}
              ></img>
            }
            contentClassName="tooltipWithoutClose"
            allowHover={true}
            allowClick={false}
            hideCloseButton={true}
            placement="bottom"
          >
            Edit
          </MotifTooltip>
        )}
        <MotifTooltip
          trigger={
            <img
              className="IconRight right-icon-background"
              src={iconRight}
              alt="icon-right"
              onClick={() => {
                props?.handlePromptToQuestionBar(utils.markdownToHtml(props?.specificPromptData?.promptText));
                if(props?.specificPromptData?.scopeId){
                  props?.setEydScopeId(props?.specificPromptData?.scopeId)
                }
                props.setIsOpenPromptModal();
                props.promptExpandLibrary();
            }}
            ></img>
          }
          contentClassName="tooltipWithoutClose"
          allowHover={true}
          allowClick={false}
          hideCloseButton={true}
          placement="bottom"
        >
          Use Prompt
        </MotifTooltip>
      </MotifModalFooter>
    </MotifModal>
  );
}