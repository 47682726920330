import React, { useEffect, useState } from "react";
import Dashboard from "./components/dashboard/dashboard";
import LogOut from "./components/dashboard/LogOut";
import HandleRedirect from "./components/dashboard/HandleRedirect";
import { CreateProject } from "./components/CreateProject/CreateProject";
import DashboardContextProvider from "./context/dashboardContext";
import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LoginSuccess } from "./components/login/LoginSuccess"

import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";

import { DashboardPlatformAdmin } from "./components/AdminDashboard/dashboardPlatformAdmin";
import ConfigureSetting from "./components/dashboard/ConfigureSetting";
import AddDocument from "./components/CreateProject/AddDocuments/AddDocument";
import ProjectDashboard from "./components/ProjectDashboard/ProjectDashboard";
import Notification from "./components/AdminDashboard/Notification";

import { groupConfig } from "./config/groupConfig";
import Utils from "./utils/Utils";
import DeepLink from "./lib/deepLinks";
import ExploretData from "./components/ProjectDashboard/ExploreData/ExploreData";
import ProjectDetail from "./components/ProjectDashboard/ProjectDetail/ProjectDetail";
import InstantInsights from "./components/ProjectDashboard/InstantInsights/InstantInsights";
import DocumentLibrary from './components/ProjectDashboard/DocumentLibrary/DocumentLibrary';
import Members from './components/ProjectDashboard/Members/Members';
import PromptLibraries from './components/ProjectDashboard/PromptLibraries/PromptLibraries';
import EditProject from './components/ProjectDashboard/EditProject/EditProject';
import PersonalPromptLibrary from './components/ProjectDashboard/PromptLibraries/PersonalPromptLibrary';
import SatBestPracticePromptLibrary from './components/ProjectDashboard/PromptLibraries/SatBestPracticePromptLibrary';
import UserLogService from './services/userLogService';
import GraphService from './services/graphService';
import ViewProjectDetail from "./components/ProjectDashboard/ViewProjectDetails/ViewProjectDetail";
import ProjectWorkspace from "./components/ProjectDashboard/ProjectWorkspace/ProjectWorkspace";
import PersonalWorkspace from "./components/ProjectDashboard/ProjectWorkspace/PersonalWorkspace/PersonalWorkspace";
import TeamWorkspace from "./components/ProjectDashboard/ProjectWorkspace/TeamWorkspace/TeamWorkspace";
import DeepLinkManager from "./DeepLinkManager";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import PromptManagement from "./components/AdminDashboard/PromptManagement/PromptManagement";
import InstantInsightPrompts from "./components/AdminDashboard/PromptManagement/InstantInsightPrompts/InstantInsightPrompts";
import SystemPrompts from "./components/AdminDashboard/PromptManagement/SystemPrompts/SystemPrompts";
import { Provider } from "react-redux";
import store from "./store";
import SaTPromptLibrary from "./components/AdminDashboard/PromptManagement/SaTPromptLibrary/SaTPromptLibrary";
import Platform from "./components/AdminDashboard/PromptManagement/Platform";
import Powerpoint from "./components/AdminDashboard/PromptManagement/Powerpoint/Powerpoint";
import CentralPrompts from "./components/AdminDashboard/PromptManagement/Powerpoint/CentralPrompts/CentralPrompts";
import SystemPromptsPowerPoint from "./components/AdminDashboard/PromptManagement/Powerpoint/SystemPrompts/SystemPromptsPowerPoint";
/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:

 */
const MainContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   */
  const [caputeredUserGrp, setCaputeredUserGrp] = useState(false);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  let allgroups = [];
  const utils = new Utils();

  //Get User Groups for the autenticated user 
  const getUserGroups = async (url) => {
    const graphService = new GraphService();
    const accessToken = await graphService.getToken();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains'
      }
    };

    // Add this in header : ConsistencyLevel and value as Eventual
    //https://graph.microsoft.com/v1.0/users/userid/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=startswith(displayName, 'S-UG-report-Author-platform-admin-d')
    // Request to get the groups assigned to 
    const response = await fetch(`${url}`, requestOptions);

    if (response.ok) {
      const userGroupData = await response.json();
      allgroups = allgroups.concat(userGroupData.value);
      const nextLink = userGroupData['@odata.nextLink'];
      if (nextLink) {
        await getUserGroups(nextLink);
      }
      return allgroups;

    }
  }
  useEffect(() => {
    sessionStorage.setItem('CurrentUserName', activeAccount?.name);
    // Get all the Grouops and assigne the group to the user
    const fetchUserGroups = async () => {
      if (activeAccount) {
        const userGroupData = await getUserGroups(groupConfig.graphApiUrl);
        const graphService = new GraphService();
        // Filter group names for PlatForm Admin

        const platFormAdmin = userGroupData.filter(group => utils.isPlatformAdminGroup(group.displayName));
        if (platFormAdmin.length > 0) {
          localStorage.setItem('groupNamePlatform', groupConfig.groupNamePlatForm);
        }

        // Filter for SAT and Non-SAT
        const groupAssigned = userGroupData.filter(group => utils.isSatGroup(group.displayName)); //[0].displayName  
        // If user Belongs to SAT or Non Sat then user will be SAT
        if (groupAssigned.length > 0) {
          localStorage.setItem('groupName', groupConfig.groupNameSat);
        }
        else {
          localStorage.setItem('groupName', groupConfig.groupNameNonSat);
        }
        setCaputeredUserGrp(true);
        // Insert User log Details        
        const userLogService = new UserLogService();
        const userbymail = await graphService.getUserDetailsByInput(activeAccount?.username);
        let userLogDetail = []
        userLogDetail = { FirstName: userbymail.value[0].givenName, LastName: userbymail.value[0].surname, Email: userbymail.value[0].mail, FullName: userbymail.value[0].displayName };
        sessionStorage.setItem('userFirstName', userbymail.value[0].givenName);
        await userLogService.addUserLogDetail(userLogDetail);
      }
    }
    fetchUserGroups();

  }, [activeAccount]);


  return (
    <div>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <div>
            <DashboardContextProvider userEmail={activeAccount.username}>
              <Routes>
                <Route element={<DeepLinkManager />}>
                  {(localStorage.getItem('groupNamePlatform') === groupConfig.groupNamePlatForm) &&
                    <>
                      <Route path="/AdminDashboard" element={<><AdminDashboard context={activeAccount} /></>}>
                        <Route
                          index
                          element={<Navigate to="dashboardPlatformAdmin" replace />}
                        />
                        <Route path="dashboardPlatformAdmin" element={<DashboardPlatformAdmin context={activeAccount} />} />
                        <Route path="ConfigureSetting" element={<ConfigureSetting context={activeAccount} />} />
                        <Route path="PromptManagement" element={<><PromptManagement /><Outlet /></>}>
                          <Route path="Platform" element={<><Platform /><Outlet /></>}>
                            <Route path="SystemPrompts" element={<SystemPrompts />} />
                            <Route path="InstantInsightPrompts" element={<InstantInsightPrompts />} />
                            <Route path="SaTPromptLibrary" element={<SaTPromptLibrary />} />
                          </Route>
                          <Route path="Powerpoint" element={<><Powerpoint /><Outlet /></>}>
                            <Route path="SystemPrompts" element={<SystemPromptsPowerPoint />} />
                            <Route path="CentralPrompts" element={<CentralPrompts />} />
                          </Route>
                        </Route>
                        <Route path="Notification" element={<Notification context={activeAccount} />} />
                      </Route>
                      <Route path="/dashboardPlatformAdmin" element={<Navigate to="/AdminDashboard/dashboardPlatformAdmin" replace />} />
                      <Route path="/ConfigureSetting" element={<ConfigureSetting context={activeAccount} />} />
                    </>
                  }

                  <Route path="/CreateProject" element={<CreateProject />} />
                  <Route path="/AddDocument" element={<AddDocument />} />
                  <Route path="/Dashboard" element={<Dashboard context={activeAccount} />} />
                  {caputeredUserGrp && <Route path="/signin-oidc" element={<LoginSuccess />} />}


                  {/* <Route path="/ProjectDashboard" element={<ProjectDashboard />} /> */}
                  <Route path="/ProjectDashboard" element={<><ProjectDashboard /></>}>
                    <Route path="ExploreData" element={<ExploretData />} />
                    <Route path="DocumentLibrary" element={<DocumentLibrary />} />
                    <Route path="Members" element={<Members />} />
                    <Route path="Projectdetail" element={<ProjectDetail />} />
                    <Route path="PromptLibraries" element={<><PromptLibraries /><Outlet /></>} >
                      <Route path="Personal" element={<PersonalPromptLibrary />} />
                      <Route path="Sat" element={<SatBestPracticePromptLibrary />} />
                    </Route>
                    <Route path="InstantInsights" element={<InstantInsights />} />
                    <Route path="EditProject" element={<EditProject />} />
                    <Route path="ProjectWorkspace" element={<><ProjectWorkspace /><Outlet /></>}>
                      <Route path="Personal" element={<PersonalWorkspace />} />
                      <Route path="Team" element={<TeamWorkspace />} />
                    </Route>
                  </Route>
                  <Route path="/ExploreData" element={<ExploretData />} />
                  <Route path="/ViewProjectDetail" element={<ViewProjectDetail />} />
                </Route>
              </Routes>
            </DashboardContextProvider>
          </div>
        ) :
          null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<HandleRedirect />} />
          <Route path="/LogOut" element={<LogOut />} />
          <Route path="*" element={<HandleRedirect />} />
        </Routes>
      </UnauthenticatedTemplate>
    </div>
  );
};

const LogoutAfterInactivity = () => {
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const { instance } = useMsal();
  useEffect(() => {
    // Function to handle user activity
    const handleActivity = () => {
      setLastActivityTime(Date.now());
    };

    // Event listeners to track user activity
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);

    // Clear event listeners on unmount
    return () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
    };
  }, []);

  useEffect(() => {
    // Timer for checking inactivity
    const checkInactivityTimer = setTimeout(() => {
      const currentTime = Date.now();
      const inactiveTime = currentTime - lastActivityTime;
      const thirtyMinutesInMillis = 30 * 60 * 1000; // 30 minutes in milliseconds

      if (inactiveTime > thirtyMinutesInMillis) {
        logoutUser();
      }
      else {
        console.log('Active after :' + inactiveTime);
      }
    }, 30 * 61 * 1000); // Check  for inactivity

    // return () => clearTimeout(checkInactivityTimer);

    // Clear the timer on activity
    const clearTimer = () => clearTimeout(checkInactivityTimer);

    document.addEventListener('mousemove', clearTimer);
    document.addEventListener('keydown', clearTimer);

    // Clear the timer on component unmount
    return () => {
      document.removeEventListener('mousemove', clearTimer);
      document.removeEventListener('keydown', clearTimer);
      clearTimeout(checkInactivityTimer);
    };
  }, [lastActivityTime]);

  const logoutUser = () => {
    console.log('User logged out due to inactivity');
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: "/LogOut",
    };
    instance.logout(logoutRequest);

  };

};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 */
const App = ({ instance }) => {
  const requestedLocation = useLocation();

  useEffect(() => {
    const deepLink = DeepLink();

    // Check if the user is not authenticated
    const accounts = instance.getAllAccounts();

    if (accounts.length === 0 && requestedLocation.pathname !== '/' && requestedLocation.pathname !== '/signin-oidc' && requestedLocation.pathname !== '/LogOut') {
      deepLink.orchestrate(requestedLocation);
    }
  }, [requestedLocation, instance]);

  return (
    <MsalProvider instance={instance}>
      <Provider store={store}>
        <MainContent />
        <LogoutAfterInactivity />
      </Provider>
      {/* <PageLayout>
              <MainContent />
          </PageLayout> */}
    </MsalProvider>
  );
};


export default App;
