import { React, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MotifButton, MotifTooltip } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import _ from "lodash";
import trashPrompt from "../../../../assets/image/trashPrompt.svg";
import MotifToast from "@ey-xd/motif-react/Toast";
import arrowRight from "../../../../assets/image/arrow-right.svg";
import eyeImage from "../../../../assets/image/eye.svg";
import "./PromptAction.css"
import check_circle from "../../../../assets/image/check-circle.svg";
import PlatformService from "../../../../services/platformService";
import plus from "../../../../assets/image/plus_scope.svg";
import { DashboardContext } from "../../../../context/dashboardContext";
import { ProjectScopeStatus } from "../../../../config/groupConfig";
import ConfirmPublishPromptModal from "../InstantInsightPrompts/ConfirmPublishPromptModal";

const PromptAction = (props) => {
  const { scopeAll } = useContext(DashboardContext);
  const { selectedScopeId } = useSelector((state) => state.promptManagement);
  const { scopeList } = useSelector(state => state.scope);
  const [showModal, setShowModal] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [currentSelectedScope, setCurrentSelectedScope] = useState(null);
  const [showConfirmPublishTestPrompt, setShowConfirmPublishTestPrompt] = useState(false);
  const [hasAtLeastOneEditOrNew, setHasAtLeastOneEditOrNew] = useState(false);

  const platformService = new PlatformService();

  useEffect(() => {
    if (props.checkedPrompts) {
      const hasAtLeastOneEditOrNew = props.checkedPrompts.some((prompt) => {
        return (prompt.newEditFlag === "Edit" || prompt.newEditFlag === "New")
      })
      setHasAtLeastOneEditOrNew(hasAtLeastOneEditOrNew);
    }
  }, [props.checkedPrompts])

  useEffect(() => {
    const currentSelectedScope = scopeList.filter((scope) => {
      return scope.scopeId === selectedScopeId;
    })
    setCurrentSelectedScope(currentSelectedScope[0]);
  }, [scopeAll, selectedScopeId, props.node])

  const handlePublishClick = () => {
    setShowModal(true);
  };

  const handleConfirmPublish = async () => {

    const SPLId = props.checkedPrompts.map((item) => item.platformPromptId).join(",");


    const scopeIds = [...new Set(props.checkedPrompts.map((item) => item.scopeId))][0];

    const requestPayload = {
      ScopeId: scopeIds,
      SPLId: SPLId,
    };

    let response = await platformService.ConfirmPublish(requestPayload)

    if (response == 200) {
      props.getSystemPrompts();
      setShowToast(true);

      setToastType("success");
      setToastMessage("Prompt(s) published successfully");

    }
    setShowModal(false);
  };

  const handleCancelPublish = () => {
    setShowModal(false);
  };

  const onPublishPromptSuccess = async () => {
    if(props.tab === "SaTPromptLibrary" ){
      let request1 = {
        platformPromptTestIds: _.map(props?.checkedPrompts, t=>t.saTPromptId)
      };
      let isSuccess = await platformService.publishSatTestPrompt(request1);
      if(isSuccess) {
        setShowConfirmPublishTestPrompt(false);
        props?.onPublishSatPromptSuccess();
      } 
    }
    else{
    let request = {
      platformPromptTestIds: _.map(props?.checkedPrompts, t=>t.platformPromptId)
    };
    let isSuccess = await platformService.publishInstantInsightTestPrompt(request);
    if(isSuccess) {
      setShowConfirmPublishTestPrompt(false);
      props?.onSuccessPublishPrompt();
    } 
  }
}
  return (
    <>
    <div className="ii-prompt-action-section">
      {props.tab === "InstantInsightPrompts" && props.innerTab === "active" && (
        <>
          <MotifButton
            label="btnCreate"
            className="li-move-prompt"
            disabled={props.disableActionButtons}
            onClick={props.onMovePrompt}
          >
            <img src={arrowRight} alt="Move Prompt"></img>
            Move Prompt
          </MotifButton>
        </>
      )}
       {props.tab === "InstantInsightPrompts" && props.innerTab === "test" && (
        <div className="ii-prompt-action-section">
          <MotifButton
            label="btnCreate"
            className="li-delete-button"
            onClick={props.onDeletePrompt}
            disabled={props.disableDeleteforIITest}
          >
            <img src={trashPrompt} alt="Delete Prompt"></img>
            <span>Delete</span>
          </MotifButton>
         
        </div>
      )}
      {props.tab === "SaTPromptLibrary" && props.innerTab === "test" && (
        <div className="ii-prompt-action-section">
          <MotifButton
            label="btnCreate"
            className="li-delete-button"
            onClick={props.onDeletePrompt}
            disabled={props.disableDeleteforIITest}
          >
            <img src={trashPrompt} alt="Delete Prompt"></img>
            <span>Delete</span>
          </MotifButton>
         
        </div>
      )}
      {(props.tab === "InstantInsightPrompts" || props.tab === "SaTPromptLibrary") && props.innerTab === "test" && (
          <MotifButton
            label="btnCreate"
            className="li-delete-button"
            onClick={props.addTestPrompt}
          >
            <img src={plus} alt="plus" />Add New Prompt
          </MotifButton>
      )}

      {props.tab === "InstantInsightPrompts" && props.innerTab === "test" && (
          <MotifTooltip
            placement="bottom"
            hide={hasAtLeastOneEditOrNew}
            trigger={
              <MotifButton
                label="btnPublishIIPrompt"
                className="li-move-prompt"
                disabled={props?.checkedPrompts?.length === 0 || !hasAtLeastOneEditOrNew}
                onClick={() => { setShowConfirmPublishTestPrompt(true) }}
              >
                <img src={eyeImage} alt="Publish Prompt"></img>
                Publish
              </MotifButton>
            }
          >
            No changes to publish
          </MotifTooltip>
      )}
      {props.tab === "SaTPromptLibrary" && props.innerTab === "test" && (
          <MotifTooltip
            placement="bottom"
            hide={hasAtLeastOneEditOrNew}
            trigger={
              <MotifButton
                label="btnPublishIIPrompt"
                className="li-move-prompt"
                disabled={props?.checkedPrompts?.length===0 || !hasAtLeastOneEditOrNew}
                onClick={()=>{setShowConfirmPublishTestPrompt(true)}}
              >
                <img src={eyeImage} alt="Publish Prompt"></img>
                Publish
              </MotifButton>
            }
            >
               No changes to publish
           </MotifTooltip>
      )}

      {props.tab === "SystemPrompts" && props.innerTab === "test" && props.node?.api === "Explore your data" && currentSelectedScope?.spEydStatus === ProjectScopeStatus.publish && (
          <MotifButton
            label="btnPublish"
            className="li-move-prompt"
            disabled={props.disableActionButtonsforEyd}
            onClick={handlePublishClick}
          >
            <img src={eyeImage} alt="Publish Prompt"></img>
            Publish
          </MotifButton>
      )}

      {props.tab === "SystemPrompts" && props.innerTab === "test" && props.node?.api === "Instant Insight" && currentSelectedScope?.spiiStatus === ProjectScopeStatus.publish && (
          <MotifButton
            label="btnPublish"
            className="li-move-prompt"
            disabled={props.disableActionButtonsII}
            onClick={handlePublishClick}
          >
            <img src={eyeImage} alt="Publish Prompt"></img>
            Publish
          </MotifButton>
        
      )}
      </div>

      {props.tab === "SystemPrompts" && props.innerTab === "test" && showModal && (
        <MotifModal
          id="motifModalPublishPrompt"

          size="lg"
          show={true}
          focusTrapOptions={{
            tabbableOptions: {
              displayCheck: "none",
            },
          }}
          onClose={handleCancelPublish}
          className="publish-genericModal modalNoHeaderBorder modalMotifFooterModal"

        >
          <div className="ii-acknowledgement-header-div">
            <MotifModalHeader
              className="motif-modal-header"
              closeButtonProps={{
                "aria-label": "Custom Close Button aria-label",
                title: "Close popup",
              }}
            >

              <div className="motif-modal-header-text">
                Publish to Active Prompts
              </div>

            </MotifModalHeader>

          </div>

          <MotifModalBody className="motif-modal-body">
            < >
              <span>
                Publish selected prompts to Active Prompts tab. Click on confirm to apply.
              </span>
              {props.checkedPrompts.length > 0 && (
                <ul className="modalContent"  >
                  {props.checkedPrompts.map((prompt, index) => (
                    <li key={index} >{prompt.platformPromptTitle
                    }</li>
                  ))}
                </ul>
              )}
            </>
          </MotifModalBody>

          <MotifModalFooter className="motif-modal-footer">
            <div className="btns-div">
              <div>
                <MotifButton
                  id="btnCancel"
                  variant="primary-alt"
                  onClick={handleCancelPublish}
                >
                  Cancel
                </MotifButton>
              </div>
              <div>
                <MotifButton
                  id="btnAcknowledge"
                  onClick={handleConfirmPublish}
                >
                  Confirm
                </MotifButton>
              </div>
            </div>
          </MotifModalFooter>
        </MotifModal>
      )}


      {showToast && (
        <MotifToast
          icons={{ toast: <img src={check_circle} alt=""></img> }}

          variant="success"
          className={"publish-motif-toast"}
          position="bottom"
          onClose={() => setShowToast(false)}
        >
          <div className="toast-body">{toastMessage}</div>
        </MotifToast>
      )}

      {
        showConfirmPublishTestPrompt && (
          <ConfirmPublishPromptModal
          closeConfirmMovePrompt={() => {
            setShowConfirmPublishTestPrompt(false);
          }}
          publishPromptList={props?.checkedPrompts}
          onPromptPublishComplete={onPublishPromptSuccess}
          tab = {props.tab}
          />
        )
      }

    </>
  );
};

export default PromptAction;
