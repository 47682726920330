import React, { useEffect, useState, useContext } from "react";
import "./ProjectDetail.css";
import "../ProjectDashboard.css";
import { MotifButton } from "@ey-xd/motif-react";
import { DashboardContext } from "../../../context/dashboardContext";
import { groupConfig } from "../../../config/groupConfig.js";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../common/BreadCrumb";
import { useLocation, useOutletContext } from "react-router-dom";

export function ProjectDetail() {
    const [category, setCategory] = useState("");
    const [paceID, setPaceID] = useState("");
    const [engagementId, setEngagementId] = useState("");
    const [clientName, setClientName] = useState("");
    const [confidentialProject, setConfidentialProject] = useState(false);
    const [area, setArea] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("");
    const [dealSize, setDealSize] = useState("");
    const [targetSector, setTargetSector] = useState("");
    const [targetSubSector, setTargetSubSector] = useState("");
    const [targetSubSubSector, setTargetSubSubSector] = useState("");
    const [dataHosting, setDataHosting] = useState("");
    const [description, setDescription] = useState("");
    const projectId = sessionStorage.getItem('DashboardProjectId');
    const projectName = sessionStorage.getItem('DashboardProjectName');
    const projectMasterData = require("../../../data/projectMasterData");
    let categoryList = useState([]);
    let areaList = useState([]);
    let regionList = useState([]);
    let countryList = useState([]);
    let dealSizeList = useState([]);
    let targetSectorList = useState([]);
    let targetSubSectorList = useState([]);
    let targetSubSubSectorList = useState([]);
    let dataHostingList = useState([]);
    const { GetProjectDetailById } = useContext(DashboardContext);
    const [isSATUser, setSATUser] = useState(false);
    const navigate = useNavigate();
    const [projectScopeName, setProjectScopeName] = useState([]);
    let projectScopeList = [];
    let scopeNameList = [];
    let { scopeAll } = useContext(DashboardContext);
    const location = useLocation();
    const outletContext = useOutletContext();
    const projectRole = localStorage.getItem("userRole");

    useEffect(() => {
        setSATUser(localStorage.getItem("groupName") === groupConfig.groupNameSat);
        categoryList = projectMasterData.CategoryConfig;
        areaList = projectMasterData.AreaConfig;
        regionList = projectMasterData.RegionConfig;
        countryList = projectMasterData.CountryConfig;
        dealSizeList = projectMasterData.DealConfig;
        dataHostingList = projectMasterData.DataHostingListConfig;
        targetSectorList = projectMasterData.SectorConfig;
        targetSubSectorList = projectMasterData.SubSectorConfig;
        targetSubSubSectorList = projectMasterData.SubSubSectorConfig;
        setDetailData();
        if (location.state?.showProjectDetail) {
            outletContext.highlightProjectDataNavigationOption();
          }
    }, []);
    /**
   * Description
   * -----------
   * This method is to set existing project details from database and setting up on variables
   */
    const setDetailData = async () => {
        if (projectId !== "") {
            let data = await GetProjectDetailById(projectId, localStorage.getItem('currentDataHostingLocation'));
            if (data) {
                setPaceID(data[0].paceId === 0 ? "" : data[0].paceId);
                if (data[0].category === "2" || data[0].category === "3") {
                    setEngagementId(data[0].engagementId === 0 ? "" : data[0].engagementId);
                } else {
                    setEngagementId(data[0].engagementId);
                }
                setDescription(data[0].description);
                setCategory(categoryList.filter((item) => item.id == data[0].category)[0].content);
                setArea(areaList.filter((item) => item.id == data[0].area)[0].content);
                setRegion(regionList.filter((item) => item.id == data[0].region)[0].content);
                setCountry(countryList.filter((item) => item.id == data[0].country)[0].content);
                setDealSize(dealSizeList.filter((item) => item.id == data[0].dealSize)[0].content);
                setTargetSector(targetSectorList.filter((item) => item.id == data[0].targetSector)[0].content);
                setTargetSubSector(targetSubSectorList.filter((item) => item.id == data[0].target_SubSector
                )[0].content);
                setTargetSubSubSector(targetSubSubSectorList.filter((item) => item.id == data[0].target_Sub_SubSector
                )[0].content);
                setDataHosting(dataHostingList.filter((item) => item.id == data[0].dataHostingLocation)[0].content);
                setClientName(data[0].clientName);
                if (data[0].confidential == projectMasterData.ConfidentialCheck) {
                    setConfidentialProject("Yes");
                }
                else {
                    setConfidentialProject("No");
                }
                if (data[0].confidential === "") {
                    setConfidentialProject("");
                }

                await getScopeList();
                let projectScopeSelected = JSON.parse(data[0].scopeId);
                if (projectScopeSelected !== null) {
                    if (projectScopeSelected.length > 0) {
                        projectScopeSelected.forEach((key) => {
                            scopeNameList.push(projectScopeList.filter((item) => item.scopeId === key)[0].scopeName);
                        });
                        setProjectScopeName(scopeNameList.toString());
                    }
                }
            }
        }
    }
    /**
     * Description
     * -----------
     * This method is to get existing project detail filtering the projectid
     */
    const getScopeList = async () => {       
        if (scopeAll) {
            projectScopeList=scopeAll;
        }
    }
    /**
  * Description
  * -----------
  * This method is to navigate to edit page once user selects edit button 
  */
    const onEditButtonClick = () => {
        navigate("/ProjectDashboard/EditProject");
    }
    return (
        <>
            <div className="ProjectDashboardPageContainer">
            <div className="HeaderLayout">
          <BreadCrumb projectName={projectName} pageName="Project Details" />
          <div className="HeaderContainer">
            <div className="HeaderTitle">
              <span className="HeaderText">{projectName}</span>
            </div>
            <div className="HeaderAction">
              {projectRole && (projectRole !== "Viewer" && projectRole !== "User (Non-SaT)" && projectRole !== "User (SaT)") && (
                <MotifButton label="btnedit" onClick={onEditButtonClick}>
                  Edit
                </MotifButton>
              )}
            </div>
          </div>
        </div>
                <div className="DashProjFrame1">
                    <div className="DashProjTitle1" >
                        <div className="DashProjTextAndSupportingText" >
                            <div className="DashProjText2" >
                                <div className="DashProjText5">Project Details</div>
                            </div>
                            <div className="ProjectDetailLine" ></div>
                        </div>
                    </div>
                    <div className="DashProjFrame" >
                        <div className="DashProjSelect">
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Project type</div>
                                    <div className="DashProjLabel1" label="lblcategory">{category}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase">
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel">PACE ID</div>
                                    <div className="DashProjLabel1" label="lblpaceid">{paceID}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase">
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Engagement ID</div>
                                    <div className="DashProjLabel1" label="lblengagementid">{engagementId}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DashProjFrame" >
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel">
                                    <div className="DashProjLabel">Project name</div>
                                    <div className="DashProjLabel1" label="lblprojectname" >{projectName}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel">
                                    <div className="DashProjLabel"> Confidential project</div>
                                    <div className="DashProjLabel1" label="lblconfidentialproject" >{confidentialProject}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjFrame5383" >
                                <div className="DashProjLabel">Client name</div>
                            </div>
                            <div className="DashProjInputDropdownBase1" >
                                <div className="DashProjInputWithLabel1" >
                                    <div className="DashProjLabel1" label="lblclientname">{clientName}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DashProjFrame" >
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Area</div>
                                    <div className="DashProjLabel1" label="lblarea">{area}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Region</div>
                                    <div className="DashProjLabel1" label="lblregion">{region}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Country</div>
                                    <div className="DashProjLabel1" label="lblcountry">{country}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DashProjFrame" >
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Deal size (USD)</div>
                                    <div className="DashProjLabel1" label="lbldealsize">{dealSize}</div>
                                </div>
                            </div>
                        </div>

                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Target sector</div>
                                    <div className="DashProjLabel1" label="lbltargetsector">{targetSector}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Target sub-sector</div>
                                    <div className="DashProjLabel1" label="lbltargetsubsector">{targetSubSector}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DashProjFrame" >
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Target sub-sub-sector</div>
                                    <div className="DashProjLabel1" label="lbltargetsubsubsector">{targetSubSubSector}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Data hosting</div>
                                    <div className="DashProjLabel1" label="lbldatahosting">{dataHosting}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DashProjSelect" >
                            <div className="DashProjInputDropdownBase" >
                                <div className="DashProjInputWithLabel" >
                                    <div className="DashProjLabel" >Project Scope (Optional)</div>
                                    <div className="DashProjLabel1" label="lblprojectscope">{projectScopeName}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="DashProjFrame" >
                        <div className="DashProjTextArea" >
                            <div className="DashProjTextarea1" >
                                <div className="DashProjLabelGroup" >
                                    <div className="DashProjLabelOutside" label="lbldescription">Description</div>
                                </div>
                                <div className='ProjectDetailWrap'>
                                    {description}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default ProjectDetail;
