import { createSlice } from "@reduxjs/toolkit";
 
const activeSatScopeSlice = createSlice({
  name: "satScopeId",
  initialState: {
    satScopeId: 0
  },
  reducers: {
    setsatScopeId: (state, action) => {
      state.satScopeId = action.payload.satScopeId;
    }
  },
});
 
export const { setsatScopeId } =
activeSatScopeSlice.actions;
 
export default activeSatScopeSlice.reducer;