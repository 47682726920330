/**
 * Admin Dashboard
 * Modified on : 01-01-2025
 */
//  Generic imports
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

//  Motif controls
import {
  MotifVerticalNavigationMenuItem,
  MotifVerticalNavigationMenu,
  MotifVerticalNavigation,
  MotifVerticalNavigationContent,
  MotifDropdownPortal,
  MotifAvatar,
  MotifDropdownItem,
} from "@ey-xd/motif-react";
 import { MotifIconButton } from '@ey-xd/motif-react/components/button';

//  Icons imports
import adminDashboard from "../../assets/image/admin-dashboard.svg";
import menu from "../../assets/image/menu.svg";
import avatarImage from "../../assets/image/default-user-icon.jpg";
import logOut from "../../assets/image/log-out.svg";
import logo from "../../assets/image/logo.svg";
import configureSetting from "../../assets/image/configure-setting.svg";
import notification from "../../assets/image/notification.svg";
import promptManagement from "../../assets/image/repository.svg";

//  Internal imports
import "./AdminDashboard.css";
import userGuide from "../../assets/image/user-guide.svg"
import Footer from "../common/Footer.js";
import { dashboardDataConstants } from '../dashboard/constants';

/**
   * Description
   * -----------
   * This method will be helpful for using the react hooks in class component
   */
function withMyHook(Component) {
    return function WrappedComponent(props) {
        const myInstance = useMsal();
        const myLocation = useLocation();
        const { isStructureComplete } = useSelector((state) => state.promptManagement);
        return <Component {...props} myInstance={myInstance} myLocation={myLocation} isStructureComplete={isStructureComplete} />;
    }
}

class AdminDashboard extends React.Component {
    constructor() {
        super();

        this.state = {
            collapseMenu: true,
            showUserMenu: false,
            adminDashboardClick: true,
            configSettingsClick: false,
            promptManagement: false,
            notificationClick: false
        }
        this.highlightAdminDashboardNavigationOption = this.highlightAdminDashboardNavigationOption.bind(this);
    }

    //  Set Explore Your Data menu option as selected
    highlightAdminDashboardNavigationOption() {
        if (this) {
            this.setState({ adminDashboardClick: true });
            this.setState({ configSettingsClick: false });
            this.setState({ promptManagement: false });
            this.setState({ notificationClick: false });
        }
    };

    //  Set Explore Your Data menu option as selected
    highlightConfigSettingsNavigationOption() {
        if (this) {
            this.setState({ adminDashboardClick: false });
            this.setState({ configSettingsClick: true });
            this.setState({ promptManagement: false });
            this.setState({ notificationClick: false });
        }
    };

    //  Set Explore Your Data menu option as selected
    highlightPromptManagementNavigationOption() {
        if (this) {
            this.setState({ adminDashboardClick: false });
            this.setState({ configSettingsClick: false });
            this.setState({ promptManagement: true });
            this.setState({ notificationClick: false });
        }
    };

    //  Set Explore Your Data menu option as selected
    highlightNotificationNavigationOption() {
        if (this) {
            this.setState({ adminDashboardClick: false });
            this.setState({ configSettingsClick: false });
            this.setState({ promptManagement: false });
            this.setState({ notificationClick: true });
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const url = window.location.pathname;        

        // Set highlight as per url
        this.setMenuHighlightAsPerURL(url);
    }
    /**
     * Set menu option highlight as per url.
     * @param {string} url 
     */


    setMenuHighlightAsPerURL = (url) => {
        // eslint-disable-next-line default-case
        switch (url) {
            case "/adminDashboard/dashboardPlatformAdmin":
                this.highlightAdminDashboardNavigationOption();
                break;
        }
    }

    onToggleButtonClick = () => {
        if (this.state.collapseMenu === true) {
            this.setState({ collapseMenu: false });
        }
        else {
            this.setState({ collapseMenu: true });
        }
    };
    showLoggedInUserMenu = () => {
        this.setState({ showUserMenu: true });
  };

      /**
   * Description
   * -----------
   * This method logout from Azure Ad and navigate to logout page
   */
      handleLogOut = (e) => {
        localStorage.clear();
        sessionStorage.clear();
        const myInstance = this.props.myInstance;

        const logoutRequest = {
            account: myInstance.instance.getActiveAccount(),
            postLogoutRedirectUri: "/LogOut",
        };
        myInstance.instance.logout(logoutRequest);
    };
    
    hideLoggedInUserMenu = () => {
        this.setState({ showUserMenu: false });
    };
    OnLogoClick = () => {
        this.setState({ navigateToDashboard: true });
    };
    
/*
   * Description
   * -----------
   * This method is to navigate to user guide page.
   */
  navigateToUserGuide = () => {
    window.open(dashboardDataConstants.userGuide.url, "_blank");
  };
  
  render() {
    return (
      <div>
        <div className="adminDashboardDetails">
          <div className="adminDashboardHeaderNav">
            <div className="adminDashboardLogoAndMenu">
              <div className="adminDashboardLogoButton">
                <div className="adminDashboardLogoButtonBase">
                  <img
                    alt="icon"
                    onClick={() => this.onToggleButtonClick()}
                    src={menu}
                    header
                  ></img>
                </div>
              </div>
              <div className="ProjectDashboardLogoEngGap">
                <div className="ProjectDashboardLogoProduct">
                  <div className="ProjectDashboardLogo">
                    <div className="ProjectDashboardContainerLogo">
                      <img
                        alt="icon"
                        className="img-hover"
                        src={logo}
                        onClick={() => this.OnLogoClick()}
                      ></img>
                    </div>
                  </div>
                  <div className="ProductName">Digital Diligence Assistant</div>
                </div>
              </div>
            </div>
            <div className="profileImageContainer">
              <MotifDropdownPortal
                aria-labelledby="dropdown-trigger-3"
                id="AdminDashboardProfileDropdown"
                open={this.state.showUserMenu}
                handleClickOutside={() => this.hideLoggedInUserMenu()}
                className="dropdownContainer"
                placement="bottom"
                hideArrow={true}
                trigger={
                  <MotifIconButton
                    aria-label="Profile"
                    id="divUserAvatar"
                    className="Avatar"
                    onClick={() => this.showLoggedInUserMenu()}
                  >
                    <MotifAvatar
                      description="Profile Icon"
                      src={avatarImage}
                      className="userAvatar"
                      //size="medium"
                    />
                  </MotifIconButton>
                }
              >
                <MotifDropdownItem
                  onClick={function noRefCheck() {}}
                  className="adminDashuserAvatarUserMenuItem"
                >
                  <div className="displaymenu">
                    <div className="displaymenuDiv">
                      {
                        <img
                          src={avatarImage}
                          alt="Avatar"
                          className="AvatarDiv"
                        ></img>
                      }
                      {
                        <div className="CurrentUserNameDiv">
                          {" "}
                          <b>{sessionStorage.getItem("CurrentUserName")}</b>
                          <div className="EmailDiv">
                            {" "}
                            {sessionStorage.getItem("Email")}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </MotifDropdownItem>
                <div className="Divider1 divider" />
                <MotifDropdownItem
                  onClick={() => this.navigateToUserGuide()}
                  className="adminDashuserguidecontainer"
                >
                  <img className="userguideimg" alt="icon" src={userGuide} />
                  <span className="userguide">User Guide</span>
                </MotifDropdownItem>
                <div className="Divider1 divider" />
                <MotifDropdownItem
                  onClick={(e) => this.handleLogOut(e)}
                  className="adminDashlogoutcontainer"
                >
                  <img className="logoutimg" alt="icon" src={logOut} />
                  <span className="logout">Log out</span>
                </MotifDropdownItem>
                <div className="Divider1 divider" />
              </MotifDropdownPortal>
            </div>
          </div>
          <div id="adminDashNav" className="adminDashboardNavDiv">
            <MotifVerticalNavigation collapse={this.state.collapseMenu}>
              <MotifVerticalNavigationContent>
                <MotifVerticalNavigationMenu role="menu">
                  <Link
                    to="/adminDashboard/dashboardPlatformAdmin"
                    role="menuitem"
                    onClick={() => {
                      this.highlightAdminDashboardNavigationOption();
                    }}
                  >
                    <div role="menu">
                      <MotifVerticalNavigationMenuItem
                        className={
                          this.state.adminDashboardClick &&
                          this.props.isStructureComplete
                            ? "adminDashboardActiveMenu"
                            : ""
                        }
                        icon={<img alt="icon" src={adminDashboard} />}
                        label="Admin Dashboard"
                        type="submit"
                      >
                        Admin Dashboard
                      </MotifVerticalNavigationMenuItem>
                    </div>
                  </Link>
                  <Link
                    to="/adminDashboard/ConfigureSetting"
                    role="menuitem"
                    onClick={() => {
                      this.highlightConfigSettingsNavigationOption();
                    }}
                  >
                    <div role="menu">
                      <MotifVerticalNavigationMenuItem
                        className={
                          this.state.configSettingsClick &&
                          this.props.isStructureComplete
                            ? "adminDashboardActiveMenu"
                            : ""
                        }
                        icon={<img alt="icon" src={configureSetting} />}
                        label="Configure Settings"
                        type="submit"
                      >
                        Configure Settings
                      </MotifVerticalNavigationMenuItem>
                    </div>
                  </Link>
                  <Link
                    to="/AdminDashboard/PromptManagement/Platform/SystemPrompts"
                    role="menuitem"
                    onClick={() => {
                      this.highlightPromptManagementNavigationOption();
                    }}
                  >
                    <div role="menu">
                      <MotifVerticalNavigationMenuItem
                        className={
                          this.state.promptManagement ||
                          !this.props.isStructureComplete
                            ? "adminDashboardActiveMenu"
                            : ""
                        }
                        icon={<img alt="icon" src={promptManagement} />}
                        label="Prompt Management"
                        type="submit"
                      >
                        Prompt Management
                      </MotifVerticalNavigationMenuItem>
                    </div>
                  </Link>
                  <Link
                    to="/adminDashboard/Notification"
                    role="menuitem"
                    onClick={() => {
                      this.highlightNotificationNavigationOption();
                    }}
                  >
                    <div role="menu">
                      <MotifVerticalNavigationMenuItem
                        className={
                          this.state.notificationClick &&
                          this.props.isStructureComplete
                            ? "adminDashboardActiveMenu"
                            : ""
                        }
                        icon={<img alt="icon" src={notification} />}
                        label="Notifications"
                        type="submit"
                      >
                        Notifications
                      </MotifVerticalNavigationMenuItem>
                    </div>
                  </Link>
                </MotifVerticalNavigationMenu>
              </MotifVerticalNavigationContent>
            </MotifVerticalNavigation>
            <div className="utilmargin">
              <Outlet></Outlet>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
    }
}
export default withMyHook(AdminDashboard);