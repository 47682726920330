import React , {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MotifTabNavigation, { MotifTabControl }  from "@ey-xd/motif-react/TabNavigation";
import BreadCrumb from "../../common/BreadCrumb";

const ProjectWorkspace = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // Check if location.state exists and has tabId defined
  const tabId = location.state && location.state.tabId;
  const [selecttTab, setSelectTab] = useState(false)
  
  const projectName = sessionStorage.getItem("DashboardProjectName");
  const workSpaceTabIds = {
    Personal: "Personal",
    Team: "Team",
  };

  /**
   * This method is to switch tha tab from personal to team vice a versa.
   * @param {string} tab 
   */
  const handleWorkSpaceTabClick = (tab) => {
    switch (tab) {
      case workSpaceTabIds.Personal:
        setSelectTab('Personal')
        navigate("Personal");
        break;
      case workSpaceTabIds.Team:
        setSelectTab('Team')
        navigate("Team");
        break;
    }
  };


  useEffect(() => {
    handleTabHighlight(); 
  }, [location]);

  /**
   * Handles highlighting of tabs based on the provided tabId.
   * This function retrieves DOM elements for 'TeamTab' and 'PersonalTab',
   * and adds/removes the 'motif-active' class accordingly.
   */
  const handleTabHighlight = () => {
    const TeamTab = document.getElementById('TeamTab');
    const PersonalTab = document.getElementById('PersonalTab');
    if (tabId === 'Team') 
      {
      if (TeamTab) {
        TeamTab.classList.add('motif-active');
      }
      if (PersonalTab) {
        PersonalTab.classList.remove('motif-active');
      }
    }
    else
    {
      if(selecttTab === 'Personal')
      {
        PersonalTab.classList.add('motif-active');
        TeamTab.classList.remove('motif-active');
      }
      else if(selecttTab === 'Team')
      {
          PersonalTab.classList.remove('motif-active');
          TeamTab.classList.add('motif-active');
      }
      
    }
  };


  return (
    <>
      <div className="ProjectDashboardPageContainer">
        <div className="HeaderLayout">
          <BreadCrumb projectName={projectName} pageName="Project Workspace" />
          <div className="HeaderContainer">
            <div className="HeaderTitle">
              <span className="HeaderText">Project Workspace</span>
            </div>
            <div className="HeaderAction"></div>
          </div>
        </div>

        <MotifTabNavigation defaultActiveKey={0} id="projectWorkspaceTab">
          <MotifTabControl  id ="PersonalTab"
            onClick={() => handleWorkSpaceTabClick(workSpaceTabIds.Personal)}
          >
            Personal Workspace
          </MotifTabControl>
          {/* <MotifTabControl  id ="TeamTab"
            onClick={() => handleWorkSpaceTabClick(workSpaceTabIds.Team)}
          >
            Team Workspace
          </MotifTabControl> */}
        </MotifTabNavigation>
      </div>
    </>
  );
};
export default ProjectWorkspace;
