import showdown from 'showdown';
import * as Crypto from "crypto-js";
import DocumentService from "../../src/services/documentService";
import _ from "lodash";
import moment from 'moment';
import { groupConfig, ReportAuthorConfig } from '../config/groupConfig';
import DOMPurify from "dompurify";

class Utils {

  convertDateToUTCTimeStamp = function (date, isAlreadyInUTC) {
    if (isAlreadyInUTC && date !== undefined && date !== null) {
      return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ").valueOf();
    }

    if (date) {
      return moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").valueOf();
    }

    return moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").valueOf();
  };

  convertDateToUTCTimeStampWithDayName = function (date, showDayName) {
    if (date !== undefined && date !== null) {
      if (showDayName) {
        return moment(date).utc().format("dddd, MM/DD/YYYY, hh:mm A").valueOf()+ " (UTC)";
      }
      return moment(date).utc().format("MM/DD/YYYY, hh:mm A").valueOf()+ " (UTC)";
    } else {
      return moment().utc().format("MM/DD/YYYY, hh:mm A").valueOf()+ " (UTC)";
    }
  }

  formatDate(date) {
    const dbDate = new Date(date);

    const localOffset = dbDate.getTimezoneOffset() * 60 * 1000; // Convert minutes to milliseconds
    const fDate = new Date(dbDate.getTime() - localOffset);//.toLocaleString('en-US', { usertimezone });

    let hours = fDate.getHours();
    let minutes = fDate.getMinutes();
    let newformat = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${("00" + (fDate.getMonth() + 1)).slice(-2)}/${(
      "00" + fDate.getDate()
    ).slice(-2)}/${fDate.getFullYear()} ${("00" + hours).slice(-2)}:${(
      "00" + minutes
    ).slice(-2)} ${newformat}`;
  }

  formatDateReq(date) {
    const fDate = new Date(date);

    let hours = fDate.getHours();
    let minutes = fDate.getMinutes();
    let newformat = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${("00" + (fDate.getMonth() + 1)).slice(-2)}/${(
      "00" + fDate.getDate()
    ).slice(-2)}/${fDate.getFullYear()} ${("00" + hours).slice(-2)}:${(
      "00" + minutes
    ).slice(-2)} ${newformat}`;
  }

  formatDateCustom(date) {
    if (date !== "") {
      const d = new Date(date);
      const currentdate = new Date();
      const yesterdayDate = new Date();
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      if (
        currentdate.getFullYear() === d.getFullYear() &&
        currentdate.getMonth() === d.getMonth() &&
        currentdate.getDate() === d.getDate()
      ) {
        return `Today, ${d.toLocaleTimeString("en-us", {
          timeStyle: "short",
        })}`;
      } else if (
        yesterdayDate.getFullYear() === d.getFullYear() &&
        yesterdayDate.getMonth() === d.getMonth() &&
        yesterdayDate.getDate() === d.getDate()
      ) {
        return `Yesterday, ${d.toLocaleTimeString("en-us", {
          timeStyle: "short",
        })}`;
      } else {
        const date = d.getDate();
        const year = d.getFullYear();
        const Month = d.toLocaleString("en-us", { month: "short" });
        return Month + " " + date + ", " + year;
      }
    } else {
      return "";
    }
  }

  /**
   * Format date time to local time. 
   * Date as Today/Yesterday/<mm/dd/yyyy> format.
   * Time as Hours:Minutes AM/PM
   * @param {*} date Date to format 
   * @returns Formatted date to local time 
   */
  formatDateTimeCustom(date) {
    if (date !== "") {
      const d = new Date(date);
      const currentdate = new Date();
      const yesterdayDate = new Date();
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      if (
        currentdate.getFullYear() === d.getFullYear() &&
        currentdate.getMonth() === d.getMonth() &&
        currentdate.getDate() === d.getDate()
      ) {
        return `Today, ${d.toLocaleTimeString("en-us", {
          timeStyle: "short",
        })}`;
      } else if (
        yesterdayDate.getFullYear() === d.getFullYear() &&
        yesterdayDate.getMonth() === d.getMonth() &&
        yesterdayDate.getDate() === d.getDate()
      ) {
        return `Yesterday, ${d.toLocaleTimeString("en-us", {
          timeStyle: "short",
        })}`;
      } else {
        return d.toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" });
      }
    } else {
      return "";
    }
  }

  getDateWithDayName(date, type) {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dateinput = new Date(date);
    const dayIndex = dateinput.getDay();
    return type === "C" ? `${dayNames[dayIndex]}, ${this.formatDateReq(date)}` : `${dayNames[dayIndex]}, ${this.formatDate(date)}`;
  }

  /**
   * Convert Date as Mmm DD, YYYY
   * @param {*} date 
   * @returns 
   */
  getDateWithMMMDYYYY(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    const dateinput = new Date(date);
    const day = dateinput.getDate();
    const monthIndex = dateinput.getMonth();
    const year = dateinput.getFullYear();
    return monthNames[monthIndex]+ ' ' + day + ', ' + year;
  }
  /**
   * Convert Date as Mmm DD, YYYY hh:mm A UTC
   * @param {*} date 
   * @returns 
   */
   getDateWithMMMDYYYYUTC(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    const dateinput = new Date(date);
    const day = ('0' + dateinput.getDate()).slice(-2);
    const monthIndex = dateinput.getMonth();
    const year = dateinput.getFullYear();
    const time  =moment(date).utc().format("hh:mm A").valueOf()+ " (UTC)" 
     return monthNames[monthIndex]+ ' ' + day + ', ' + year + ' ' + time;
  }
  /**
   * Convert Date as 
   * Today HH:MM AM/PM
   * Yesterday HH:MM AM/PM
   * DD Mmm YYYY, HH:MM AM/PM
   * @param {*} date 
   * @returns 
   */
  getDateWithMMMDYYYYHHMMSS(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    const d = new Date(date);
    const currentdate = new Date();
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    if (
      currentdate.getFullYear() === d.getFullYear() &&
      currentdate.getMonth() === d.getMonth() &&
      currentdate.getDate() === d.getDate()
    ) {
      return `Today, ${d.toLocaleTimeString("en-us", {
        timeStyle: "short",
      })}`;
    } else if (
      yesterdayDate.getFullYear() === d.getFullYear() &&
      yesterdayDate.getMonth() === d.getMonth() &&
      yesterdayDate.getDate() === d.getDate()
    ) {
      return `Yesterday, ${d.toLocaleTimeString("en-us", {
        timeStyle: "short",
      })}`;
    } else {
      const day = d.getDate();
      const monthIndex = d.getMonth();
      const year = d.getFullYear();
      let hours = d.getHours();
      let minutes = d.getMinutes();
      let newformat = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours || 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return `${day} ${monthNames[monthIndex]} ${year}, ${("00" + hours).slice(-2)}:${(
        "00" + minutes
      ).slice(-2)} ${newformat}`;
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  getBytesInMB(bytes, decimals = 2) {
    const dm = decimals < 0 ? 0 : decimals;
    //return parseFloat(bytes / 1024 ** 2).toFixed(dm);
    return Number(parseFloat(bytes / 1024 ** 2).toFixed(dm)).toLocaleString('fullwide', { useGrouping: false });
  }

  getGBFromMB(fileSize, decimals) {
    const dm = decimals < 0 ? 0 : decimals;
    return parseFloat(fileSize / 1024).toFixed(dm);
  }

  getBytesFromGB(gbSize) {
    return gbSize * Math.pow(1024, 3);
  }

  getGBFormat(size) {
    return Number(size).toLocaleString('fullwide', { useGrouping: false });
  }

  getFileExtension(fileName) {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileName)[1];
  }
  isContainSpecialCharacters(name) {
    const regex = /[`!@#$%^*+=[\]{};':"\\|,<>\/?~]/;
    return regex.test(name);
  }

  /**
   * Get the HTML equivalent to Markdown text
   * @param {*} markdownText - Markdown text
   * @returns HTML equivalent to Markdown text
   */
  markdownToHtml = (markdownText) => {
    var converter = new showdown.Converter();
    return converter.makeHtml(DOMPurify.sanitize(markdownText))
  }

  /**
  * Get deleted document name 
  * @returns document name
  */
  async getdeleteDocumentName() {
    const documentService = new DocumentService();
    const uploadDocuments = await documentService.getDocuments(
      sessionStorage.getItem("DashboardProjectId")
    );
    if (uploadDocuments.length > 0) {
      const deletedInProgressDocs = _.filter(
        uploadDocuments,
        function (item) {
          return item.status === "Delete InProgress";
        }
      );

      if (deletedInProgressDocs.length == 0) {
        const deletedCompletedDocs = _.filter(
          uploadDocuments,
          function (item) {
            return item.status === "Deleted";
          }
        );
        if (deletedCompletedDocs.length > 0) {
          deletedCompletedDocs.sort(function (a, b) {
            return new Date(b.modifiedDate) - new Date(a.modifiedDate);
          });
          return deletedCompletedDocs[0].documentName;

        }
      }
      else {
        return "";
      }

    }
  }

  /**
   * Check if the group name provided is in the SAT group name configuration
   * @param {groupDisplayName} groupDisplayName Group name to check as SAT Group name 
   * @returns true if provided group name is SAT group name, otherwise false
   */
  isSatGroup(groupDisplayName) {
    //REACT_APP_GROUPNAME_DAISEY configuration contains comma (,) separated group names
    const satGroupNames = groupConfig.groupNameDaisEy.toLowerCase().split(",");
    return satGroupNames.findIndex(sgn => sgn.trim() === groupDisplayName.toLowerCase().trim()) !== -1
  }

  /**
   * Check if the group name provided is in the Platform Admin group name configuration
   * @param {groupDisplayName} groupDisplayName Group name to check as Platform Admin Group name 
   * @returns true if provided group name is Platform Admin group name, otherwise false
   */
  isPlatformAdminGroup(groupDisplayName) {
    //REACT_APP_GROUPNAME_PLATFORM_ADMIN configuration contains comma (,) separated group names
    const platformAdminGroupNames = groupConfig.groupNamePlatformAdmin.toLowerCase().split(",");
    return platformAdminGroupNames.findIndex(pagn => pagn.trim() === groupDisplayName.toLowerCase().trim()) !== -1
  }

  /**
   * Add custom tooltip to the HTML element
   * @param {htmlElement} htmlElement parameter an object of html element
   * @param {tooltTipText} tooltTipText parameter a text which should be displayed in tooltip area
   * @param {id} id parameter is id for a tooltip area 
   */
  addCustomTooltip(htmlElement, tooltTipText, id, position='top') {
    const tooltip = document.createElement('div');
    tooltip.id = `tooltip${id}`;
    tooltip.className = position==='top' ? 'tooltip-bulleted-numbered-top': 'tooltip-bulleted-numbered-bottom';
    tooltip.textContent = tooltTipText;
    tooltip.style.display = 'none';
    const leftValue = tooltTipText==='Bulleted list' ? 36: 42.5;
    const positionValue = position==='top' ? 40 : -40;

    document.body.appendChild(tooltip);

    htmlElement.addEventListener('mouseenter', (e) => {
      const rect = htmlElement.getBoundingClientRect();
      tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2 - leftValue}px`; // Center horizontally
      tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight - positionValue}px`; // Position above the button
      tooltip.style.display = 'flex';
      tooltip.style.marginTop="-4px";
      tooltip.style.height="34px";
    });

    htmlElement.addEventListener('mouseleave', () => {
      tooltip.style.display = 'none';
    });

  }

  /**
   * Encrypt  text
   * @param {*} text
   * @returns
   */
  encryptText = (text, secretKey) => {

    // Generate a random salt for encryption
    const generateSalt = () => Crypto.lib.WordArray.random(128/8);

    // Generate a random initialization vector
    const generateIV = () => Crypto.lib.WordArray.random(128/8);

    const deriveKey = (password, salt) => {
      return Crypto.PBKDF2(password, salt, {
        keySize: 256/32,
        iterations: 1000,
        hasher: Crypto.algo.SHA1  // Match Python's default PBKDF2 hasher
      });
    };

    // Generate salt and IV
    const salt = generateSalt();
    const iv = generateIV();

    const key = deriveKey(secretKey, salt);
    
    // Convert password to UTF-8 bytes
    const passwordBytes = Crypto.enc.Utf8.parse(text);

    // Encrypt using AES-CBC
    const encrypted = Crypto.AES.encrypt(passwordBytes, key, {
      iv: iv,
      mode: Crypto.mode.CBC,
      padding: Crypto.pad.Pkcs7
    });

     // Get raw ciphertext bytes
     const ciphertext = encrypted.ciphertext;

     // Convert components to Base64
     const saltBase64 = Crypto.enc.Base64.stringify(salt);
     const ivBase64 = Crypto.enc.Base64.stringify(iv);
     const ciphertextBase64 = Crypto.enc.Base64.stringify(ciphertext);

     return `${saltBase64}:${ivBase64}:${ciphertextBase64}`;
  };

  /**
   * Decrypt Text
   * @param {*} vEncryptedText
   * @returns
   */
  decryptText = (vEncryptedText) => {

    const deriveKey = (password, salt) => {
      return Crypto.PBKDF2(password, salt, {
        keySize: 256/32,
        iterations: 1000,
        hasher: Crypto.algo.SHA1  // Match Python's default PBKDF2 hasher
      });
    };
 
   // Split the encrypted data into components
   const [saltBase64, ivBase64, ciphertextBase64] = vEncryptedText.split(':');

   // Convert from Base64
   const salt = Crypto.enc.Base64.parse(saltBase64);
   const iv = Crypto.enc.Base64.parse(ivBase64);
   const ciphertext = Crypto.enc.Base64.parse(ciphertextBase64);

   // Derive key using the same parameters
   const key = deriveKey(ReportAuthorConfig.secretKey, salt);

   // Create cipher params
   const encrypted = Crypto.lib.CipherParams.create({
     ciphertext: ciphertext,
     salt: salt,
     iv: iv
   });

   // Decrypt
   const decrypted = Crypto.AES.decrypt(encrypted, key, {
     iv: iv,
     mode: Crypto.mode.CBC,
     padding: Crypto.pad.Pkcs7
   });

   // Convert to UTF-8 string
   const decryptedText = decrypted.toString(Crypto.enc.Utf8);
   return decryptedText;
  };
/**
   * Get the HTML equivalent to Markdown text
   * @param {*} htmlText - Markdown text
   * @returns HTML equivalent to Markdown text
   */
htmlToMarkdown = (htmlText) => {
  if (htmlText && htmlText.includes("<") && htmlText.includes(">")) {
    var converter = new showdown.Converter();
    let markdownText = converter.makeMarkdown(htmlText)
    markdownText = markdownText.replace(/<br\s*\/?>/gi, '  \n');
    markdownText = markdownText.replace(/<!--[\s\S]*?-->/g, '');
    return markdownText;
  } else {
    return htmlText;
  }
}
/**
   * Get the Decoded Html
   * @param {*} html - Html text
   * @returns Decoded Html
   */
decodeHTML = (html) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(html, 'text/html');
    return doc.documentElement.textContent;
}

isSupportedDocumentType(extention) {
  return ReportAuthorConfig.supportedDocumentTypeList.indexOf(extention) > -1;
}

}
export default Utils;
