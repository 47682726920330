import { configureStore } from "@reduxjs/toolkit";
import instantInsightReducer from "./reducers/instantInsightSlice";
import promptManagementReducer from "./reducers/promptManagementSlice";
import scopeReducer from "./reducers/scopeSlice";
import activeUserReducer from "./reducers/activeUserRoleSlice";
import activeSatScopeReducer from "./reducers/activeSatScopeIdSlice"

const store = configureStore({
  reducer: {
    instantInsight: instantInsightReducer,
    promptManagement: promptManagementReducer,
    scope: scopeReducer,
    activeUser: activeUserReducer,
    satScopeId:activeSatScopeReducer,
  },
});
export default store;
