//  Generic imports
import React, { useState, useRef, useEffect } from "react";

//  Motif imports
import MotifRichTextEditor from "@ey-xd/motif-react/RichTextEditor";
import { MotifButton, MotifInput } from "@ey-xd/motif-react";

//  Internal imports
import "./RichTextEditor.css";
const constantData = require("../../data/constant.js");

const RichTextEditor = ({
  editorContent,
  setEditorContent,
  handleEditorDataChange,
  editorContentError,
}) => {

  const [selectedText, setSelectedText] = useState("");
  const [isLinkModalVisible, setIsLinkModalVisible] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [selectionRange, setSelectionRange] = useState(null);
  const notificationReference = useRef(null);

  //  Getting the domain name from .env file
  const domainNames = process.env.REACT_APP_DOMAIN_NAMES || '';
  const allowedDomainNames = domainNames.split(',').map(url => url.trim());

  useEffect(() => {
    overrideLinkInputEvent();
  }, []);

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    const text = selection.toString();
    if (text) {
      setSelectedText(text);
      setSelectionRange(selection.getRangeAt(0));
      setIsLinkModalVisible(true);
    }
  };

  const handleSaveLink = () => {
    const linkedText = `<a href="${linkUrl}" style="font-weight:bold;">${linkUrl}</a>`;
    const tempElement = document.createElement("div");
    tempElement.innerHTML = linkedText;
    const linkedNode = tempElement.firstChild;

    const range = selectionRange;
    range.deleteContents();
    range.insertNode(linkedNode);

    const updatedContent = document.querySelector(".motifeditor").innerHTML;
    setEditorContent(updatedContent);
    handleEditorDataChange(updatedContent);
    setIsLinkModalVisible(false);
  };

  const handleLinkUrlChange = (e) => setLinkUrl(e.target.value);

  const validateLinkInput = (link, saveLink) => {
    let isValidLink = false;
    let validationError = "";
    const linkInputText = notificationReference.current?.querySelector(".ql-tooltip.ql-editing > input");
    if (linkInputText) {
      linkInputText.placeholder = "https://discovercontent.ey.net/";
    }
    const domainName = URL.canParse(link) ? (new URL(link)).hostname : "";    
    if (!URL.canParse(link)) {
      validationError = constantData.INVALID_LINK_ERROR; 
    } else if (!allowedDomainNames.includes(domainName)) {
      validationError = constantData.ADD_VALID_LINK_ERROR; 
    } else {
      isValidLink = true;
    }
    const errorMessageContainer = document.getElementById("linkError");
    if (errorMessageContainer) {
      errorMessageContainer.innerText = validationError;
    }
    if (saveLink) {
      saveLink.style = isValidLink ? "" : "color:grey; pointer-events:none"
    }
  }

  const overrideLinkInputEvent = () => {
    const promptEditor = notificationReference.current;
    const linkInputText = promptEditor?.querySelector(".ql-tooltip.ql-editing > input");
    const saveLink = promptEditor?.querySelector(".ql-tooltip.ql-editing > a.ql-action");
    
    if (linkInputText) {
      linkInputText.placeholder = "https://discovercontent.ey.net/";
      //  Insert error message div
      const linkTooltipContainer = promptEditor?.querySelector(".ql-tooltip.ql-editing");
      const errorMessageContainer = document.createElement("div");
      errorMessageContainer.setAttribute("id", "linkError");
      errorMessageContainer.setAttribute("class", "linkError");
      linkTooltipContainer.appendChild(errorMessageContainer);

      linkInputText.addEventListener("input", (e) => {
        validateLinkInput(e.target.value, saveLink);
      });
      linkInputText.addEventListener("focus", (e) => {
        validateLinkInput(e.target.value, saveLink);
      });
      validateLinkInput(linkInputText.value, saveLink);
    } else {
      setTimeout(overrideLinkInputEvent, 10);
    }
  }

  const toolbarOptions = [
    [{ font: ["MyCustomFont", "Arial", "Courier"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ];

  return (
    <div>
      <div className="mlabel-message-desc">Message Description</div>
      <div className="motif-rich-editor-div " ref={notificationReference}>
        <MotifRichTextEditor
          className={`motifeditor ${editorContentError ? "editor-error" : ""}`}
          fonts={["hello"]}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          modules={{
            clipboard: {
              matchVisual: false,
            },
            toolbar: [
              [
                {
                  font: [],
                },
              ],
              ["bold", "italic"],
              [
                {
                  header: "1",
                },
                {
                  header: "2",
                },
              ],
              ["link"],
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ],
            ],
          }}
          onChange={(content, delta, source, editor) => {
            const updatedContent = editor.getHTML();
            setEditorContent(updatedContent);
            handleEditorDataChange(updatedContent);
          }}
          value={editorContent}
          onMouseUp={handleSelectionChange}
        />
      </div>
      {isLinkModalVisible && (
        <div className="link-modal">
          <label>Enter Link</label>
          <MotifInput
            aria-label="Input"
            id="input"
            label="Input"
            value={linkUrl}
            onChange={handleLinkUrlChange}
          />
          <MotifButton onClick={handleSaveLink} disabled={!linkUrl}>
            Save
          </MotifButton>
        </div>
      )}
    </div>
  );
};

export default RichTextEditor;
