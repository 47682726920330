import React,  { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//  Motif controls
import MotifTabNavigation, { MotifTabControl } from "@ey-xd/motif-react/TabNavigation";
import PlatformService from "../../../../services/platformService";
import { setRefreshScopes, setScopeList } from "../../../../reducers/scopeSlice";

export default function Powerpoint() {
  
    //  Initialization of hooks
    const dispatch = useDispatch();

    //  Initialization of services
    const platformService = new PlatformService();

    //  Initialization of redux state variables
    const { refreshScopes } = useSelector((state) => state.scope);
    const { isStructureComplete } = useSelector((state) => state.promptManagement);

    const [selectedTab, setSelectedTab] = useState('SystemPrompts');

    const tabIds = {
        SystemPrompts: "PPSystemPrompts",
        CentralPrompts: "PPCentralPrompts",
    };

    const navigate = useNavigate();

    const handlePromptManagementTabClick = (tab) => {
        setSelectedTab(tab);
        dispatch(
            setRefreshScopes({
                refreshScopes: !refreshScopes,
            })
        );
        switch (tab) {
            case tabIds.SystemPrompts:
                navigate("SystemPrompts");
                break;
            case tabIds.CentralPrompts:
                navigate("CentralPrompts");
                break;
            default:
                navigate("SystemPrompts");
                break;
        }
    };
    return (
        <div>
            <div className="prompt-managment-container">
                <MotifTabNavigation
                    className="tab"
                    defaultActiveKey={0}
                    data-testid="tabsPrompts"
                    id="tabsPPPromptManagement"
                >
                    <MotifTabControl
                        onClick={() => handlePromptManagementTabClick(tabIds.SystemPrompts)}
                        data-testid="tabSystemPrompts"
                        className={(isStructureComplete && (selectedTab === tabIds.SystemPrompts)) ? 'active' : ''}
                    >
                        System Prompts
                    </MotifTabControl>
                    <MotifTabControl
                        onClick={() =>
                            handlePromptManagementTabClick(tabIds.CentralPrompts)
                        }
                        className={(!isStructureComplete || (selectedTab === tabIds.CentralPrompts)) ? 'active' : ''}
                    >
                        Central Prompts
                    </MotifTabControl>
                </MotifTabNavigation>
            </div>
        </div>
    )
}
