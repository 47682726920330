import React, { useEffect, useState } from "react";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import { MotifToast } from "@ey-xd/motif-react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  setSelectedPromptsToMove,
  setTargetSubSection,
} from "../../../../reducers/instantInsightSlice";
import { MotifButton } from "@ey-xd/motif-react";
import plus from "../../../../assets/image/plus_scope.svg";

import DeletePrompt from "../InstantInsightPrompts/DeletePromptPopup";
import infoempty from "../../../../assets/image/info-empty.svg";
import PromptAccordion from "./PromptAccordion";
import MoveInstantInsightPromptModal from "../InstantInsightPrompts/MoveInstantInsightPromptModal";
import ConfirmMovePromptModal from "../InstantInsightPrompts/ConfirmMovePromptModal";

import PlatformService from "../../../../services/platformService";

import noProject from "../../../../assets/image/no-project-image.png";
import PromptAction from "./PromptAction";
import EditTestPrompts from "./EditTestPrompts";
import NoPromptFound from "./NoPromptFound";
import AddEditPromptModal from "./AddEditPromptModal";

import checkCircle from "../../../../assets/image/check-circle.svg";

import Utils from "../../../../utils/Utils";
import SaTPromptAccordion from "./SaTPromptAccordion";
import { satPromptAddedToastMsg, satPromptEditedToastMsg, duplicateErrorMsg } from "../../../../data/constant";
import { ActiveTestPromptStatus } from "../../../../config/groupConfig";

const PromptDetail = (props) => {
  const platformService = new PlatformService();
  const dispatch = useDispatch();
  const [node, setNode] = useState({});
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [disableDeleteforIITest, setDisableDeleteforIITest] = useState(false);
  const [disableDeleteforII, setDisableDeleteforII] = useState(false);
  const [showDeletedComplete, setshowDeletedComplete] = useState(false);
  const [showMovePrompt, setShowMovePrompt] = useState(false);
  const [showConfirmMovePrompt, setShowConfirmMovePrompt] = useState(false);
  const [promptsSelected, setPromptsSelected] = useState([]);
  const [activePromptList, setActivePromptList] = useState([]);
  const [testPromptList, setTestPromptList] = useState([]);
  const [showEditPromptPopover, setShowEditPromptPopover] = useState(false);
  const [showEditSuccessfullToast, setShowEditSuccessfullToast] = useState(false);
  const [currentTestPrompt, setCurrentTestPrompt] = useState(null);
  const [isPromptMoveSuccess, setIsPromptMoveSuccess] = useState(false);
  const tabIds = { activePrompt: "activePrompt", testPrompt: "testPrompt" };
  const [currentTab, setCurrentTab] = useState(tabIds.activePrompt[0]);
  const [selectedSystemCategory, setSelectedSystemCategory] = useState("");
  const [editedPromptsSelected, setEditedPromptsSelected] = useState(false);
  const [showNoPromptsMessage, setShowNoPromptsActiveMessage] = useState(false);
  const [showNoPromptTestMessage, setShowNoPromptTestMessage] = useState(false);
  const [isSubsectionSelected, setIsSubsectionSelected] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showPublishSuccessMessage, setShowPublishSuccessMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [errorMessageForTitle, setErrorMessageForTitle] = useState();
  const [promptText, setPromptText] = useState();
  const [promptTitle, setPromptTitle] = useState();
  const [maxLengthReached, setMaxLengthReached] = useState(false);
  const [editIITestPrompt, setEditIITestPrompt] = useState(false);
  const [editPromptId, setEditPromptId] = useState(false);
  const [selectedNodeID, setSelectedNodeID] = useState();
  const [matchedTitle, setMatchedTitle] = useState();
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState();
  const [currentEditedPrompt, setCurrentEditedPrompt] = useState(null);
  const [satPrompts, setSatPrompts] = useState(false);
  const [promptId, setPromptId] = useState();
  const INITIALPROMPTTEXT = currentEditedPrompt?.platformPromptText ? currentEditedPrompt?.platformPromptText : "";
  const INITIALPROMPTITLE = currentEditedPrompt?.platformPromptTitle ? currentEditedPrompt?.platformPromptTitle : "";
  const [errorMessageForPrompt, setErrorMessageForPrompt] = useState();
  const utils = new Utils();
  const SATINITIALPROMPTITLE = currentEditedPrompt?.title ? currentEditedPrompt?.title : "";
  const SATINITIALPROMPTTEXT = currentEditedPrompt?.promptText ? currentEditedPrompt?.promptText : "";

  useEffect(() => {
    if (props.tab === 'SaTPromptLibrary') {
      setSatPrompts(true)
    }
    else {
      setSatPrompts(false)
    }
  }, [props.tab])

  useEffect(() => {
    setNode(props.selectedNode);

    return () => {
      dispatch(
        setSelectedPromptsToMove({
          selectedPromptsToMove: null,
        })
      );
      dispatch(
        setTargetSubSection({
          targetSubSection: null,
        })
      );
    };
  }, [props.selectedNode]);
  useEffect(() => {
    if (props.tab === "InstantInsightPrompts") {
      if (node?.id && node?.nodeType === 3) {
        getActivePrompts(node.id);
        getTestPrompts(node.id);
      } else {
        setIsSubsectionSelected(false);
        setActivePromptList([]);
        setTestPromptList([]);
      }
    } else if ((props.tab === "SystemPrompts")) {
      if (node?.api) {
        setSelectedSystemCategory(node.api);
      } else {
        setIsSubsectionSelected(false);
        setActivePromptList([]);
        setTestPromptList([]);
      }
    }
    else {
      if (props.selectedScopeId !== null) {
        setIsSubsectionSelected(true);
        if (props.selectedNode) {
          const promptList = ([])
          props.selectedNode.map((p) => {
            promptList.push({ ...p, isChecked: false });
          });
          setActivePromptList(promptList);
        }
      }
      else {
        setIsSubsectionSelected(false);
        setActivePromptList([]);
        setTestPromptList([]);
      }
    }
  }, [node]);
  /**
   * This method enable/disable the delete prompt button
   */
  useEffect(() => {
    if (testPromptList?.length > 0) {
      const anyTestPromptsSelected = testPromptList.filter(
        (data) => data.isChecked === true
      );
      if (anyTestPromptsSelected.length > 0) {
        if (props.tab === "InstantInsightPrompts") {
          setDisableDeleteforIITest(true)
        }
        else if (props.tab === "SaTPromptLibrary") {
          setDisableDeleteforIITest(true)
        }
        else {
          setEditedPromptsSelected(true);
        }

      } else {
        setEditedPromptsSelected(false);
        setDisableDeleteforIITest(false);
      }
      setPromptsSelected(anyTestPromptsSelected);
    }
    if (activePromptList?.length > 0) {
      const anyPromptsSelected = activePromptList.filter(
        (data) => data.isChecked === true
      );
      if (anyPromptsSelected.length > 0 || props.selectedScope?.spEydStatus != 4) {
        setDisableDelete(true);
      } else {
        setDisableDelete(false);
      }
      if (anyPromptsSelected.length > 0 || props.selectedScope?.spiiStatus != 4) {
        setDisableDeleteforII(true);
      } else {
        setDisableDeleteforII(false);
      }
    }

  }, [activePromptList, testPromptList]);

  /**
   * This useEffect method Delete Confirm and reload prompts
   */
  useEffect(() => {
    if (showDeletedComplete) {
      if (node?.id && node?.nodeType === 3) {
        getActivePrompts(node.id);
        getTestPrompts(node.id);
        setShowMovePrompt(false);
      } else {
        setActivePromptList([]);
        setTestPromptList([]);
      }

      //setshowDeletedComplete(false);
    }
  }, [showDeletedComplete]);

  useEffect(() => {
    if (selectedSystemCategory && currentTab) {
      getSystemPrompts();
    }
  }, [selectedSystemCategory, currentTab]);

  /**
   * To delete prompts from UI /DB. Input is row
   */
  const deletePrompt = async () => {
    setShowDeleteConfirmPopup(true);
  };

  /**
   * This is a callback method from the move instant insight prompt modal
   */
  const movePrompt = () => {
    let selectedPrompts = _.filter(activePromptList, { isChecked: true });
    dispatch(
      setSelectedPromptsToMove({
        selectedPromptsToMove: selectedPrompts,
      })
    );

    let selectedDetails = {
      category: _.pick(node?.parent?.parent, ["id", "value"]),
      section: _.pick(node?.parent, ["id", "value"]),
      subSection: _.pick(node, ["id", "value"]),
    };

    dispatch(
      setTargetSubSection({
        targetSubSection: selectedDetails,
      })
    );
    setShowMovePrompt(true);
  };

  /**
   * This function is for closing the toaster message after delete prompts
   */
  const onclosepromptclick = () => {
    setshowDeletedComplete(false);
  };

  const onCloseEditSuccessfullToast = () => {
    setShowEditSuccessfullToast(false);
  }

  const updateEditStatus = (status) => {
    getSystemPrompts();
    setShowEditSuccessfullToast(status);
  }

  /**
   * This method to get the active prompts
   * @param {*} subSectionId
   */
  const getActivePrompts = async (subSectionId) => {
    const prompts = await platformService.getInstantInsightActivePrompts(
      subSectionId
    );
    setIsSubsectionSelected(true);
    if (prompts) {
      let promptList = [];
      prompts.map((p) => {
        promptList.push({ ...p, isChecked: false });
      });
      setActivePromptList(promptList);
    }
  };

  /**
   * This method to get the system prompts
   * @param {*} subSectionId
   */
  const getSystemPrompts = async () => {
    const prompts = await platformService.getSystemPrompts(
      selectedSystemCategory,
      currentTab,
      props.selectedScopeId,
    );
    setIsSubsectionSelected(true);
    if (prompts) {
      let promptList = [];
      prompts.map((p) => {
        promptList.push({ ...p, isChecked: false });
      });
      if (currentTab === tabIds.activePrompt[0]) {
        setActivePromptList(promptList);
      } else {
        setTestPromptList(promptList);
      }
    }
  };

  /**
   * This method to get the test prompts
   * @param {*} subSectionId
   */
  const getTestPrompts = async (subSectionId) => {
    const prompts = await platformService.getInstantInsightTestPrompts(
      subSectionId
    );
    setIsSubsectionSelected(true);
    if (prompts) {
      let promptList = [];
      prompts.map((p) => {
        promptList.push({ ...p, isChecked: false });
      });
      setTestPromptList(promptList);
    }
  };

  /**
   * This method is to set the selected prompts from the active prompt list
   * @param {integer} id
   * @param {boolean} checked
   */
  const onSelectedActivePrompt = (id, checked) => {
    let activePrompts = activePromptList.map((prompt) =>
      prompt.platformPromptId === id
        ? { ...prompt, isChecked: checked }
        : prompt
    );
    setActivePromptList(activePrompts);
  };

  /**
   * This method is to set the selected prompts from the active prompt list
   * @param {integer} id
   * @param {boolean} checked
   */
  const onSelectedTestPrompt = (id, checked) => {
    let testPrompts = testPromptList.map((prompt) =>
      prompt.platformPromptId === id
        ? { ...prompt, isChecked: checked }
        : prompt
    );
    setTestPromptList(testPrompts);
    setEditedPromptsSelected(testPrompts.some(
      (prompt) => prompt.isChecked === true))
  };

  const onSelectedTestSaTPrompt = (id, checked) => {
    let testPrompts = testPromptList.map((prompt) =>
      prompt.saTPromptId === id
        ? { ...prompt, isChecked: checked }
        : prompt
    );
    setTestPromptList(testPrompts);
    setEditedPromptsSelected(testPrompts.some(
      (prompt) => prompt.isChecked === true))
  };

  const onPromptEditIconClick = (e, testPrompt) => {
    setShowEditPromptPopover(true);
    setCurrentTestPrompt(testPrompt);
    e.stopPropagation();
  }

  const onEditPopoverCloseClick = (e) => {
    setShowEditPromptPopover(false);
  }

  /**
   * This method calls when user continue button click from the move prompt modal
   */
  const continueMoveInstantInsightPrompt = () => {
    setShowConfirmMovePrompt(true);
  };

  /**
   * This method calls when move prompt opertation succeeds
   */
  const onMovePromptSuccess = () => {
    setShowConfirmMovePrompt(false);
    setIsPromptMoveSuccess(true);
    setTimeout(() => {
      getActivePrompts(node.id);
      getTestPrompts(node.id);
    }, 1000);
  };

  /**
   * This method calls when publish prompt opertation succeeds
   */
  const onPublishPromptSuccess = () => {
    setShowPublishSuccessMessage(true);
    setTimeout(() => {
      getActivePrompts(node.id);
      getTestPrompts(node.id);
    }, 1000);
  };
  const getSatPrompts = async () => {
    const prompts = await platformService.getSaTPromptData(
      props.selectedScopeId,
      "T"
    );
    setTestPromptList(prompts);
  }

  const getSatActivePrompts = async () => {
    let SaTTestData = await platformService.getSaTPromptData(
      props.selectedScopeId,
      ActiveTestPromptStatus.Active
    );
    setActivePromptList(SaTTestData);
  }

  const onPublishSatPromptSuccess = () => {
    setShowPublishSuccessMessage(true);
    getSatPrompts()
    getSatActivePrompts()

  };


  /**
   * This method to switch the tabs active to test prompts
   * @param {integer} tab
   */
  const handleTabClick = async (tab) => {
    setCurrentTab(tab[0]);
    switch (tab) {
      case tabIds.activePrompt:
        document.getElementById("promptIIActivePromptDetail").style.display =
          "block";
        document.getElementById("promptIITestPromptDetail").style.display =
          "none";
        break;
      case tabIds.testPrompt:
        document.getElementById("promptIIActivePromptDetail").style.display =
          "none";
        document.getElementById("promptIITestPromptDetail").style.display =
          "block";
        break;
      default:
    }
    if (tab === "testPrompt" && props.tab === "SaTPromptLibrary") {
      let SaTTestData = await platformService.getSaTPromptData(
        props.selectedScopeId,
        "T"
      );
      setTestPromptList(SaTTestData);
    }
  };
  const checkTab = async () => {
    if (currentTab === "a" && props.tab === "SaTPromptLibrary") {
      let SaTTestData = await platformService.getSaTPromptData(
        props.selectedScopeId,
        ActiveTestPromptStatus.Active
      );
      setTestPromptList(SaTTestData);
    }
    if (currentTab === "t" && props.tab === "SaTPromptLibrary") {
      let SaTTestData = await platformService.getSaTPromptData(
        props.selectedScopeId,
        ActiveTestPromptStatus.Test
      );
      setTestPromptList(SaTTestData);
    }
  }
  useEffect(() => {
    checkTab()
  }, [props.selectedScopeId])

  const addTestPrompt = () => {
    if (props.tab === "SaTPromptLibrary") {
      setSatPrompts(true);
    }
    else {
      setSatPrompts(false);
    }
    setShowModal(true);
    setPromptText()
    setPromptTitle()
    setSelectedNodeID(props.selectedNode?.id)
    setErrorMessageForTitle('');
    setErrorMessageForPrompt('');
  }
  const onCancelBtnClick = () => {
    setShowModal(false);
    setEditIITestPrompt(false)
    setPromptText()
    setPromptTitle()
    setErrorMessageForTitle('');
    setErrorMessageForPrompt('');
  }
  useEffect(() => {
    if (!editIITestPrompt) {
      setPromptText()
      setPromptTitle()
      setErrorMessageForTitle()
      setErrorMessageForPrompt();
    }
  }, [editIITestPrompt])

  const onPromptTitlechange = async (event) => {
    let value = event.target.value;
    const restrictedCharactersRegex = /[`!@$%^+\]\[\\{};'"\|,~]/;
    if (restrictedCharactersRegex.test(value)) {
      setErrorMessageForTitle("Special characters not allowed");
    } else {
      setErrorMessageForTitle('');
      setIsConfirmBtnDisabled(false)
    }
    if (value.length > 50) {
      value = value.slice(0, 50);
      setMaxLengthReached(true)
    }
    else {
      setMaxLengthReached(false);
    }
    setPromptTitle(value);

    if (satPrompts) {
      const prompts = await platformService.getSaTPromptData(props.selectedScopeId, "T");
      const filteredData = prompts.filter((prompt) =>
        prompt?.title?.toLowerCase() === value.toLowerCase().trim()
      );

      if (filteredData.length !== 0) {
        setErrorMessageForTitle(duplicateErrorMsg);
      }
      const newData = filteredData[0];
      if (editIITestPrompt) {
        if (newData?.title.trim() === matchedTitle) {
          setErrorMessageForTitle("")
        }
      }

    }
    else {
      const prompts = await platformService.getInstantInsightTestPrompts(selectedNodeID);
      const filteredData = prompts.filter((prompts) => prompts?.platformPromptTitle.toLowerCase() === value.toLowerCase().trim())
      if (filteredData.length !== 0) {
        setErrorMessageForTitle("Title already in use, choose another title");
      }
      const newData = filteredData[0];
      if (editIITestPrompt) {
        if (newData?.platformPromptTitle.trim() === matchedTitle) {
          setErrorMessageForTitle("")
        }
      }
    }
  }

  const handleBlur = () => {
    setMaxLengthReached(false);
  }
  const onPromptTxtChange = (value) => {
    setPromptText(value);
    const restrictedCharactersRegex = /&/;
    const decodedPrompt = utils.decodeHTML(value);
    if (restrictedCharactersRegex.test(decodedPrompt)) {
      setErrorMessageForPrompt("Special characters not allowed");
      setIsConfirmBtnDisabled(true)
    } else {
      setErrorMessageForPrompt('');
      setIsConfirmBtnDisabled(false)
    }
  }
  const handleConfirmClick = async () => {
    if (satPrompts) {
      const testPayload1 = {
        saTPromptId: editIITestPrompt ? promptId : null,
        scopeId: props.selectedScopeId,
        title: promptTitle,
        promptText: promptText,
        type: editIITestPrompt ? "E" : "I"

      }
      let response = await platformService.addEditSaTPrompt(testPayload1)
      setShowModal(false);
      setShowToast(true);
      setToastMessage(!editIITestPrompt ? satPromptAddedToastMsg : satPromptEditedToastMsg);
      let SaTTestData = await platformService.getSaTPromptData(
        props.selectedScopeId,
        "T"
      );
      setTestPromptList(SaTTestData);
    } else {
      const testPayload = {
        platformPromptTestId: editIITestPrompt ? editPromptId : null,
        platformPromptTestTitle: promptTitle,
        platformPromptTestText: promptText,
        subSectionId: selectedNodeID,
        type: editIITestPrompt ? "E" : "I"
      };
      let response = await platformService.AddEditInstantInsightTestPrompt(testPayload)
      setShowModal(false);
      setShowToast(true);
      setToastMessage(!editIITestPrompt ? "The prompt has been added successfully" : "Prompt has been edited successfully");
      getTestPrompts(selectedNodeID)
    }
    setEditIITestPrompt(false)
  }

  const EditTestPromptsClick = (e, testPromptII) => {
    setCurrentEditedPrompt(testPromptII);
    setSelectedNodeID(props.selectedNode?.id)
    setEditPromptId(testPromptII.platformPromptId)
    setPromptTitle(testPromptII?.platformPromptTitle)
    setPromptText(testPromptII?.platformPromptText)
    setEditIITestPrompt(true)
    setShowModal(true);
    e.stopPropagation();
    setMatchedTitle(testPromptII?.platformPromptTitle)
  }

  const EditSatTestPromptsClick = (e, testPromptSaT) => {
    setCurrentEditedPrompt(testPromptSaT);
    setPromptTitle(testPromptSaT?.title)
    setPromptText(testPromptSaT?.promptText)
    setPromptId(testPromptSaT.saTPromptId)
    setEditIITestPrompt(true)
    setShowModal(true);
    e.stopPropagation();
    setMatchedTitle(testPromptSaT?.title)
  }
  useEffect(() => {
    if (!promptTitle || !promptText || errorMessageForTitle !== '' || errorMessageForPrompt !== '') {
      setIsConfirmBtnDisabled(true);
    }
    else {
      setIsConfirmBtnDisabled(false);
    }
  }, [promptText, promptTitle, errorMessageForTitle, errorMessageForPrompt])

  useEffect(() => {
    if ((promptTitle === INITIALPROMPTITLE && promptText === INITIALPROMPTTEXT) || (promptTitle === SATINITIALPROMPTITLE && promptText === SATINITIALPROMPTTEXT) || !promptTitle || !promptText
      || errorMessageForPrompt?.length > 0 || errorMessageForTitle?.length > 0) {
      setIsConfirmBtnDisabled(true);
    }
    else {
      setIsConfirmBtnDisabled(false);
    }

  }, [promptText, promptTitle, INITIALPROMPTITLE, INITIALPROMPTTEXT, errorMessageForPrompt, errorMessageForTitle, SATINITIALPROMPTTEXT, SATINITIALPROMPTITLE])



  return (
    <div className="ii-prompt-response-detail">
      {(() => {
        if (isSubsectionSelected) {
          return (
            <div className="ii-detail-container">
              {props.tab === "InstantInsightPrompts" && (
                <div className="ii-detail-subsection-header">{node?.value}</div>
              )}
              {props.tab === "SystemPrompts" && (
                <div className="ii-detail-subsection-header">{node?.api}</div>
              )}
              <MotifTabNavigation id="tabPromptResponse" defaultActiveKey={0}>
                <MotifTabControl
                  onClick={() => handleTabClick(tabIds.activePrompt)}
                >
                  Active Prompts
                </MotifTabControl>
                <MotifTabControl
                  onClick={() => handleTabClick(tabIds.testPrompt)}
                >
                  Test Prompts
                </MotifTabControl>
              </MotifTabNavigation>
              <div id="promptIIActivePromptDetail">
                <div className="ii-prompt-response-container">
                  {activePromptList.length == 0 && (
                    <NoPromptFound activePromptList={activePromptList} tab={props.tab}></NoPromptFound>
                  )}
                  {activePromptList.length > 0 && (
                    <PromptAction
                      tab={props.tab}
                      innerTab={"active"}
                      disableActionButtons={!disableDelete}
                      onDeletePrompt={deletePrompt}
                      onMovePrompt={movePrompt}
                    />
                  )}
                  <div className="ii-detail-prompt-accordion">
                    {activePromptList.length > 0 && (props.tab === "SystemPrompts" || props.tab === "InstantInsightPrompts") &&
                      activePromptList?.map((activePrompt, index) => {
                        return (
                          <PromptAccordion
                            key={selectedSystemCategory + currentTab + activePrompt?.platformPromptId + index.toString()}
                            prompt={activePrompt}
                            onCheckPrompt={onSelectedActivePrompt}
                            tab={props.tab}
                            type={tabIds.activePrompt}
                          />
                        );
                      })}

                    {activePromptList.length > 0 && props.tab === "SaTPromptLibrary" &&
                      activePromptList?.map((activePrompt, index) => {
                        return (
                          <SaTPromptAccordion
                            key={selectedSystemCategory + currentTab + activePrompt?.saTPromptId + index.toString()}
                            prompt={activePrompt}
                            onCheckPrompt={onSelectedActivePrompt}
                            tab={props.tab}
                            type={tabIds.activePrompt}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>

              <div id="promptIITestPromptDetail">
                <div className="ii-prompt-response-container">
                  {props.tab === "SystemPrompts" && testPromptList.length == 0 && (
                    <NoPromptFound>

                    </NoPromptFound>
                  )}
                  {props.tab === "InstantInsightPrompts" && testPromptList?.length === 0 && (
                    <>
                      <MotifButton
                        label="btnCreate"
                        className="li-delete-button positionRight"
                        onClick={addTestPrompt}
                      >
                        <img src={plus} alt="plus" />Add New Prompt
                      </MotifButton>
                      <NoPromptFound></NoPromptFound>
                    </>
                  )}
                  {props.tab === "SaTPromptLibrary" && testPromptList?.length === 0 && (
                    <>
                      <MotifButton
                        label="btnCreate"
                        className="li-delete-button positionRight"
                        onClick={addTestPrompt}
                      >
                        <img src={plus} alt="plus" />Add New Prompt
                      </MotifButton>
                      <NoPromptFound testPromptList={testPromptList}></NoPromptFound>
                    </>
                  )}
                  {testPromptList?.length > 0 && (
                    <PromptAction
                      tab={props.tab}
                      innerTab={"test"}
                      selectedScopeId={props.selectedScopeId}
                      disableActionButtonsforEyd={!disableDelete && !editedPromptsSelected}
                      disableActionButtonsII={!disableDeleteforII && !editedPromptsSelected}
                      disableDeleteforIITest={!disableDeleteforIITest}
                      onDeletePrompt={deletePrompt}
                      checkedPrompts={testPromptList.filter(prompt => prompt.isChecked)}
                      getSystemPrompts={getSystemPrompts}
                      node={node}
                      addTestPrompt={addTestPrompt}
                      onSuccessPublishPrompt={onPublishPromptSuccess}
                      testPromptsList={testPromptList}
                      onPublishSatPromptSuccess={onPublishSatPromptSuccess}
                    />
                  )}
                  <div className="ii-detail-prompt-accordion">
                    {testPromptList?.length > 0 && (props.tab === "SystemPrompts" || props.tab === "InstantInsightPrompts") &&
                      testPromptList?.map((testPrompt, index) => {
                        return (
                          <PromptAccordion
                            key={selectedSystemCategory + currentTab + testPrompt?.platformPromptId + index.toString()}
                            prompt={testPrompt}
                            onCheckPrompt={onSelectedTestPrompt}
                            tab={props.tab}
                            type={tabIds.testPrompt}
                            onEditIconClick={(e) => onPromptEditIconClick(e, testPrompt)}
                            node={node}
                            editTextPrompts={(e) => EditTestPromptsClick(e, testPrompt)}
                          />
                        );
                      })}
                    {testPromptList?.length > 0 && props.tab === "SaTPromptLibrary" &&
                      testPromptList?.map((testPrompt, index) => {
                        return (
                          <SaTPromptAccordion
                            key={selectedSystemCategory + currentTab + testPrompt?.saTPromptId + index.toString()}
                            prompt={testPrompt}
                            onCheckPrompt={onSelectedTestSaTPrompt}
                            tab={props.tab}
                            type={tabIds.testPrompt}
                            onEditIconClick={(e) => onPromptEditIconClick(e, testPrompt)}
                            node={node}
                            editSatTextPrompts={(e) => EditSatTestPromptsClick(e, testPrompt)}
                          />
                        );
                      })}
                  </div>
                </div>
                {
                  showEditPromptPopover &&
                  (
                    <EditTestPrompts onEditModalCloseClick={onEditPopoverCloseClick} prompt={currentTestPrompt} updateEditStatus={updateEditStatus} />
                  )
                }
              </div>
              {
                showEditSuccessfullToast &&
                (
                  <MotifToast
                    icons={{ toast: <img src={checkCircle} alt="" /> }}
                    variant="success"
                    actionName=""
                    position="bottom"
                    className="prompt-edited-toast"
                    onClose={() => {
                      onCloseEditSuccessfullToast();
                    }}
                  >
                    <div>{currentTestPrompt.platformPromptTitle} edited successfully</div>
                  </MotifToast>
                )
              }
              {showDeleteConfirmPopup && (
                <DeletePrompt
                  setshowDeletedComplete={setshowDeletedComplete}
                  setShowDeleteConfirmPopup={setShowDeleteConfirmPopup}
                  promptsSelected={promptsSelected}
                  tab={props.tab}
                  getSatPrompts={getSatPrompts}
                  getSatActivePrompts={getSatActivePrompts}
                />
              )}
              {showDeletedComplete && (
                <MotifToast
                  icons={{ toast: <img src={infoempty} alt=""></img> }}
                  variant="info"
                  actionName=""
                  position="bottom"
                  className="instant-toast-msg-delete"
                  onClose={() => {
                    onclosepromptclick();
                  }}
                >
                  <div><b>The prompt(s) have been deleted successfully</b></div>
                </MotifToast>
              )}

              {showMovePrompt && (
                <MoveInstantInsightPromptModal
                  scopeTreeData={props.scopeTreeData}
                  selectedSubSection={node}
                  continueMoveInstantInsightPrompt={() => {
                    setShowMovePrompt(false);
                    continueMoveInstantInsightPrompt();
                  }}
                  closeMovePrompt={() => {
                    setShowMovePrompt(false);
                  }}
                />
              )}
              {showConfirmMovePrompt && (
                <ConfirmMovePromptModal
                  closeConfirmMovePrompt={() => {
                    setShowConfirmMovePrompt(false);
                    setShowMovePrompt(true);
                  }}
                  onMovePromptComplete={onMovePromptSuccess}
                />
              )}
            </div>
          );
        } else {
          return (
            <div className="ii-prompt-detail-no-selection">
              <img src={noProject} alt="no selection made" />
              <div className="ii-prompt-no-selection-div">
                <span className="ii-prompt-no-selection-text">
                  No selection made
                </span>
                <span className="ii-prompt-no-selection-desc">
                  Please make a selection from the left pane
                </span>
              </div>
            </div>
          );
        }
      })()}
      {isPromptMoveSuccess && (
        <MotifToast
          variant="success"
          className="instant-toast-msg"
          position="bottom"
          onClose={() => {
            setIsPromptMoveSuccess(false);
          }}
          icons={{ toast: <img src={checkCircle} alt="" /> }}
        >
          <div className="prompt-move-success-message">Prompt moved successfully</div>
        </MotifToast>
      )}
      {(props.tab === "InstantInsightPrompts" || props.tab === "SaTPromptLibrary") && showModal && (
        <AddEditPromptModal
          onCancelBtnClick={onCancelBtnClick}
          handleConfirmClick={handleConfirmClick}
          onPromptTxtChange={onPromptTxtChange}
          onPromptTitlechange={onPromptTitlechange}
          errorMessageForTitle={errorMessageForTitle}
          promptText={promptText}
          promptTitle={promptTitle}
          maxLengthReached={maxLengthReached}
          handleBlur={handleBlur}
          editIITestPrompt={editIITestPrompt}
          isConfirmBtnDisabled={isConfirmBtnDisabled}
          errorMessageForPrompt={errorMessageForPrompt}

        ></AddEditPromptModal>
      )}
      {showToast &&
        <MotifToast
          icons={{ toast: <img src={checkCircle} alt=""></img> }}

          variant="success"
          className={"publish-motif-toast"}
          position="bottom"
          onClose={() => setShowToast(false)}
        >
          <div className="toast-body">{toastMessage}</div>
        </MotifToast>
      }
      {showPublishSuccessMessage &&
        <MotifToast
          icons={{ toast: <img src={checkCircle} alt=""></img> }}

          variant="success"
          className={"publish-motif-toast"}
          position="bottom"
          onClose={() => setShowPublishSuccessMessage(false)}
        >
          <div className="prompt-move-success-message">The prompt(s) have been successfully published to Active Prompts</div>
        </MotifToast>
      }
    </div>
  );
};

export default PromptDetail;
