
import React from "react";
import noProject from "../../../../assets/image/no-project-image.png";
import "./NoPromptFound.css";
export default function NoPromptFound(props) {
  return (
    <div className="ii-prompt-detail-no-selection">
      <img className="icon-noPrompt" src={noProject} alt="no selection made" />
      <div className="ii-prompt-no-selection-div">
        <span className="ii-prompt-no-selection-text">
          No prompts found
          {
            props.testPromptList?.length === 0 && 
            <span className="ii-prompt-no-selection-desc" style={{display: "block"}}>
              Add a prompt to get started
            </span>
          }
          {
            props.tab === "SaTPromptLibrary" && props.activePromptList?.length === 0 && 
            <span className="ii-prompt-no-selection-desc" style={{display: "block"}}>
             Publish prompts from Test Prompts
            </span>
          }
        </span>
      </div>
    </div>
  )
}