import axios from "../services/axiosWithMsal";
import CommonService from "./commonService.js";
class ExploreService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = this.commonService.getAPIUrl();
  }

  async GetDownlodedDocument(projectId, documentName) {
    const userDocumentRequest = {
      Project_UUID: projectId,
      DocumentName: documentName,
    };
    const response = await axios
      .get(this.apiUrl + "Document/DownloadDocument", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        responseType: "blob",
        params: userDocumentRequest,
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Download Document"
        );
      });

    return response;
  }

  async GetPromptResponse(
    conversationId,
    projectId,
    Prompt,
    filetype,
    email,
    flag,
    fileList,
    scopeId
  ) {
    const userPromptRequest = {
      Conv_UUID: conversationId,
      Project_UUID: projectId,
      Prompt: Prompt,
      FileType: "All",
      userEmail: email,
      publicFlag: flag,
      fileList: fileList,
      scopeId:scopeId,
      projectType:localStorage.getItem('ProjectType'),
    };

    const response = await axios
      .post(this.apiUrl + "Prompt/GetPromptResponse", userPromptRequest)
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Get Prompt Response",
          userPromptRequest
        );
      });

    return response.data;
  }
   /**
   * This service returns the project scope id
   * @param projectType: object contains the values of project id 
   * @returns 
   */
  async GetEYDScope (projectId) {
    const userRequest = {
      Project_UUID: projectId,
      Email: sessionStorage.getItem("Email") 
    };
    const response = await axios
    .get(this.apiUrl + "Prompt/GetEYDScopeDetails", {
      headers: {  
        "Content-type": "application/json; charset=UTF-8",
      },
      params: userRequest,
    })
    .catch((error) => {
      this.commonService.logException(
        error,
        "Explore Your Data",
        "GetEYDScopeDetails"
      );
    });

  return response;
  
  };
  /**
   * This service returns the project scopes
   * @param projectType: object contains the values of project type 
   * @returns 
   */
  async GetEYDScopeDetails (projectType) {
    const userRequest = {
      ProjectType: projectType
    };
    const response = await axios
    .get(this.apiUrl + "Prompt/GetEYDScopeDetailsByProjectType", {
      headers: {  
        "Content-type": "application/json; charset=UTF-8",
      },
      params: userRequest,
    })
    .catch((error) => {
      this.commonService.logException(
        error,
        "Explore Your Data",
        "GetEYDScopeDetailsByProjectType"
      );
    });

  return response;
  
  };

  async AddPromptToLibrary(PromptData, scopeId) {
    const addPromptToLibraryrequest = {
      promptid: PromptData.promptid,
      convid: PromptData.convid,
      project_uuid: PromptData.project_uuid,
      prompttype: "Personal",
      title: PromptData.title,
      tags: PromptData.tags,
      prompttext: PromptData.prompttext,
      IsFavOrSaved: PromptData.IsFavOrSaved,
      Email: PromptData.Email,
      scopeId: scopeId ? scopeId : ""
    };

    const response = await axios
      .post(
        this.apiUrl + "PromptLibrary/AddPromptToLibrary",
        addPromptToLibraryrequest
      )
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Add Prompt To Library"
        );
      });

    return response.status;
  }

  /**
   * This service saves the modified response
   * @param prompt: object contains the values of modified response 
   * @returns 
   */
  async updatePromptResponse(prompt) {
    let response = true;
    const apiEndPoint = `Prompt/UpdateModifiedResponse`;
    await axios.put(this.apiUrl + apiEndPoint, prompt).catch((error) => {
      this.commonService.logException(
        error,
        "Explore Your Data",
        "Update Prompt Response"
      );
      response = false;
    });

    return response;
  }

  /**
   * This service saves the modified response
   * @param prompt: object contains the values of modified response 
   * @returns 
   */
  async upsertLikeOrDislikePromptContent(promptId, userAction) {
    const apiEndPoint = `Prompt/UpdateExplorePromptUserAction`;
    let request = { promptID: promptId, userAction: userAction };
    return axios.put(this.apiUrl + apiEndPoint, request)
      .then((response) => {
        if (!!response && response.status === 200) {
          return { promptHistoryId: request.promptID, userAction: request.userAction };
        }
      }).catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Update Prompt Like or Dislike User Action Response"
        );
        return false;
      });
  }

  /**
   * Api call to remove the favorite prompt of Explore your data from favorites
   * @param {*} promptId 
   * @returns 
   */
  async removePromptFromFavorites(promptId) {
    let response = true;
    await axios
      .put(this.apiUrl + "Prompt/MarkPromptAsUnFavorite?promptId=" + promptId)
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore your data Component",
          "Remove Favorite Prompt from Favorites"
        );
        response = false;
      });
    return response;
  }
}

export default ExploreService;
