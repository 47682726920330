/**
 * Modal to display discard incomplete structure
 * Created on : 02/12/2024
 * Modified on : 07/01/2025
 */
//  Generic imports
import React from "react";
import PropTypes from "prop-types";
import { useBlocker } from "react-router-dom";
import { useDispatch } from "react-redux";

//  Motif imports
import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";

// Internal imports
import "../../../dashboard/ClosePopup.css";
import "../../../ProjectDashboard/InstantInsights/InstantInsights.css";
import { setIsStructureComplete } from "../../../../reducers/promptManagementSlice";

//  Icons imports
import iconWarningCircle from "../../../../assets/image/warning-circle.svg";

//  Constants
const constants = require("../../../../data/constant.js");

export default function DiscardIncompleteStructure(props) {

  const { state, proceed, reset } = useBlocker(props.when);

  //  Initialization of hooks
  const dispatch = useDispatch();
  
  /**
   * Confirm action performed on manual discard
   */
  const btnConfirmClick = async () => {
    dispatch(
      setIsStructureComplete({
        isStructureComplete: true,
      })
    );
    if (props.trigger === 'dynamic') {
      proceed();
    } else {
      props.setOpenModal(false);
      props.onConfirm();
    }
  };

  /**
   * Close action performed on modal
   */
  const btnModalCloseClick = () => {
    props.setOpenModal(false);
    props.onCancel();
  };

  return (
    (((props.trigger === 'dynamic') && (state === "blocked")) || (props.trigger === 'manual')) && (
      <MotifModal
        id="motifModalAcknowledgeRefresh"
        className="motif-modal-confidential modalDiscardIncompleteStructure"
        size="md"
        show={true}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={(props.trigger === 'dynamic') ? reset : btnModalCloseClick}
      >
        <div className="ii-acknowledgement-header-div">
          <MotifModalHeader
            closeButtonProps={{
              "aria-label": "Custom Close Button aria-label",
              title: "Close popup",
            }}
          >
            <div className="ii-acknowledgement-header-icon-div">
              <img src={iconWarningCircle} alt="Warning"></img>
            </div>
          </MotifModalHeader>
          <div className="ii-acknowledgement-title">Incomplete Structure</div>
        </div>
        <MotifModalBody>
          <div className="textISConfirmModalBody">
            {
              (props.messageType === 'selectionChange') ?
              constants.discardIncompleteStructure.messageSelectionChange :
              constants.discardIncompleteStructure.messageNavigation
            }
          </div>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="btns-div">
            <div>
              <MotifButton
                variant="secondary"
                size="large"
                onClick={(props.trigger === 'dynamic') ? reset : btnModalCloseClick}
              >
                Cancel
              </MotifButton>
            </div>
            <div>
              <MotifButton
                variant="warn"
                size="large"
                onClick={() => btnConfirmClick()}
              >
                Confirm
              </MotifButton>
            </div>
          </div>
        </MotifModalFooter>
      </MotifModal>
    )
  );
}

DiscardIncompleteStructure.propTypes = {
  when: PropTypes.bool,
  setOpenModal: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  trigger: PropTypes.string,
  messageType: PropTypes.string
}