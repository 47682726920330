import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from "@ey-xd/motif-react/Button";
import React, { useState } from "react";
import "./DocumentDeleteConfirmModal.css";
import icon_no from "../../../assets/image/icon_no.svg";
export function DocumentDeleteConfirmModal({
  setOpenModal,
  setConfirm,
  documentUUID,
  documentName,
}) {
  const [open, setOpen] = useState(true);

  /**
   * Description
   * -----------
   * This method is use to close the confirm modal.
   */
  const onCloseClick = () => {
    setOpen(false);
    setOpenModal(false);
  };

  return (
    <div>
      <MotifModal
        id="closepopupmodal"
        show={open}
        size="md"
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          onCloseClick();
        }}
        className="genericModal modalNoHeaderBorder modalMotifFooterModal"
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Close",
            title: "Close",
          }}
        >
          <img src={icon_no} alt="Warning"/> 
        </MotifModalHeader>
        <MotifModalBody>
          <React.Fragment key=".0">
            <div id="text">
            <span className="deletemesg"
        
        >
          Delete Document
        </span>
           <p className="deleteContent">
                Do you want to Delete the document '{documentName}' ?
              </p>
            </div>
            {/* <TestDropdown /> */}
          </React.Fragment>
        </MotifModalBody>
        <MotifModalFooter>
          <React.Fragment key=".0">
          <div className="deletedoc">

              <MotifButton
                className="buttondesign"
                onClick={() => {
                  onCloseClick();
                }}

                type="button"
                variant="secondary"
              >

                Cancel
              </MotifButton>
              <MotifButton
                className="deleteButtonBackground"
                onClick={() => {
                  setConfirm(documentUUID, documentName);
                }}
                size="medium"
                type="button"
              >

                Delete
              </MotifButton>
            </div>
          </React.Fragment>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
}

export default DocumentDeleteConfirmModal;
