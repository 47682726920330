import React, { useState } from "react";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import "../ProjectDashboard/ProjectDashboard.css";
import "./Notification.css";
import { MotifButton } from "@ey-xd/motif-react";
import noNotifiaction from "../../assets/image/no-notification-image.png";
import createnobell from "../../assets/image/create-no-bell.svg";
import CreateNotification from "../CreateNotification/CreateNotification";

const Notification = () => {
  const tabIds = {
    Active: "Active",
    Draft: "Draft",
    History: "History",
  };

  const [isCreatingNotification, setIsCreatingNotification] = useState(false);
  const [activeTab, setActiveTab] = useState(tabIds.Active);

  // will replace after api completion with redux
  const [notifications, setNotifications] = useState([]);

  const handleCreateNotificationClick = () => {
    setIsCreatingNotification(true);
  };

  const handleCancelCreateNotification = () => {
    setIsCreatingNotification(false);
  };

  const filterNotifications = () => {
    if (activeTab === tabIds.Active) {
      return notifications.filter((notification) => notification.isActive);
    } else if (activeTab === tabIds.Draft) {
      return notifications.filter((notification) => notification.isDraft);
    } else if (activeTab === tabIds.History) {
      return notifications.filter((notification) => notification.isHistory);
    }
    return [];
  };

  const renderTabContent = () => {
    const filteredNotifications = filterNotifications();

    return (
      <div>
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <div key={notification.notificationId}>{notification.content}</div>
          ))
        ) : (
          <div className="no-notification-container">
            <div className="notification-container">
              <img
                className="icon-no-notification"
                src={noNotifiaction}
                alt="no no-notification icon"
              />
              <div className="no-notification">
                <span className="text-notification-found">
                  No notifications found
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleNotificationManagementTabClick = (tab) => {
    // switch (tab) {
    //   case tabIds.Active:
    //     //navigate("InstantInsight");
    //     break;
    //   case tabIds.Draft:
    //     //navigate("InstantInsight");
    //     break;
    //   case tabIds.History:
    //     //("InstantInsight");
    //     break;
    //   default:
    //     break;
    // }

    setActiveTab(tab);
    setIsCreatingNotification(false);
  };

  // const renderNonotificationsmessage = () => {
  //   return (
  //     <div className="no-notification-container">
  //       <div className="notification-container">
  //         <img
  //           className="icon-no-notification"
  //           src={noNotifiaction}
  //           alt="no no-notification icon"
  //         />
  //         <div className="no-notification">
  //           <span className="text-notification-found">
  //             No notifications found
  //           </span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="ProjectDashboardPageContainer">
      <div className="HeaderLayout">
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Notifications</span>
          </div>

          {!isCreatingNotification && (
            <MotifButton
              onClick={handleCreateNotificationClick}
              className="motif-Create-notification-button"
            >
              {<img alt="icon" src={createnobell} className="bell" />}

              <span className="text-button">Create Notification</span>
            </MotifButton>
          )}
        </div>
      </div>

      {isCreatingNotification ? (
        <div className="create-notification-section">
          <CreateNotification onCancel={handleCancelCreateNotification} />
        </div>
      ) : (
        <div className="notification-managment-container">
          <MotifTabNavigation defaultActiveKey={0}>
            <MotifTabControl
              onClick={() =>
                handleNotificationManagementTabClick(tabIds.Active)
              }
            >
              Active
            </MotifTabControl>

            <MotifTabControl
              onClick={() => handleNotificationManagementTabClick(tabIds.Draft)}
            >
              Draft
            </MotifTabControl>

            <MotifTabControl
              onClick={() =>
                handleNotificationManagementTabClick(tabIds.History)
              }
            >
              History
            </MotifTabControl>
          </MotifTabNavigation>

          {/* {activeTab === tabIds.Active && renderNonotificationsmessage()}
          {activeTab === tabIds.Draft && renderNonotificationsmessage()}
          {activeTab === tabIds.History && renderNonotificationsmessage()} */}
          <div className="tab-content">{renderTabContent()}</div>
        </div>
      )}
    </div>
  );
};

export default Notification;
