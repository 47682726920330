
import React from 'react';
import { DashboardContext } from "../../context/dashboardContext";
import InstantInsights from './InstantInsights/InstantInsights';
import avatarImage from "../../assets/image/default-user-icon.jpg";
import exploreImage from "../../assets/image/explore.svg";
import logo from "../../assets/image/logo.svg";
import documentImage from "../../assets/image/document.svg";
import projectWorkspace from "../../assets/image/project-workspace-nav.svg";
import memberImage from "../../assets/image/member.svg";
import projectdetail from "../../assets/image/projectdetail.svg";
import projectDashboardImage from "../../assets/image/admin-dashboard.svg";
import magicwand from "../../assets/image/magic-wand.svg";
import menu from "../../assets/image/menu.svg";
import logOut from "../../assets/image/log-out.svg";
import userGuide from "../../assets/image/user-guide.svg"
import { useMsal } from '@azure/msal-react';

import {
  MotifVerticalNavigationMenuItem,
  MotifVerticalNavigationMenu,
  MotifVerticalNavigation,
  MotifVerticalNavigationContent,
  MotifVerticalNavigationFooter,
  MotifDropdownItem,
  MotifAvatar,
  MotifDropdownPortal,
} from "@ey-xd/motif-react";
import { MotifIconButton } from '@ey-xd/motif-react/components/button';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import CommonComponent from '../common/CommonComponent';
import { dashboardDataConstants } from '../dashboard/constants';

/**
   * Description
   * -----------
   * This method will be helpful for using the react hooks in class component
   */
function withMyHook(Component) {
    return function WrappedComponent(props) {
        const myInstance = useMsal();
        const myLocation = useLocation();
        return <Component {...props} myInstance={myInstance} myLocation={myLocation}/>;
    }
}
class ProjectDashboard extends React.Component {

    constructor() {
        super();

        this.state = {
            collapseMenu: true,
            showUserMenu: false,
            exploreClick: true,
            docLibClick: false,
            membersClick: false,
            prjDetClick: false,
            //promptLibClick: false,
            projectDashboard: false,
            navigateToDashboard: false,
            insightsClick: false,
            showInsightsPage: false,
            showOtherPages: true
        }

        this.highlightExploreDataNavigationOption = this.highlightExploreDataNavigationOption.bind(this);
        this.highlightProjectDataNavigationOption = this.highlightProjectDataNavigationOption.bind(this);
        this.projectMasterData = require("../../data/projectMasterData.js");
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const url = window.location.pathname;

        // Related to conditional rendering of Instant Insights page and others
        if (url === '/ProjectDashboard/InstantInsights') {
            this.setState({ showInsightsPage: true, showOtherPages: false });
        }
        else {
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }

        // Set highlight as per url
        this.setMenuHighlightAsPerURL(url);
    }
    onToggleButtonClick = () => {
        if (this.state.collapseMenu === true) {
            this.setState({ collapseMenu: false });
        }
        else {
            this.setState({ collapseMenu: true });
        }
    };
    showLoggedInUserMenu = () => {
        this.setState({ showUserMenu: true });
    };
    hideLoggedInUserMenu = () => {
        this.setState({ showUserMenu: false });
    };
    OnLogoClick = () => {
        window.location.href="/Dashboard";
    };

    /**
   * Description
   * -----------
   * This method logout from Azure Ad and navigate to logout page
   */
    handleLogOut = (e) => {
        localStorage.clear();
        sessionStorage.clear();
        const myInstance = this.props.myInstance;

        const logoutRequest = {
            account: myInstance.instance.getActiveAccount(),
            postLogoutRedirectUri: "/LogOut",
        };
        myInstance.instance.logout(logoutRequest);
    };

    //Set Explore Your Data menu option as selected
    highlightExploreDataNavigationOption() {
        if (this) {
            this.setState({ exploreClick: true });
            this.setState({ docLibClick: false });
            this.setState({ membersClick: false });
            this.setState({ prjDetClick: false });
            this.setState({ projectWorkspaceClick: false });
            //this.setState({ promptLibClick: false });
            this.setState({ projectDashboard: false });
            this.setState({ insightsClick: false });
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }
    };

    //Set ProjectDetail menu option as selected
    highlightProjectDataNavigationOption() {
        if (this) {
            this.setState({ exploreClick: false });
            this.setState({ docLibClick: false });
            this.setState({ membersClick: false });
            this.setState({ prjDetClick: true });
            this.setState({ projectWorkspaceClick: false });
           // this.setState({ promptLibClick: false });
            this.setState({ projectDashboard: false });
            this.setState({ insightsClick: false });
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }
    };

    //Set DcoumentLibrary menu option as selected
    highlightDocLibraryNavigationOption = () => {
        if (this) {
            this.setState({ docLibClick: true });
            this.setState({ exploreClick: false });
            this.setState({ membersClick: false });
            this.setState({ prjDetClick: false });
            this.setState({ projectWorkspaceClick: false });
            //this.setState({ promptLibClick: false });
            this.setState({ projectDashboard: false });
            this.setState({ insightsClick: false });
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }
    };

     //Set DcoumentLibrary menu option as selected
     highlightPersonalWorkspaceNavigationOption = () => {
        if (this) {
            this.setState({ projectWorkspaceClick: true });
            this.setState({ exploreClick: false });
            this.setState({ membersClick: false });
            this.setState({ prjDetClick: false });
            this.setState({ docLibClick: false });
            //this.setState({ promptLibClick: false });
            this.setState({ projectDashboard: false });
            this.setState({ insightsClick: false });
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }
    };

    /**
     * Set menu option highlight as per url.
     * @param {string} url 
     */
    setMenuHighlightAsPerURL = (url) => {
        switch (url) {
            case "/Dashboard":
            this.highlightProjectDashboardNavOption();
            break;
            case "/ProjectDashboard/ExploreData":
                this.highlightExploreDataNavigationOption();
                break;
            case "/ProjectDashboard/DocumentLibrary":
                this.highlightDocLibraryNavigationOption();
                break;
            case "/ProjectDashboard/Members":
                this.highlightMembersNavigationOption();
                break;
            case "/ProjectDashboard/Projectdetail":
                this.highlightProjectDataNavigationOption();
                break;
           /* case "/ProjectDashboard/PromptLibraries/Personal":
                this.highlightPromptLibraryNavOption();
                break;*/
            case "/ProjectDashboard/ProjectWorkspace/Personal":
                this.highlightPersonalWorkspaceNavigationOption();
                break;
            case "/ProjectDashboard/InstantInsights":
                this.highlightInsightsMenuOption();
                break;
        }
    }

    /**
     * Set Members menu option as selected.
     */
    highlightMembersNavigationOption = () => {
        this.setState({ membersClick: true });
        this.setState({ exploreClick: false });
        this.setState({ docLibClick: false });
        this.setState({ prjDetClick: false });
      //  this.setState({ promptLibClick: false });
        this.setState({ projectDashboard: false });
        this.setState({ projectWorkspaceClick: false });
        this.setState({ insightsClick: false });
        this.setState({ showInsightsPage: false, showOtherPages: true });
    }

    /**
     * Set Prompt Library menu item as selected.
     */
   /* highlightPromptLibraryNavOption = () => {
       // this.setState({ promptLibClick: true });
        this.setState({ projectDashboard: true });
        this.setState({ exploreClick: false });
        this.setState({ docLibClick: false });
        this.setState({ membersClick: false });
        this.setState({ prjDetClick: false });
        this.setState({ projectWorkspaceClick: false });
        this.setState({ insightsClick: false });
        this.setState({ showInsightsPage: false, showOtherPages: true });
    }*/

       /**
     * Set Prompt Library menu item as selected.
     */
    highlightProjectDashboardNavOption = () => {
       // this.setState({ promptLibClick: true });
        this.setState({ projectDashboard: true });
        this.setState({ exploreClick: false });
        this.setState({ docLibClick: false });
        this.setState({ membersClick: false });
        this.setState({ prjDetClick: false });
        this.setState({ projectWorkspaceClick: false });
        this.setState({ insightsClick: false });
        this.setState({ showInsightsPage: false, showOtherPages: true });
    }


    /**
     * Set Insights menu item as selected.
     */
    highlightInsightsMenuOption = () => {
        this.setState({ showInsightsPage: true, showOtherPages: false });
        //this.setState({ promptLibClick: false });
        this.setState({ projectDashboard: false });
        this.setState({ exploreClick: false });
        this.setState({ docLibClick: false });
        this.setState({ membersClick: false });
        this.setState({ projectWorkspaceClick: false });
        this.setState({ prjDetClick: false });
        this.setState({ insightsClick: true });
    }

    //This is to check DocumentLibrary state variable
    componentDidUpdate() {
        if (this.props.myLocation.state?.showDocLibrary) {
            this.highlightDocLibraryNavigationOption();
            this.props.myLocation.state.showDocLibrary = false;
        }
    }

    getCountry = (countryId) => {
        if (!countryId) {
            countryId = sessionStorage.getItem("ProjectCountry");
        }
        const country = this.projectMasterData.CountryConfig.find(country => country.id === countryId);
        return country ? country.content : "NA";
    }

  /*
   * Description
   * -----------
   * This method is to navigate to user guide page.
   */
     navigateToUserGuide = () => {
         window.open(dashboardDataConstants.userGuide.url,'_blank');
         };

    render() {

        return (
          <div>
            <div className="ProjectDashboardEditDetails">
              <div className="projectdashboardnavdiv">
                <MotifVerticalNavigation
                  collapse={this.state.collapseMenu}
                  className={
                    this.state.insightsClick && this.state.showInsightsPage
                      ? "instantheightnavdiv"
                      : ""
                  }
                >
                  <MotifVerticalNavigationContent>
                    <MotifVerticalNavigationMenu role="menu">
                      <Link
                        to="/Dashboard"
                        role="menuitem"
                        onClick={this.highlightProjectDashboardNavOption}
                      >
                        <div role="menu">
                          <MotifVerticalNavigationMenuItem
                            className={
                              this.state.projectDashboard ? "active-menu" : ""
                            }
                            icon={
                              <img alt="icon" src={projectDashboardImage} />
                            }
                            label="Project Dashboard"
                          >
                            Project Dashboard
                          </MotifVerticalNavigationMenuItem>
                        </div>
                      </Link>
                      <MotifVerticalNavigationFooter>
                        <div role="menu">
                          <Link
                            to="/ProjectDashboard/ExploreData"
                            role="menuitem"
                            onClick={() => {
                              this.highlightExploreDataNavigationOption();
                            }}
                          >
                            <div role="menu">
                              <MotifVerticalNavigationMenuItem
                                className={
                                  this.state.exploreClick ? "active-menu" : ""
                                }
                                icon={<img alt="icon" src={exploreImage} />}
                                label="Explore Your Data"
                                type="submit"
                              >
                                Explore Your Data
                              </MotifVerticalNavigationMenuItem>
                            </div>
                          </Link>
                          <Link
                            to="/ProjectDashboard/InstantInsights"
                            role="menuitem"
                            onClick={this.highlightInsightsMenuOption}
                          >
                            <div role="menu">
                              <MotifVerticalNavigationMenuItem
                                className={
                                  this.state.insightsClick ? "active-menu" : ""
                                }
                                icon={<img alt="icon" src={magicwand} />}
                                label="Instant Insights"
                              >
                                Instant Insights
                              </MotifVerticalNavigationMenuItem>
                            </div>
                          </Link>
                          <Link to="/ProjectDashboard/ProjectWorkspace/Personal" role="menuitem" onClick={() => {
                                                 this.highlightPersonalWorkspaceNavigationOption();
                                                 }}>
                                                <div role="menu">
                                                    <MotifVerticalNavigationMenuItem className={this.state.projectWorkspaceClick ? "active-menu" : ""}
                                                        icon={<img alt='icon' src={projectWorkspace} />}
                                                        label="Project Workspace"
                                                    >
                                                        Project Workspace
                                                    </MotifVerticalNavigationMenuItem>
                                                </div>
                                            </Link>
                        </div>
                      </MotifVerticalNavigationFooter>
                      <MotifVerticalNavigationFooter>
                        <Link
                          to="/ProjectDashboard/DocumentLibrary"
                          role="menuitem"
                          onClick={() => {
                            this.highlightDocLibraryNavigationOption();
                          }}
                        >
                          <div role="menu">
                            <MotifVerticalNavigationMenuItem
                              className={
                                this.state.docLibClick ? "active-menu" : ""
                              }
                              icon={<img alt="icon" src={documentImage} />}
                              label="Document Library"
                            >
                              Document Library
                            </MotifVerticalNavigationMenuItem>
                          </div>
                        </Link>
                        <Link
                          to="/ProjectDashboard/Members"
                          role="menuitem"
                          onClick={this.highlightMembersNavigationOption}
                        >
                          <div role="menu">
                            <MotifVerticalNavigationMenuItem
                              className={
                                this.state.membersClick ? "active-menu" : ""
                              }
                              icon={<img alt="icon" src={memberImage} />}
                              label="Members"
                            >
                              Members
                            </MotifVerticalNavigationMenuItem>
                          </div>
                        </Link>
                        <Link
                          to="/ProjectDashboard/Projectdetail"
                          role="menuitem"
                          onClick={() => {
                            this.highlightProjectDataNavigationOption();
                          }}
                        >
                          <div role="menu">
                            <MotifVerticalNavigationMenuItem
                              className={
                                this.state.prjDetClick ? "active-menu" : ""
                              }
                              icon={<img alt="icon" src={projectdetail} />}
                              label="Project Details"
                            >
                              Project Details
                            </MotifVerticalNavigationMenuItem>
                          </div>
                        </Link>
                      </MotifVerticalNavigationFooter>
                      <MotifVerticalNavigationFooter />
                    </MotifVerticalNavigationMenu>
                  </MotifVerticalNavigationContent>
                </MotifVerticalNavigation>
                <div className="utilmargin">
                  {this.state.showOtherPages && (
                    <Outlet
                      context={{
                        highlightExploreDataNavigationOption:
                          this.highlightExploreDataNavigationOption,
                        highlightProjectDataNavigationOption:
                          this.highlightProjectDataNavigationOption,
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: this.state.showInsightsPage ? "block" : "none",
                      height: "100%",
                    }}
                  >
                    <InstantInsights
                      highlightProjectDataNavigationOption={
                        this.highlightProjectDataNavigationOption
                      }
                      showInsightsPage={this.state.showInsightsPage}
                    ></InstantInsights>
                  </div>
                </div>
              </div>
              <div className="ProjectDashboardHeaderNav">
                <div className="ProjectDashboardLogoAndMenu">
                  <div className="ProjectDashboardLogoButton">
                    <div className="ProjectDashboardLogoButtonBase">
                      <img
                        alt="icon"
                        onClick={() => this.onToggleButtonClick()}
                        src={menu}
                      ></img>
                    </div>
                  </div>

                  <div className="ProjectDashboardLogoEngGap">
                    <div className="ProjectDashboardLogoProduct">
                      <div className="ProjectDashboardLogo">
                        <div className="ProjectDashboardContainerLogo">
                          <img
                            alt="icon"
                            className="img-hover"
                            src={logo}
                            onClick={() => this.OnLogoClick()}
                          ></img>
                        </div>
                      </div>
                      <div className="ProductName">
                        Digital Diligence Assistant
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ProjectDashboardContent1">
                  <div className="engagementLocationSelectDiv">
                    <DashboardContext.Consumer>
                      {(context) => (
                        <div className="engagementLocationSelectLabel">
                          Engagement location -{" "}
                          {this.getCountry(context.projectCountry)}
                        </div>
                      )}
                    </DashboardContext.Consumer>
                  </div>
                  <div className="ProjectDashboardSearchGroup">
                    <div className="ProjectDashboardIconGroup">
                      <div className="ProjectDashboardButtonsGroup"></div>
                    </div>
                  </div>
                  <div className="projectDashboardprofileImageContainer">
                    <MotifDropdownPortal
                      aria-labelledby="dropdown-trigger-3"
                      id="ProjectDashboardProfileDropdown"
                      open={this.state.showUserMenu}
                      handleClickOutside={() => this.hideLoggedInUserMenu()}
                      className="dropdownContainer"
                      placement="bottom"
                      hideArrow={true}
                      trigger={
                        <MotifIconButton
                          aria-label="Profile"
                          onClick={() => this.showLoggedInUserMenu()}
                          className="Avatar"
                        >
                          <MotifAvatar
                            description="Profile Icon"
                            src={avatarImage}
                            className="userAvatar"
                          />
                        </MotifIconButton>
                      }
                    >
                      <MotifDropdownItem
                        onClick={function noRefCheck() {}}
                        className="projectDashuserAvatarUserMenuItem"
                      >
                        <div className="displaymenu">
                          <div className="projectDashdisplaymenuDiv">
                            {
                              <img
                                src={avatarImage}
                                alt="Avatar"
                                className="AvatarDiv"
                              ></img>
                            }
                            {
                              <div className="CurrentUserNameDiv">
                                {" "}
                                <b>
                                  {sessionStorage.getItem("CurrentUserName")}
                                </b>
                                <div className="EmailDiv">
                                  {" "}
                                  {sessionStorage.getItem("Email")}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </MotifDropdownItem>
                      <div className="Divider1 divider" />
                      <MotifDropdownItem
                        onClick={() => this.navigateToUserGuide()}
                        className="projectDashuserguidecontainer"
                      >
                        <img
                          className="userguideimg"
                          alt="icon"
                          src={userGuide}
                        />
                        <span className="userguide">User Guide</span>
                      </MotifDropdownItem>
                      <div className="Divider1 divider" />
                      <MotifDropdownItem
                        onClick={(e) => this.handleLogOut(e)}
                        className="projectDashlogoutcontainer"
                      >
                        <img className="logoutimg" alt="icon" src={logOut} />
                        <span className="logout">Log out</span>
                      </MotifDropdownItem>
                      <div className="Divider1 divider" />
                    </MotifDropdownPortal>
                  </div>
                </div>
              </div>
            </div>
            {this.state.navigateToDashboard && (
              <Navigate to="../Dashboard"></Navigate>
            )}
            <CommonComponent />
          </div>
        );
    }

}


export default withMyHook(ProjectDashboard);