import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import MotifSearch from "@ey-xd/motif-react/Search";
import {
  MotifButton,
  MotifCheckbox,
  MotifFormField,
  MotifToast,
  MotifSelect,
  MotifOption,
  MotifLabel,
  MotifTooltip
} from "@ey-xd/motif-react";
import { MotifButtonGroup, MotifIconButton } from "@ey-xd/motif-react/Button";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import { v4 as uuidv4 } from "uuid";
import "../ExploreData/ExploreData.css";
import noDocumentImg from "../../../assets/image/noDocument.png";
import noLinkIcon from "../../../assets/image/no-link.svg";
import cancelIcon from "../../../assets/image/cancel.svg";
import ExpandIcon from "../../../assets/image/expand-button.svg";
import RightArrow from "../../../assets/image/right-arrow.svg";
import DeleteIcon from "../../../assets/image/warning-circle.svg";
import bookmarkfill from "../../../assets/image/bookmarkemptyblack.svg";
import editPencil from "../../../assets/image/edit-pencil.svg";
import plus from "../../../assets/image/plus_scope.svg";
import { DashboardContext } from "../../../context/dashboardContext.js";
import PromptModal from "./PromptModal";
import Utils from "../../../utils/Utils.js";
import PublicSearchIcon from "../../../assets/image/open-in-browser.svg";
import ExploreDataService from "../../../services/exploreDataService.js";
import ExploreService from "../../../services/exploreService.js";
import { explorerDataConstants } from "./constants.js";
import search from "../../../assets/image/search.svg";
import upload from "../../../assets/image/upload.svg";
import download from "../../../assets/image/download.svg";
import DownloadDocument from "../../../utils/downloadDocument";
import "./ExploreData.css";
import { conversationDeleteMsg, promptDeleteMsg } from "../../../data/constant";
import LoaderComponent from "../../common/LoaderComponent";
import PlatformService from "../../../services/platformService";
import { useDispatch } from "react-redux";
import { setsatScopeId } from "../../../reducers/activeSatScopeIdSlice";
import { useSelector } from "react-redux";
import PromptService from "../../../services/promptService";
import DeletePromptModal from "../PromptLibraries/DeletePromptModal";
import EditPromptModal from "../PromptLibraries/EditPromptModal";

const ExloreRightPanal = ({
  setShowInstantModal,
  publicOrConfidential,
  selectdocs,
  isSrcDocActive,
  handleQuestionFocus,
  handlePromptToQuestionBar,
  setEydScopeId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const utils = new Utils();
  const promptService = new PromptService();
  const exploreDataService = new ExploreDataService();
  //  Initialization of services
  const platformService = new PlatformService();
  const projectMasterData = require("../../../data/projectMasterData.js");
  const [srcdocClick, setSrcDocClick] = useState(true);
  const [historyClick, setHistoryClick] = useState(false);
  const [historyType, setHistoryType] = useState("");
  const [historySourceType, setHistorySourceType] = useState("");
  const [docs, setDocs] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [readyDocuments, setReadyDocuments] = useState([]);
  const [warningVisible, setWarningVisible] = useState(false);
  const [favorClick, setFavorClick] = useState(false);
  const [searchFavPrompt, setSearchFavPrompt] = useState("");
  const [conversationList, setConversationList] = useState([]);
  const [currentPromptType, setCurrentPromptType] = useState("");
  const [warningDocumentVisible, setwarningDocumentVisible] = useState(true);
  const [historyPrompts, setHistoryPrompts] = useState([]);
  const [historyConversationPrompts, setHistoryConversationPrompts] = useState(
    []
  );
  const [searchHisPrompt, setSearchHisPrompt] = useState("");
  const [filteredHistoryPrompts, setFilteredHistoryPrompts] = useState([]);
  const [isInstantInsightDisable, setInstantInsightDisable] = useState(false);
  const [isInstantInsight, setInstantInsight] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteConv_UUID, setDeleteConv_UUID] = useState("");
  const [deleteSuccess, setdeleteSuccess] = useState(false);
  const [isOpenPromptModal, setIsOpenPromptModal] = useState(false);
  const [specificPromptData, setSpecificPromptData] = useState("");
  const [scopeList, setScopeList] = useState([]);
  const [projectType, setProjectType] = useState();
  const [promptLibraryData, setPromptLibrary] = useState([]);
  const [filteredPromptList, setFilteredPromptList] = useState([]);
  const [filteredPersonalPromptList, setFilteredPersonalPromptList] = useState(
    []
  );
  const [promptPersonalData, setpromptPersonalData] = useState([]);
  const exploreService = new ExploreService();
  const [showConfirmDeletePrompt, setShowConfirmDeletePrompt] = useState(false);
  const [showConfirmEditPrompt, setShowConfirmEditPrompt] = useState(false);
  const [deletePromptSuccess, setDeletePromptSuccess] = useState(false);
  const { satScopeId } = useSelector((state) => state.satScopeId);

  const [srcDocDrpDwnItems, setSrcDocDrpDwnItems] = useState(
    explorerDataConstants.Types
  );
  const [filterTypes, setFilterTypes] = useState([]);
  const downloadDocument = new DownloadDocument();
  const [list, setList] = useState("favorites-prompts-list1");
  let {
    GetDocuments,
    getAlldocuments,
    setViewPrompt,
    setIsNewPromptCoversation,
    isRefreshFavorites,
    setIsRefreshFavorites,
  } = useContext(DashboardContext);

  /**
   * Description
   * -----------
   * This useEffect is used fetch the documents.
   */
  useEffect(() => {
    const getDocs = async () => {
      await GetDocuments(sessionStorage.getItem("DashboardProjectId"));
    };
    getDocs();
    setCurrentPromptType("C");
    setConversationList(projectMasterData.ConversationConfig);
  }, []);

  /**
   * Description
   * -----------
   * This useEffect is used filter the prompts from the search input for history prompt tab.
   */
  useEffect(() => {
    let Prompts = _.filter(historyPrompts, function (item) {
      return (
        item.requestContent
          ?.toLowerCase()
          .includes(searchHisPrompt.toLowerCase()) ||
        item.responseContent
          ?.toLowerCase()
          ?.includes(searchHisPrompt.toLowerCase())
      );
    });

    let historySearchResult = historyPrompts.filter((o1) =>
      Prompts.some((o2) => o1.conv_UUID === o2.conv_UUID)
    );
    const groupPromptsdata = _.groupBy(historySearchResult, "conv_UUID");
    const firstConversationPrompts = Object.keys(groupPromptsdata).map(
      (item) => groupPromptsdata[item][0]
    );
    setHistoryConversationPrompts(firstConversationPrompts);
  }, [searchHisPrompt]);

  /**
   * Description
   * -----------
   * This useEffect is used filter the prompts from the search input for favorite prompt tab.
   */
  useEffect(() => {
    let promptData = _.filter(promptLibraryData, function ({ title, promptText }) {
      return (title?.toLowerCase()?.includes(searchFavPrompt.toLowerCase()) || promptText?.toLowerCase()?.includes(searchFavPrompt.toLowerCase()));
    });
    setFilteredPromptList(promptData);
  }, [searchFavPrompt, promptLibraryData]);

  /**
   * Description
   * -----------
   * This useEffect is used filter the prompts from the search input for favorite prompt tab.
   */
  useEffect(() => {
    let promptData = _.filter(promptPersonalData, function ({ promptTitle, promptText }) {
      return (promptTitle
        ?.toLowerCase()
        ?.includes(searchFavPrompt.toLowerCase()) ||
        promptText?.toLowerCase()?.includes(searchFavPrompt.toLowerCase()));
    });
    setFilteredPersonalPromptList(promptData);
  }, [searchFavPrompt, promptPersonalData]);

  /**
   * Description
   * -----------
   * This useEffect is to reset search value on prompt tab change.
   */
  useEffect(() => {
    setSearchFavPrompt("");
  }, [currentPromptType]);
  /**
 * Description
 * -----------
 * This useEffect is to update personal prompt tab change.
 */
  useEffect(() => {
    getPersonalData();
  }, [srcdocClick]);
  /**
   * Description
   * -----------
   * This useEffect is use to fetch the prompts from the backend based on selection of tab.
   */
  useEffect(() => {
    getScopeList();
    if (favorClick) {
      getPromptDetails("F", currentPromptType);
      setInstantInsight(false);
      onScopeChange(satScopeId);
    } else if (historyClick) {
      getPromptDetails("H");
      setHistoryType(projectMasterData.ConversationConfig[0].content);
    }
  }, [favorClick, currentPromptType, historyClick]);

  /**
   * Description
   * -----------
   * This useEffect is use to set the document tab active.
   */
  useEffect(() => {
    if (isSrcDocActive) {
      setSrcDocClick(true);
      setHistoryClick(false);
    }
  }, [isSrcDocActive]);

  useEffect(() => {
    setProjectType(localStorage.getItem("ProjectType"));
  }, [projectType, promptLibraryData]);
  /**
   * Description
   * -----------
   * This method is used to get the prompt details.
   * Parameters:
   * promptType: view of the prompt favorite or history.
   * dataType: type of the prompt confidential or public.
   */

  const getScopeList = async () => {
    const response = await exploreService.GetEYDScopeDetails(localStorage.getItem('ProjectType'));
    const projectTypeRequest = (projectType === 1 || projectType === 2) ? "T" : "A";
    const promptData = await platformService.getSaTPromptData("", projectTypeRequest);
    const commonScopeList = _.intersectionBy(response.data, promptData, "scopeId")
    setScopeList(() => ([...commonScopeList]));
  }

  const getPromptDetails = async (promptType, dataType) => {
    setShowLoader(true);
    let confidentialHistoryprompts = await exploreDataService.GetPromptDetails(
      sessionStorage.getItem("DashboardProjectId"),
      promptType,
      dataType
    );

    if (confidentialHistoryprompts.length > 0) {
      confidentialHistoryprompts.forEach(function (prompt) {
        let documentList = [];
        let documentDetails = [];
        if (prompt.IsPublicConfidential === "P") {
          documentDetails = prompt.source?.split(",");
        } else {
          documentDetails = prompt.fileStatus?.split(",");
        }
        if (documentDetails?.length) {
          documentDetails.forEach(function (doc) {
            if (prompt.IsPublicConfidential === "P") {
              documentList.push({
                documentName: doc,
                isAvailable: true,
              });
            } else {
              let docInfo = doc.split(":");
              documentList.push({
                documentName: docInfo[0],
                isAvailable: docInfo[1] === "1",
              });
            }
          });
        }
        prompt.documentList = documentList;
      });
    }
    if (historyClick) {
      setHistoryPrompts(confidentialHistoryprompts);
      setFilteredHistoryPrompts(confidentialHistoryprompts);
      if (confidentialHistoryprompts.length > 0) {
        const groupPromptsdata = _.groupBy(
          confidentialHistoryprompts,
          "conv_UUID"
        );
        const firstConversationPrompts = Object.keys(groupPromptsdata).map(
          (item) => groupPromptsdata[item][0]
        );
        setHistoryConversationPrompts(firstConversationPrompts);
      } else {
        setHistoryConversationPrompts([]);
      }
    }
    setShowLoader(false);
  };

  /**
   * Description
   * -----------
   * This method search prompts in favorite prompt tab
   */
  const searchFavoritePrompt = (e) => {
    setSearchFavPrompt(e.target.value);
  };

  /**
   * Description
   * -----------
   * This method sets default prompt type for favorite prompt tab
   */
  const isActivePromptTypeButton = () => {
    return currentPromptType === "C";
  };

  /**
   * Description
   * -----------
   * This method search prompts in history prompt tab
   */
  const searchHistoryPrompt = (e) => {
    setSearchHisPrompt(e.target.value);
  };

  /**
   * Description
   * -----------
   * This method is used to toggle to confidential and public in favourite tab
   */
  const changePromptType = () => {
    if (currentPromptType === "C") {
      if (!isInstantInsight) {
        setCurrentPromptType("P");
        setInstantInsightDisable(true);
        getPersonalData();
      }
    } else {
      setCurrentPromptType("C");
      setInstantInsightDisable(false);
    }
  };

  /**
   * Description
   * -----------
   * This method is used to set the conversation id from the history to delete.
   * Parameters:
   * event: button click event object.
   * Conv_UUID: Conv_UUID which needs to delete.
   */

  const setDeleteConv = async (event, Conv_UUID) => {
    event.stopPropagation();
    setDeleteConv_UUID(Conv_UUID);
  };

  /**
   * Description
   * -----------
   * This method is used to open prompt modal pop-up.
   */

  const showPromptModal = (e, promptData) => {
    e.stopPropagation();
    if (promptData) {
      setSpecificPromptData(promptData);
    }
    setIsOpenPromptModal(true);
  };

  /**
   * Description
   * -----------
   * This method is used to delete the conversation from the history.
   * Parameters:
   * event: button click event object.
   * conv_UUID: conversation id which needs to deleted.

   */
  const deleteConversation = async (event) => {
    event.stopPropagation();
    const exploreDataService = new ExploreDataService();
    await exploreDataService.DeleteConversation(deleteConv_UUID);
    getPromptDetails("H", historySourceType);
    setdeleteSuccess(true);
    setDeleteConv_UUID("");
  };

  /**
   * Description
   * -----------
   * This method is used to view the prompt from favorite and history prompts list.
   * Parameters:
   * prompt: selected prompt values
   */
  const showPrompt = (prompt) => {
    const conversationPrompts = filteredHistoryPrompts.filter(
      (promptConversation) => promptConversation.conv_UUID === prompt.conv_UUID
    );
    sessionStorage.setItem(
      "PromptConversationId",
      conversationPrompts[0].conv_UUID
    );
    setViewPrompt(conversationPrompts);
  };

  /**
   * Description
   * -----------
   * This useEffect is used to get docs from backend API calla
   */
  useEffect(() => {
    let allReadyDocs = getAlldocuments.filter(function (doc) {
      return doc.status === "Complete";
    });
    allReadyDocs.map((item) => (item.selected = false));
    setReadyDocuments(allReadyDocs);
    setDocs(allReadyDocs);
  }, [getAlldocuments]);

  /**
   * Description
   * -----------
   * This method reset the conversation chat and stare new.
   */
  const createNewConversation = () => {
    setIsNewPromptCoversation(false);
    if (sessionStorage.getItem("PromptConversationId")) {
      sessionStorage.removeItem("PromptConversationId");
    }
    sessionStorage.setItem("PromptConversationId", uuidv4());
    setIsNewPromptCoversation(true);
    setSrcDocClick(true);
    setFavorClick(false);
    setHistoryClick(false);
  };

  /**
   * Description
   * -----------
   * document selection checkbox change event
   * Parameters:
   * e: checkbox change event
   * itemID: documenet id
   */
  const onChecked = (e, itemID) => {
    const currentIndex = srcDocDrpDwnItems.findIndex(
      (item) => item.id === itemID
    );
    const currentItem = srcDocDrpDwnItems.find((item) => item.id === itemID);

    let checked = !currentItem.checked;

    if (currentItem.type === "All") {
      if (checked) {
        setWarningVisible(true);
      } else {
        setWarningVisible(false);
      }
    }

    if (currentItem.type === "All") {
      let newSrcDrpDwnItems = srcDocDrpDwnItems;
      let filtTypes = [];
      newSrcDrpDwnItems.forEach((iter) => {
        iter.checked = checked;
      });
      let newFilteredDocs = docs;
      if (checked) {
        newFilteredDocs.forEach((item) => {
          item.selected = true;
        });
        filtTypes = newSrcDrpDwnItems
          .filter((itm) => itm.type !== "All")
          .map((object) => object.type);
      } else {
        newFilteredDocs.forEach((item) => {
          item.selected = false;
        });
        filtTypes = [];
      }
      setFilterTypes(filtTypes);
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      setDocs(newFilteredDocs);
      toggleToAllWarning(newSrcDrpDwnItems);
    } else {
      const updatedItem = {
        ...srcDocDrpDwnItems[currentIndex],
        checked: !currentItem.checked,
      };
      const newSrcDrpDwnItems = [...srcDocDrpDwnItems];
      newSrcDrpDwnItems[currentIndex] = updatedItem;
      setFilterTypes(
        newSrcDrpDwnItems
          .filter((itm) => itm.type !== "All" && itm.checked)
          .map((object) => object.type)
      );
      let newFilteredDocs = docs;
      if (checked) {
        newFilteredDocs.forEach((item) => {
          if (
            updatedItem.actualType.toLowerCase() ===
            item.documentName.split(".").pop().toLowerCase()
          ) {
            item.selected = true;
          }
        });
      } else {
        newFilteredDocs.forEach((item) => {
          if (
            updatedItem.actualType.toLowerCase() ===
            item.documentName.split(".").pop().toLowerCase()
          ) {
            item.selected = false;
          }
        });
        newSrcDrpDwnItems.forEach((item) => {
          if (item.type === "All") {
            item.checked = false;
          }
        });
        const selectAllIndex = srcDocDrpDwnItems.findIndex(
          (item) => item.type === "All"
        );
        const selectAllItem = {
          ...srcDocDrpDwnItems[selectAllIndex],
          checked: false,
        };
        newSrcDrpDwnItems[selectAllIndex] = selectAllItem;
      }
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      setDocs(newFilteredDocs);
      toggleToAllWarning(newSrcDrpDwnItems);
    }
    if (docs?.length > 0) {
      selectdocs(docs);
      let selectedDocs = docs.filter((doc) => doc.selected === true);
      setSelectedDocs(selectedDocs);
      if (docs.filter((doc) => doc.selected === true).length > 0) {
        setwarningDocumentVisible(false);
      } else {
        setwarningDocumentVisible(true);
      }
    }
  };
  /**
   * Description
   * -----------
   * This method filter out the docuements
   * Parameters:
   * currentItem: selected document item
   */
  const getLastSelectedType = (currentItem) => {
    let docsWithSelectedType = docs.filter((item) => {
      return (
        item.documentName.split(".").pop() ===
        currentItem.documentName.split(".").pop()
      );
    });
    return docsWithSelectedType.filter((item) => {
      return item.selected === true;
    }).length;
  };

  /**
   * Description
   * -----------
   * Doc selection from source document tab
   * Parameters:
   * e: checkbox change event
   * docId: documenet id
   */

  const onDocChange = (e, docId) => {
    const currentIndex = docs.findIndex((item) => item.documentId === docId);
    const currentItem = docs[currentIndex];
    const checked = !currentItem.selected;
    const updatedDoc = { ...currentItem, selected: checked };
    const newDocs = [...docs];
    newDocs[currentIndex] = updatedDoc;
    setDocs(newDocs);

    const newSelectedDocs = checked
      ? [...selectedDocs, updatedDoc]
      : selectedDocs.filter((doc) => doc.documentId !== docId);
    setSelectedDocs(newSelectedDocs);

    const docSelectCount = newSelectedDocs.length;
    setwarningDocumentVisible(docSelectCount === 0);

    let noOfTypedDocs = getLastSelectedType(currentItem);
    if (noOfTypedDocs === 1 && !checked) {
      let item = currentItem.documentName.split(".").pop();
      if (item === explorerDataConstants.excelComparator) {
        item = explorerDataConstants.dropdownExcelValue;
      } else if (item === explorerDataConstants.docComparator) {
        item = explorerDataConstants.dropdownDocValue;
      }
      let newSrcDrpDwnItems = srcDocDrpDwnItems.map((itm) => ({
        ...itm,
        checked:
          itm.type.toLowerCase() === item.toLowerCase() ? false : itm.checked,
      }));
      let newFilterTypes = filterTypes.filter(
        (itm) => itm.toLowerCase() !== item.toLowerCase()
      );
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      setFilterTypes(newFilterTypes);
    }
  };

  /**
   * Description
   * -----------
   * This useEffect is used to filter the selected documents
   */
  useEffect(() => {
    // Reset search when searchActive becomes false
    const updatedDocs = readyDocuments.map((doc) => {
      const selected = selectedDocs.some(
        (selectedDoc) => selectedDoc.documentId === doc.documentId
      );
      return { ...doc, selected };
    });
    if (!searchActive) {
      setDocs(updatedDocs);
    } else {
      const searchTerm = searchTxt.toLowerCase();
      const filteredDocs = readyDocuments
        .filter((doc) => doc.documentName.toLowerCase().includes(searchTerm))
        .map((doc) => {
          const selected = selectedDocs.some(
            (selectedDoc) => selectedDoc.documentId === doc.documentId
          );
          return { ...doc, selected };
        });
      setDocs(filteredDocs);
    }
    // Update the parent component or context with the selected documents
    selectdocs(updatedDocs.filter((doc) => doc.selected));
  }, [searchActive, searchTxt, readyDocuments, selectedDocs, selectdocs]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTxt(searchTerm);
    setSearchActive(true);

    if (searchTerm !== "") {
      const filteredDocs = readyDocuments
        .filter((doc) => doc.documentName.toLowerCase().includes(searchTerm))
        .map((doc) => {
          const selected = selectedDocs.some(
            (selectedDoc) => selectedDoc.documentId === doc.documentId
          );
          return { ...doc, selected };
        });
      setDocs(filteredDocs);
    } else {
      const updatedDocs = readyDocuments.map((doc) => {
        const selected = selectedDocs.some(
          (selectedDoc) => selectedDoc.documentId === doc.documentId
        );
        return { ...doc, selected };
      });
      setDocs(updatedDocs);
    }
  };

  /**
   * Description
   * -----------
   * History type selection change event (All/Conficential/Public)
   * Parameters:
   * e: dropdown change event
   */
  const onSourceChange = (e) => {
    if (e === projectMasterData.ConversationConfig[0].content) {
      getPromptDetails("H");
      setHistorySourceType(null);
    } else if (e === projectMasterData.ConversationConfig[1].content) {
      getPromptDetails("H", "C");
      setHistorySourceType("C");
    } else {
      getPromptDetails("H", "P");
      setHistorySourceType("P");
    }
  };

  /**
   * Description
   * -----------
   * All selected from the check box list
   * Parameters:
   * e: checkbox selected items
   */
  const toggleToAllWarning = (srcDrpDwnItems) => {
    const checkdrpitems = srcDrpDwnItems.filter((item) => item.type !== "All");
    const drpSelectCount = checkdrpitems.filter(
      (item) => item.checked === true
    ).length;
    if (drpSelectCount === 5) {
      setWarningVisible(true);
    } else {
      setWarningVisible(false);
    }
  };

  useEffect(() => {
    if (isRefreshFavorites) {
      getPromptDetails("F", currentPromptType);
      setIsRefreshFavorites(false);
    }
  }, [isRefreshFavorites]);

  /**
   * Description
   * -----------
   * This function is used to close the delete confirmation modal
   */
  const closeDeletePromptModal = () => {
    setShowConfirmDeletePrompt(false);
  }
  /**
   * Description
   * -----------
   * This function is used for calling delete service after confirmation
   * @param promptId: selected promptId for deletion
   */
  const confirmPromptDelete = async (promptId) => {
    setShowConfirmDeletePrompt(false);
    setIsOpenPromptModal(false);
    await promptService.deletePersonalPrompt(promptId).then(setDeletePromptSuccess(true));
    getPersonalData();
  }
  /**
   * Description
   * -----------
   * This function is used to close the edit modal
   */
  const closeEditPromptModal = () => {
    setShowConfirmEditPrompt(false);
  }
  /**
   * Description
   * -----------
   * This function is used for calling edit service.
   * @param promptData: selected promptData for edit
   */
  const confirmPromptEdit = async (promptData) => {
    setShowConfirmEditPrompt(false);
    setIsOpenPromptModal(false);
    await promptService.editPersonalPrompt(promptData);
    getPersonalData();
  }
  /**
   * This method returns a topic name based on id from constant value
   * @param topicId: sections topic id
   * @returns string topic name
   */

  const onScopeChange = async (scopeId) => {
    dispatch(
      setsatScopeId({
        satScopeId: scopeId,
      })
    );
    if (projectType === "1" || projectType === "2") {
      const response = await platformService.getSaTPromptData(scopeId, "A");
      setPromptLibrary(() => [...response]);
    } else if (projectType === "3") {
      const response = await platformService.getSaTPromptData(scopeId, "T");
      setPromptLibrary(() => [...response]);
    } else {
      const response = await platformService.getSaTPromptData(scopeId);
      setPromptLibrary(() => [...response]);
    }
  };

  // personal data for prompt library
  const getPersonalData = (personal) => {
    promptService.getPersonalPrompts().then((response) => {
      setpromptPersonalData(response);
    });
  };

  const promptExpandLibrary = () => {
    setSrcDocClick(true);
    setFavorClick(false);
    setHistoryClick(false);
  }

  return (
    <div className="right-panel-container">
      <div className="right-panel-container-newprompt-container">
        <div className="right-panel-container-newprompt-heder">
          <MotifButton
            className="right-panel-container-newprompt-text"
            onClick={createNewConversation}
          >
            + New Conversation
          </MotifButton>
        </div>
      </div>

      {(() => {
        if (srcdocClick) {
          //display source doc section
          if (publicOrConfidential === "C") {
            if (docs.length === 0 && !searchActive) {
              // display no documents section
              return (
                <>
                  <img
                    className="no-doc-found-image"
                    src={noDocumentImg}
                    alt=""
                  ></img>
                  <div className="no-doc-found-txt">No documents found</div>
                  <div className="no-doc-instruction-txt">
                    Please upload your documents in the document library
                  </div>
                  <div className="no-doc-button">
                    <MotifButton
                      onClick={(e) => {
                        navigate("/ProjectDashboard/DocumentLibrary", {
                          state: { showDocLibrary: true },
                        });
                      }}
                      className="motif-upload-doc-button"
                    >
                      <img src={upload} alt="upload document icon" />
                      Upload Document
                    </MotifButton>
                  </div>
                </>
              );
            } else {
              if (docs.length > 0 || searchActive) {
                //show docs
                return (
                  <div className="source-doc-container">
                    <div className="select-file-type-title">
                      Select documents by file type
                    </div>
                    <div className="checkbox-list-filetypes">
                      {srcDocDrpDwnItems.map((item) => {
                        return (
                          <MotifCheckbox
                            id={item.id}
                            value={item.type}
                            name={item.type}
                            checked={item.checked}
                            onChange={(e) => onChecked(e, item.id)}
                          >
                            <span>{item.type}</span>
                          </MotifCheckbox>
                        );
                      })}
                    </div>
                    {warningDocumentVisible ? (
                      <MotifToast
                        id="ToastClose"
                        className="warning-toast"
                        variant="warning"
                        actionName=""
                        position=""
                        onClose={() => {
                          setwarningDocumentVisible(false);
                        }}
                      >
                        <div>Select documents to begin your search.</div>
                      </MotifToast>
                    ) : (
                      <></>
                    )}
                    {warningVisible ? (
                      <MotifToast
                        className="warning-toast"
                        variant="warning"
                        actionName=""
                        position=""
                        onClose={() => {
                          setWarningVisible(false);
                        }}
                      >
                        <div>
                          This selection may result in the response taking a
                          greater time to generate results.
                        </div>
                      </MotifToast>
                    ) : (
                      <></>
                    )}
                    <div className="uploaded-docs-container">
                      <div className="right-panel-doc-count">
                        <span className="right-panel-doc-count-text">
                          All uploaded documents
                        </span>
                        <span className="right-panel-doc-count-digit">
                          {selectedDocs.length}/{readyDocuments.length}
                        </span>
                      </div>
                      <span className="select-files">
                        Select files to begin your search
                      </span>
                      <MotifFormField className="search-field">
                        <img className="search-icon" src={search} alt="" />
                        <MotifSearch
                          className="search-text-box"
                          aria-label="Search"
                          hideClearButton
                          id="states-search2"
                          placeholder="Search file..."
                          onChange={handleSearch}
                          value={searchTxt}
                        />
                      </MotifFormField>
                      <div className="right-panel-docs-container-with-noselection">
                        {docs.map((doc) => {
                          return (
                            <div
                              key={doc.documentId}
                              className="right-panel-doc-item"
                            >
                              <MotifCheckbox
                                id={doc.documentId}
                                value={doc.documentName}
                                name={doc.documentName}
                                className="right-panel-doc-checkbox"
                                checked={doc.selected}
                                onChange={(e) => onDocChange(e, doc.documentId)}
                              >
                                <span className="right-panel-doc-name">
                                  {doc.documentName}
                                </span>
                              </MotifCheckbox>
                              {localStorage.getItem("userRole") !== "Viewer" &&
                                localStorage.getItem("userRole") !==
                                "User (Non-SaT)" && (
                                  <MotifIconButton
                                    id="downloadButton"
                                    className="right-panel-download-button"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents the click event from bubbling up to the checkbox
                                      downloadDocument.handleDownload(
                                        doc.documentName
                                      );
                                    }}
                                  >
                                    <img
                                      src={download}
                                      alt="download source documents"
                                    />
                                  </MotifIconButton>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              }
            }
          } else {
            //display public section
            return (
              <div className="public_source_document">
                <div className="wrapper-public-section">
                  <img
                    className="public_source_document_icon"
                    src={PublicSearchIcon}
                    alt=""
                  />
                  <div className="public-search-box">
                    <p className="txt-public-search-enabled">
                      Public search enabled
                    </p>
                    <p className="txt-confidential-doc">
                      Digital Diligence Assistant is hosted in a secure and
                      private EY environment, which means that all information
                      you submit as a prompt will be completely private to you,
                      not accessible by any other EY member nor able to re-train
                      or affect the model in any way. However, you are
                      responsible for ensuring you have the required rights and
                      permissions to use the data you provide as prompts.
                    </p>
                    <p className="txt-confidential-doc">
                      Digital Diligence Assistant leverages a GPT-4-Turbo model
                      last updated December 2023, and its ability to be updated
                      / re-trained is disabled. It has not specifically been
                      trained on EY data and cannot provide any EY specific
                      answers.
                    </p>
                    <p className="txt-confidential-doc">
                      The public search option returns a response based on the
                      knowledge of the GPT-4-Turbo model and does not consider
                      documents included in the Document Library.
                    </p>
                  </div>
                </div>
              </div>
            );
          }
        } else if (favorClick) {
          //display Prompt Library section
          return (
            <>
              {/* <RightPanelPromptLibrary/> */}
              <div className="right-panel-favorites">
                <MotifButtonGroup id="favourite-button-type">
                  <MotifButton
                    className={`prompt-type-button ${isActivePromptTypeButton() ? "CentralActive" : ""
                      }`}
                    onClick={changePromptType}
                  >
                    Central
                  </MotifButton>
                  <MotifButton
                    className={`prompt-type-button ${!isActivePromptTypeButton() ? "CentralActive" : ""
                      }`}
                    onClick={changePromptType}
                  >
                    Personal
                  </MotifButton>
                </MotifButtonGroup>

                <div
                  className={
                    isInstantInsightDisable
                      ? "dropdown-title hide"
                      : "dropdown-title"
                  }
                >
                  Project Scope
                </div>
                <div
                  className={
                    isInstantInsightDisable ? "eyd-select hide" : "eyd-select"
                  }
                >
                  <MotifFormField>
                    {satScopeId === 0 ? (
                      <MotifLabel id="selectscope">
                        Select Project Scope
                      </MotifLabel>
                    ) : null}
                    <MotifSelect
                      id="scopeSelect"
                      className="Input-eyd-select-prompt"
                      ariaLabelledBy="select-label"
                      onChange={onScopeChange}
                      value={satScopeId}
                    >
                      {scopeList && [
                        ...scopeList.map((item) => {
                          return (
                            <MotifOption
                              className={item.scopeName}
                              value={item.scopeId}
                              key={item.scopeId}
                            >
                              {item.scopeName}
                            </MotifOption>
                          );
                        }),
                      ]}
                    </MotifSelect>
                  </MotifFormField>
                </div>
                <div>
                  <MotifFormField
                    className={isInstantInsightDisable ? "personal-search" : ""}
                  >
                    <img
                      className="explore-rp-search-icon"
                      src={search}
                      alt=""
                    />
                    <MotifSearch
                      aria-label="Search"
                      hideClearButton
                      id="states-search"
                      className="explore-rp-search-textbox"
                      onChange={searchFavoritePrompt}
                      onSearchButtonClick={function noRefCheck() { }}
                      onSelect={function noRefCheck() { }}
                      placeholder="Search"
                      searchButtonProps={{
                        title: "Search",
                      }}
                      value={searchFavPrompt}
                      disabled={
                        !isInstantInsightDisable ? satScopeId === 0 : false
                      }
                    />
                  </MotifFormField>
                </div>
              </div>
              {!isInstantInsightDisable ? (
                <div className="favorites-prompts-list1 prompt-tab-container-bgcolor">
                  {filteredPromptList.length > 0 ? (
                    <div className="PromptLibraryList">
                      {filteredPromptList.map((promptData) => {
                        return (
                          <div className="PromptLibraryCard">
                            <div className="PromptLibraryTitle">
                              {promptData?.title}
                            </div>
                            <div className="PromptLibraryButtons">
                              <MotifTooltip
                                trigger={
                                  <MotifIconButton
                                    aria-label="Click here to see options"
                                    onClick={(e) => { showPromptModal(e, promptData) }}
                                    className="PromptLibraryExpand"
                                  >
                                    <img src={ExpandIcon} title="" alt="expand" />
                                  </MotifIconButton>
                                }
                                contentClassName="tooltipWithoutClose"
                                allowHover={true}
                                allowClick={false}
                                hideCloseButton={true}
                                placement="bottom"
                              >
                                Expand
                              </MotifTooltip>
                              <MotifTooltip
                                trigger={
                                  <MotifIconButton
                                    aria-label="Click here to see options"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlePromptToQuestionBar(utils.markdownToHtml(promptData?.promptText));
                                      if(promptData?.scopeId){
                                        setEydScopeId(promptData?.scopeId)
                                      }
                                      promptExpandLibrary()
                                    }}
                                    className="PromptLibraryUseprompt"
                                  >
                                    <img
                                      src={RightArrow}
                                      title=""
                                      alt="useprompt"
                                    />
                                  </MotifIconButton>
                                }
                                contentClassName="tooltipWithoutClose"
                                allowHover={true}
                                allowClick={false}
                                hideCloseButton={true}
                                placement="bottom"
                              >
                                Use prompt
                              </MotifTooltip>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="PromptLibrartNoScope">
                      <div className="noScopeSeletced">
                        <img
                          className="no-doc-found-image"
                          src={noDocumentImg}
                          alt=""
                        ></img>
                        <div className="no-scope-found-txt">
                          No Project Scope Selected
                        </div>
                        <div className="no-scope-instruction-txt">
                          Select a Project Scope to view prompts
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="personal-tab-container">
                  <div
                    className={
                      isInstantInsightDisable
                        ? "personal-tab prompt-tab-container-bgcolor"
                        : "favorites-prompts-list1 prompt-tab-container-bgcolor"
                    }
                  >
                    {filteredPersonalPromptList.length > 0 ? (
                      <div className="PromptLibraryList">
                        {filteredPersonalPromptList.map((promptData) => {
                          return (
                            <div className="PromptLibraryCard">
                              <div className="PromptLibraryTitle">
                                {promptData?.promptTitle}
                              </div>
                              <div className="PromptLibraryButtons">
                                <MotifTooltip
                                  trigger={
                                    <MotifIconButton
                                      aria-label="Click here to see options"
                                      onClick={() => {
                                        setSpecificPromptData(promptData);
                                        setShowConfirmDeletePrompt(true);
                                      }}
                                      className="PromptLibraryIconBorder"
                                    >
                                      <img
                                        src={bookmarkfill}
                                        title=""
                                        alt="bookmark"
                                      />
                                    </MotifIconButton>
                                  }
                                  contentClassName="tooltipWithoutClose"
                                  allowHover={true}
                                  allowClick={false}
                                  hideCloseButton={true}
                                  placement="bottom"
                                >
                                  Remove
                                </MotifTooltip>

                                <MotifTooltip
                                  trigger={
                                    <MotifIconButton
                                      aria-label="Click here to see options"
                                      onClick={() => {
                                        setSpecificPromptData(promptData);
                                        setShowConfirmEditPrompt(true);
                                      }}
                                      className="PromptLibraryIconBorder"
                                    >
                                      <img
                                        src={editPencil}
                                        title=""
                                        alt="edit"
                                      />
                                    </MotifIconButton>
                                  }
                                  contentClassName="tooltipWithoutClose"
                                  allowHover={true}
                                  allowClick={false}
                                  hideCloseButton={true}
                                  placement="bottom"
                                >
                                  Edit
                                </MotifTooltip>
                                <MotifTooltip
                                  trigger={
                                    <MotifIconButton
                                      aria-label="Click here to see options"
                                      onClick={(e) => {
                                        showPromptModal(e, promptData);
                                      }}
                                      className="PromptLibraryExpand"
                                    >
                                      <img
                                        src={ExpandIcon}
                                        title=""
                                        alt="expand"
                                      />
                                    </MotifIconButton>
                                  }
                                  contentClassName="tooltipWithoutClose"
                                  allowHover={true}
                                  allowClick={false}
                                  hideCloseButton={true}
                                  placement="bottom"
                                >
                                  Expand
                                </MotifTooltip>
                                <MotifTooltip
                                  trigger={
                                    <MotifIconButton
                                      aria-label="Click here to see options"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handlePromptToQuestionBar(
                                          utils.markdownToHtml(promptData?.promptText)
                                        );
                                        if(promptData?.scopeId){
                                          setEydScopeId(promptData?.scopeId)
                                        }
                                        promptExpandLibrary();
                                      }}
                                      className="PromptLibraryUseprompt"
                                    >
                                      <img
                                        src={RightArrow}
                                        title=""
                                        alt="useprompt"
                                      />
                                    </MotifIconButton>
                                  }
                                  contentClassName="tooltipWithoutClose"
                                  allowHover={true}
                                  allowClick={false}
                                  hideCloseButton={true}
                                  placement="bottom"
                                >
                                  Use prompt
                                </MotifTooltip>

                              </div>

                              <DeletePromptModal
                                showDeletePromptModal={showConfirmDeletePrompt}
                                showConfirmDeletePrompt={
                                  showConfirmDeletePrompt
                                }
                                toggleDeletePromptModal={closeDeletePromptModal}
                                handleDeletePrompt={() => {
                                  confirmPromptDelete(
                                    specificPromptData?.promptId
                                  );
                                }}
                              />
                              <EditPromptModal
                                showEditPromptModal={showConfirmEditPrompt}
                                showConfirmEditPrompt={showConfirmEditPrompt}
                                selectedPrompt={specificPromptData}
                                toggleEditPromptModal={closeEditPromptModal}
                                handleEditPrompt={(promptData) => {
                                  confirmPromptEdit(promptData);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="PromptLibrartNoScope">
                        <div className="noScopeSeletced">
                          <img
                            className="no-doc-found-image"
                            src={noDocumentImg}
                            alt=""
                          ></img>
                          <div className="no-scope-found-txt personal-no-scope">
                            No prompts found
                          </div>
                          <div className="no-scope-instruction-txt personal-no-scope-instruction">
                            Add New Prompt to get started
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="add-new-prompt">
                    <MotifButton
                      label="btnCreate"
                      className="add-new-prompt-bgcolor"
                      onClick={() => { }}
                    >
                      <img src={plus} alt="plus" />
                      Add New Prompt
                    </MotifButton>
                  </div>
                </div>
              )}
              {deletePromptSuccess ? (
                <MotifToast
                  id="ToastPromptDeleteSuccess"
                  className="DeleteSuccess-toast prompt-toast"
                  actionName=""
                  position=""
                  onClose={() => {
                    setDeletePromptSuccess(false);
                  }}
                >
                  <div>{promptDeleteMsg}</div>
                </MotifToast>
              ) : (
                <></>
              )}
            </>
          );
        } else if (historyClick) {
          //display history section
          return (
            <>
              <div className="right-panel-history">
                {showLoader && <LoaderComponent />}
                <div className="dropdown-title">Select Source</div>
                <div id="selectfavoriteType">
                  <MotifFormField>
                    <MotifSelect
                      label="selectfavorite"
                      className="selectfavoriteInput"
                      ariaLabelledBy="select-label"
                      onChange={onSourceChange}
                      value={historyType}
                      select="Select Favourite Tap"
                      searchPlaceholder={historyType}
                      filter={true}
                      required
                    >
                      {conversationList.map((item) => {
                        return (
                          <MotifOption
                            className={item.content}
                            value={item.content}
                            key={item.id}
                          >
                            {item.content}
                          </MotifOption>
                        );
                      })}
                    </MotifSelect>
                  </MotifFormField>
                </div>
                <MotifFormField id="favourite-history-search">
                  <img className="explore-rp-search-icon" src={search} alt="" />
                  <MotifSearch
                    aria-label="Search"
                    hideClearButton
                    id="states-search"
                    className="explore-rp-search-textbox"
                    onChange={searchHistoryPrompt}
                    onSearchButtonClick={function noRefCheck() { }}
                    onSelect={function noRefCheck() { }}
                    placeholder="Search"
                    searchButtonProps={{
                      title: "Search",
                    }}
                    value={searchHisPrompt}
                  />
                </MotifFormField>
              </div>
              <div className={list}>
                {historyConversationPrompts.length > 0 ? (
                  historyConversationPrompts.map((prompt) => (
                    <div
                      className="favorites-prompts"
                      onClick={(e) => {
                        e.preventDefault();
                        showPrompt(prompt);
                      }}
                    >
                      <div className="favorites-prompts-content">
                        <div className="favorites-prompts-header">
                          <div>
                            <img
                              className="favorites-star-icon"
                              src={cancelIcon}
                              onClick={(e) => showPromptModal(e)}
                              alt="promptModal"
                            ></img>
                          </div>
                          <div
                            className={
                              prompt.isPublicConfidential === "P"
                                ? "favorites-conversationprompt-public"
                                : "favorites-conversationprompt-confidential"
                            }
                          >
                            {prompt.isPublicConfidential === "P"
                              ? "Public"
                              : "Confidential"}
                          </div>
                          <div className="favorites-prompt-title">
                            {prompt.promptLibraryName}
                          </div>
                          <div
                            className="favorites-prompt-question"
                            dangerouslySetInnerHTML={{
                              __html: prompt.requestContent,
                            }}
                          ></div>
                        </div>
                        <div className="favorites-star">
                          <img
                            className="favorites-star-icon"
                            src={cancelIcon}
                            onClick={(e) => setDeleteConv(e, prompt.conv_UUID)}
                            alt=""
                          ></img>
                        </div>
                      </div>
                      <div className="favorites-footer-date">
                        <div className="favorites-date">
                          {utils.getDateWithMMMDYYYYUTC(
                            prompt.conv_LastPromptDate
                          )}
                        </div>
                        {prompt?.modifiedResponse && (
                          <div className="flagEdited">Edited</div>
                        )}
                        {prompt?.isBroken === "Y" && (
                          <div className="favorites-broken-link">
                            <div className="favorites-broken-link-icon"></div>
                            <img src={noLinkIcon} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No Prompts in History!</div>
                )}
                {deleteSuccess ? (
                  <MotifToast
                    id="Toastsuccess"
                    className="DeleteSuccess-toast"
                    actionName=""
                    position=""
                    onClose={() => {
                      setdeleteSuccess(false);
                    }}
                  >
                    <div>{conversationDeleteMsg}</div>
                  </MotifToast>
                ) : (
                  <></>
                )}
              </div>
            </>
          );
        }
      })()}

      <div className="right-panel-nav-container-row">
        <div className="right-panel-nav-source-doc-container-explore">
          <div
            className={
              srcdocClick
                ? "right-panel-active-card right-panel-active-select right-panel-nav-source-doc-div"
                : "right-panel-active-card right-panel-nav-source-doc-div"
            }
            onClick={() => {
              setSrcDocClick(true);
              setFavorClick(false);
              setHistoryClick(false);
            }}
          >
            <div className="right-panel-nav-source-doc">Source documents</div>
          </div>
          <div
            id="divFavorites"
            className={
              favorClick
                ? "right-panel-active-card right-panel-active-select right-panel-container-favourate-tab-div"
                : "right-panel-active-card right-panel-container-favourate-tab-div"
            }
            onClick={() => {
              setSrcDocClick(false);
              setFavorClick(true);
              setHistoryClick(false);
              handleQuestionFocus(false);
              setList("favorites-prompts-list1");
            }}
          >
            <div className="right-panel-container-favourate-tab">
              Prompt Library
            </div>
          </div>
          <div
            className={
              historyClick
                ? "right-panel-active-card right-panel-active-select right-panel-container-history-tab-div"
                : "right-panel-active-card right-panel-container-history-tab-div"
            }
            onClick={() => {
              setSrcDocClick(false);
              setFavorClick(false);
              setHistoryClick(true);
              handleQuestionFocus(false);
              setList("favorites-prompts-list2");
            }}
          >
            <div className="right-panel-container-favourate-tab">History</div>
          </div>
        </div>
      </div>
      <MotifModal
        id="DeleteConversation"
        show={!!deleteConv_UUID}
        size="md"
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => setDeleteConv_UUID("")}
        className="DeleteConversationPopup"
      >
        <MotifModalHeader className="DeleteConversation-header">
          <img className="DeleteConversation-icon" src={DeleteIcon} alt="" />
          <div className="DeleteConversation-title">Remove from History</div>
        </MotifModalHeader>
        <MotifModalBody className="DeleteConversation-body">
          <div className="DeleteConversationPopup-innerbody">
            Confirm you would like to remove the conversation from History. This
            action cannot be undone.{" "}
          </div>
        </MotifModalBody>
        <MotifModalFooter className="DeleteConversation-footer">
          <MotifButton
            onClick={deleteConversation}
            size="medium"
            type="button"
            variant="warn"
          >
            Confirm
          </MotifButton>
          <MotifButton
            onClick={() => setDeleteConv_UUID("")}
            size="medium"
            type="button"
            variant="secondary"
          >
            Cancel
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
      <PromptModal
        isOpenPromptModal={isOpenPromptModal}
        setIsOpenPromptModal={setIsOpenPromptModal}
        specificPromptData={specificPromptData}
        isInstantInsightDisable={isInstantInsightDisable}
        handlePromptToQuestionBar={handlePromptToQuestionBar}
        promptExpandLibrary={promptExpandLibrary}
        setEydScopeId={setEydScopeId}
      ></PromptModal>
    </div>
  );
};
export default ExloreRightPanal;
