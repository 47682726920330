import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//  Internal imports
import "../../ProjectDashboard/ProjectDashboard.css";
import "./PromptManagement.css";
import { MotifContentSwitcher, MotifButton } from '@ey-xd/motif-react';

const PromptManagement = () => {
  const [active, setActive] = useState(true)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/adminDashboard/PromptManagement/Powerpoint/SystemPrompts' || location.pathname === '/adminDashboard/PromptManagement/Powerpoint/CentralPrompts'){
      setActive(false)
    }
    else{
      setActive(true)
    }
  }, [])

  const openPlatformPrompts = () => {
    navigate('Platform/SystemPrompts')
    setActive(true)
  }
  const openPowerpointPrompts = () => {
    navigate('Powerpoint/SystemPrompts')
    setActive(false)
  }
  return (
    <div className="ProjectDashboardPageContainer">
      <div className="HeaderLayout">
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Prompt Management</span>
          </div>
          <div className="HeaderAction"></div>
        </div>
      </div>
      <MotifContentSwitcher style={{ marginBottom: '10px' }}>
        <MotifButton
          aria-label="platform"
          aria-pressed
          className={active ? "motif-active" : null}
          onClick={openPlatformPrompts}
        >
          Platform
        </MotifButton>
        <MotifButton
          aria-label="Powerpoint"
          className={!active ? "motif-active" : null}
          onClick={openPowerpointPrompts}
        >
          Powerpoint
        </MotifButton>
      </MotifContentSwitcher>
    </div>
  );
};

export default PromptManagement;
